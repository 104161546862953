import './App.css';
import Header from './container/Header'
import Footer from './container/Footer'
import AccountPage from './container/AccountPage';
import CheckOutPage from './container/CheckOutPage';
import OrderPage from './container/OrderPage';

import {Navigate, Route, Routes} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import MainPage from './container/MainPage';
import BlogPage from './container/BlogPage';
import CategoryPage from './container/CategoryPage';
import SignIn from './container/SignIn';
import CartPage from './container/CartPage';
import DetailPage from './container/DetailPage';

import React from 'react'
import ItemAdd from './container/ItemAdd';
import ContactPage from './container/ContactPage';
import FAQPage from './container/FAQPage';
import SubscribePage from './container/SubscribePage';
import WarrantyPage from './container/WarrantyPage';
import ExWarrantyPage from './container/ExWarrantyPage';
import BlogListPage from './container/BlogListPage';
import LegalNotice from './container/LegalNotice';
import TermOfSale from './container/TermOfSale';
import OurLocation from './container/OurLocation';

function App() {
    return (
      <div className="App">
      <Header/>
        <ScrollToTop>
            <Routes>
              <Route exact={true} path="/acer" Component={MainPage}/>
              <Route path="/acer/category/:category" element={<CategoryPage/>}/>
              <Route path="/acer/category/:category/:subcategory" element={<CategoryPage/>}/>
              <Route path="/acer/signin" element={<SignIn/>}/>
              <Route path="/acer/product/:itemid" element={<DetailPage/>}/>
              <Route path="/acer/product/:itemid/:condition" element={<DetailPage/>}/>
              <Route path="/acer/blog/:blogid" element={<BlogPage/>}/>
              <Route path="/acer/cart" element={<CartPage/>}/>
              <Route path="/acer/checkout" element={<CheckOutPage/>}/>
              <Route path="/acer/order/:orderid" element={<OrderPage/>}/>
              <Route path="/acer/invoice/:invoiceid" element={<OrderPage/>}/>
              <Route path="/acer/account" element={<AccountPage/>}/>
              <Route path="/acer/account/:session" element={<AccountPage/>}/>
              <Route path="/acer/account/:session/:sessionid" element={<AccountPage/>}/>
              <Route path="/acer/add-to-cart" Component={ItemAdd}/>
              <Route path="/acer/contact-us" Component={ContactPage}/>
              <Route path="/acer/faq" Component={FAQPage}/>
              <Route path="/acer/subscribe" Component={SubscribePage}/>
              <Route path="/acer/warranty" Component={WarrantyPage}/>
              <Route path="/acer/extended-warranty/:session" Component={ExWarrantyPage}/>
              <Route path="/acer/blog" Component={BlogListPage}/>
              <Route path="/acer/legal-notice" Component={LegalNotice}/>
              <Route path="/acer/term-of-sale" Component={TermOfSale}/>
              <Route path="/acer/location" Component={OurLocation}/>
              <Route path="*" element={<Navigate to="/acer" replace/>}/>
            </Routes>
        </ScrollToTop>
      {/* <CouponMain/> */}
      {/* <CouponInfo/> */}
      {/* <CouponFooter/> */}
      <Footer/>
    </div>
    )
}

export default App;
