const userInfo={
    cartItems:[],
    cartItemsNumber:0,
    cartItemsSubtotal: 0,
    addresses:[],
    wishLists:[],
    wishListsItems:[],
    updateInfo,
    resetUserInfo
}
function updateInfo(field,data){
    userInfo[field] = data
}
// call when customer logged out
function resetUserInfo(){
    userInfo["cartItems"] = []
    userInfo['cartItemsNumber'] = 0
    userInfo['cartItemsSubtotal'] = 0
    userInfo['addresses'] = []
    userInfo['wishLists'] = []
    userInfo['wishListsItems'] = []
}
export default userInfo