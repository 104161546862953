import React, { Component } from 'react'
import './index.css'
import { BsFillLightningChargeFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';
// import itemImageURL from '../../media/image/productbrief.png'
import imageNotFound from '../../media/image/image-not-found.jpeg'
import withRouter from '../../withRouter';
// import PubSub from 'pubsub-js';
class ItemAdd extends Component {
    constructor(props){
        super(props)
        this.state = {
            product:null,
            imageUrl:null,
            addedQuantity:0,
            cartItemsNumber:0,
            cartItemsSubtotal:0,
            isMobile:window.innerWidth<=800?true:false,
            currentTop:window.pageYOffset,
        }
        this.freezeWindow = function(){
            window.scrollTo({top:this.state.currentTop,behavior:"auto"})
          }
        this.freezeHandler = this.freezeWindow.bind(this)
        this.quitItemAdd = this.quitItemAdd.bind(this)
    }
    quitItemAdd(){
        window.history.back()
    }
    componentDidMount(){
        // console.log(this.props.location)
        window.addEventListener('scroll',this.freezeHandler)
        // console.log(this.props)
        if(this.props.location.state.product){
            this.setState({
                product: {...this.props.location.state.product},
                imageUrl: this.props.location.state.imageUrl,
                addedQuantity: this.props.location.state.quantity,
                cartItemsNumber: this.props.location.state.cartItemsNumber,
                cartItemsSubtotal: this.props.location.state.cartItemsSubtotal
            })
        }else{
            this.props.navigate('/signin',{state:{from:this.props.location.pathname}})
        }
    }
      
    componentWillUnmount(){
        window.removeEventListener('scroll',this.freezeHandler)
    }
    render() {
    return (
        <div className='itemadd' style={{top:this.state.currentTop}}>
            <div className='itemadd-content'>
                <div className='itemadd-title'>
                    <h1>Ok, {this.state.addedQuantity} {this.state.addedQuantity > 1 ? 'items': 'item'} was added to your cart. What's next?</h1>
                    <button onClick={()=>this.quitItemAdd()}><RxCross2/></button>
                </div>
                <div className='itemadd-main'>
                    <div className='itemadd-content-main'>
                        <div className='itemadd-content-main-item'>
                            <img src={this.state.imageUrl?this.state.imageUrl:imageNotFound} alt="err" onError={(e)=>{e.target.onError = null; e.target.src=imageNotFound}}/>
                            <div className='itemadd-content-main-item-info'>
                                <h2>{this.state.product?.item_name}</h2>
                                <p>{this.state.addedQuantity} X ${this.state.product?.item_condition === "ScratchDent" ? this.state.product?.personal_scratch_dent_price?.toFixed(2) : (this.state.product?.is_sale ? this.state.product?.personal_sale_price?.toFixed(2):this.state.product?.personal_price?.toFixed(2))}</p>
                            </div>
                        </div>
                    </div>
                    <div className='itemadd-content-main-checkout'>
                        <button onClick={()=>{this.props.navigate('/acer/checkout',{state:{from:this.props.location.pathname}})}}><BsFillLightningChargeFill/>CHECKOUT</button>
                        <p>Order subtotal</p>
                        <h1>${this.state.cartItemsSubtotal?.toFixed(2)}</h1>
                        <p>Your cart contains {this.state.cartItemsNumber} {this.state.cartItemsNumber>1?"items":"item"}</p>
                        <button onClick={()=>this.quitItemAdd()}>CONTINUE SHOPPING</button>
                        <Link to={{pathname:'/acer/cart'}} state ={{from:this.props.location.pathname,cartItems:this.props.location.state.cartItems}}><p>View or edit your cart</p></Link>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default withRouter(ItemAdd)