import React, { Component } from 'react'
import Banner from './Banner'
import './index.css'
import breakBannerURL from '../../media/image/website_photo/main_page_3.png'
import loadingGif from '../../media/image/Spinner.gif'
import ProductBreif from '../ProductBrief'
import ProductDetail from '../ProductDetail'
import ProductListing from './ProductListing'
import BlogBreif from './BlogBreif'
import RecentPosts from '../RecentPosts'
import withRouter from '../../withRouter'
import axios from 'axios'
import Cookies from 'universal-cookie'
import serverInfo from "../../config.js"
import PubSub from 'pubsub-js'
class MainPage extends Component {
  constructor(props){
    super(props)
    this.cookies = new Cookies()
    this.baseURL = "http://localhost:8080"
    this.state={
      isMobile:window.innerWidth<=800?true:false,
      productWidth:window.innerWidth<=800?"calc((100% - 10px) / 2)":"calc((100% - 120px) / 4)",
      featuredProducts:null,
      newProducts:null,
      popularProducts:null,
      percentage:this.cookies.get('percentage') ? this.cookies.get('percentage'): 0,
    }
  }
  windowResize(){
    const currentWidth = window.innerWidth
    if(this.state.isMobile && currentWidth > 800){
      this.setState({
        isMobile:false,
        productWidth:"calc((100% - 120px) / 4)"
      })
    }
    if(!this.state.isMobile && currentWidth <= 800){
      this.setState({
        isMobile:true,
        productWidth:"calc((100% - 10px) / 2)"
      })
    }
  }
  getMainPageInfo(percentage){
    axios.get(`${serverInfo.baseURL}/mainpage`,{headers:{'customerid':serverInfo.customerid,'percentage':percentage,'authorization':serverInfo.token}}).then(
      res=>{
        if(res.data.scode === 200){
          const featuredProducts = [], newProducts = [],popularProducts = []
          for(const product of res.data.products.featuredProducts){
            const item = {...product}
            if(product.is_brand_new){
              item["item_condition"] = "BrandNew"
              featuredProducts.push(item)
            }else if(product.is_recertified){
              item["item_condition"] = "Recertified"
              featuredProducts.push(item)
            }
          }
          for(const product of res.data.products.newProducts){
            const item = {...product}
            if(product.is_brand_new){
              item["item_condition"] = "BrandNew"
              newProducts.push(item)
            }else if(product.is_recertified){
              item["item_condition"] = "Recertified"
              newProducts.push(item)
            }
          }
          for(const product of res.data.products.popularProducts){
            const item = {...product}
            if(product.is_brand_new){
              item["item_condition"] = "BrandNew"
              popularProducts.push(item)
            }else if(product.is_recertified){
              item["item_condition"] = "Recertified"
              popularProducts.push(item)
            }
          }
          this.setState({
            featuredProducts,
            newProducts,
            popularProducts,
          })
          console.log(res.data.products.newProducts)
          PubSub.publish('mainPageProducts',{'POPULAR PRODUCTS':[...popularProducts],'NEW PRODUCTS':[...newProducts]})
        }else{
          console.log(res.data.scode,res.data.smessage)
        }
      },
      rej=>console.log(rej)
    )
  }
  componentDidMount(){
    window.addEventListener('resize', this.windowResize.bind(this))
    this.getMainPageInfo(this.state.percentage)
    this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{
      this.getMainPageInfo(data)
      this.setState({percentage:data})
    })
  }
  componentWillUnmount(){
      window.removeEventListener('resize', this.windowResize.bind(this))
      PubSub.unsubscribe(this.percentageSub)
  }
  render() {
    console.log('serverInfo.percentage.main',serverInfo.percentage)
    return (
      <div className='mainpage'>
        <Banner/>
        <div className='mainpage-featproduct'>
            <h1>FEATURED PRODUCTS</h1>
            <div className='mainpage-featproduct-listing'>
              {this.state.featuredProducts ? this.state.featuredProducts?.map(product=>(<ProductBreif width={this.state.productWidth} key={`f-${product.sku}`} product={product}/>)):<div className='loading-image'><img src={loadingGif} alt="loading..."/></div>}
                {/* <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/>
                <ProductBreif width={this.state.productWidth}/> */}
            </div>
        </div>
        <div className='mainpage-break-banner'>
            <img src={breakBannerURL} alt='err'/>
        </div>
        {this.state.popularProducts? <ProductListing title='POPULAR PRODUCTS' products={this.state.popularProducts}/>:<div className='loading-image'><img src={loadingGif} alt="loading..."/></div>}
        {this.state.newProducts? <ProductListing title='NEW PRODUCTS' products={this.state.newProducts}/>:<div className='loading-image'><img src={loadingGif} alt="loading..."/></div>}
        {/* <div className='mainpage-subsrcibe'>
            <h1>DON'T MISS OUT</h1>
            <div className='mainpage-subsrcibe-form'>
                <input type='text' defaultValue={'YOUR EMAIL'}/>
                <button>SUBSCRIBE</button> 
            </div>
        </div> */}
        {/* <RecentPosts/> */}
        {/* <div className='mainpage-blog'>
            <h1>RECENT POSTS</h1>
            <div className='mainpage-blog-listing'>
                <BlogBreif/>
                <BlogBreif/>
            </div>
        </div> */}

      </div>
    )
  }
}

export default withRouter(MainPage)