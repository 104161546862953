import React, { Component } from 'react'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import './index.css'
import PubSub from 'pubsub-js';
class OurLocation extends Component {
    componentDidMount(){
        PubSub.publish('routes',[{pathname:'/acer/location',name:'Our Location',isCurrent:true}])
    }
  render() {
    return (
      <div className='ourlocation'>
        <PageRoute/>
        <div className='ourlocation-main'>
            <h1>Get in touch</h1>
            <div className='ourlocation-location'>
                <div className='ourlocation-location-info'>
                    <h2>ALC Micro Toronto</h2>
                    <div className='ourlocation-location-info-block'>
                        <FaPhoneAlt style={{color:'rgb(129,194,68)',fontSize:"1.2em"}}/>
                        <div className='ourlocation-location-info-text'>
                            <p>Phone: <a href="tel:905-731-1928">905-731-1928</a></p>
                        </div>
                    </div>
                    <div className='ourlocation-location-info-block'>
                        <IoIosMail style={{color:'rgb(129,194,68)',fontSize:"1.5em"}}/>
                        <div className='ourlocation-location-info-text'>
                            <p>General Inquiries: <a href="mailto: admin@alcmicro.com">admin@alcmicro.com</a></p>
                            <p>Sales Info.: <a href="mailto: sales@alcmicro.com">sales@alcmicro.com</a></p>
                        </div>
                    </div>
                    <div className='ourlocation-location-info-block'>
                        <IoLocationSharp style={{color:'rgb(129,194,68)',fontSize:"1.5em"}}/>
                        <div className='ourlocation-location-info-text'>
                            <p>45A West Wilmot St. Unit 5</p>
                            <p>Richmond Hill</p>
                            <p>Ontario</p>
                            <p>L4B 1K1</p>
                            <p>Canada</p>
                        </div>
                    </div>
                </div>
                <div className='ourlocation-location-info-iframe'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.192069455041!2d-79.39189282374295!3d43.85184753956013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2b3706a693b7%3A0x95ad6ab568d4464e!2s45A%20West%20Wilmot%20St%2C%20Richmond%20Hill%2C%20ON%20L4B%202P3!5e0!3m2!1sen!2sca!4v1723115200275!5m2!1sen!2sca" width="400" height="300" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
export default withRouter(OurLocation)