import React, { Component } from 'react'
import './index.css'
import withRouter from '../../../withRouter'
import axios from 'axios'
import PubSub from 'pubsub-js'
import serverInfo from '../../../config'
import userInfo from '../../../userInfo'
class Address extends Component {
    constructor(props){
        super(props)
        this.state = {
          address: this.props.address? this.props.address:null,
          isChecked: false,
          isMobile:window.innerWidth<=800?true:false,
          width: this.props.width?this.props.width:(window.innerWidth<=800?`calc((100% - 20px) / 2)`:`calc((100% - 40px) / 3)`)
        }
    }
    deleteAddress(){
      if(this.state.address?.address_id){
        axios.delete(`${serverInfo.baseURL}/user/${serverInfo.customerid}/address/${this.state.address.address_id}/delete`,{headers:{'customerid':serverInfo.customerid,'percentage':serverInfo.percentage,'authorization':serverInfo.token}}).then(
          res=>{
            if(res.data.scode === 200){
              userInfo.updateInfo('addresses',res.data.addresses)
              PubSub.publish('addresses',res.data.addresses)
            }else if(res.data.scode === 201){
              alert('Address has been deleted successfully, if the deleted address still shows in page, please refresh the page!')
            }else{
              alert(res.data.smessage)
            }
          },
          rej=>console.log(rej)
        )
      }
    }
    checkBoxHandler = ()=>{
      if(!this.state.isChecked && this.props.addressType){
        PubSub.publish(this.props.addressType,this.state.address)
      }else{
        PubSub.publish(this.props.addressType,null)
      }
      this.setState({isChecked:!this.state.isChecked})
    }
    componentDidUpdate(){
      if(!this.state.address || this.props.isChecked !== this.state.isChecked || this.props.address.address_id !== this.state.address.address_id){
        this.setState({
          address:this.props.address,
          isChecked:this.props.isChecked?true:false
        })
      }
    }
    componentDidMount(){
      if(this.props.address){
        this.setState({
          address:this.props.address,
          isChecked:this.props.isChecked? true:false
        })
      }
    }
  render() {
    return (
      <div className='account-address' style={{width:this.props.width?this.props.width:(window.innerWidth<=800?`calc((100% - 20px) / 2)`:`calc((100% - 40px) / 3)`),height:this.props.height?this.props.height:'250px'}}>
        <input type='checkbox' checked={this.state.isChecked} onChange={()=>this.checkBoxHandler()} style={{display:this.props.showCheckbox?'flex':'none'}}/>
            <div className='account-address-content'>
                <h4>{`${this.state?.address?.first_name} ${this.state?.address?.last_name}`}</h4>
                <p>{this.state?.address?.company?this.state?.address?.company:""}</p>
                <p>{this.state?.address?.address_line_1}</p>
                <p>{this.state?.address?.address_line_2? this.state?.address?.address_line_2:""}</p>
                <p>{`${this.state?.address?.city}, ${this.state?.address?.province}, ${this.state?.address?.zip}`}</p>
                <p>{this.state?.address?.country}</p>
                <div className='account-address-phone'>
                    <p>Phone: </p>
                    <p>{this.state?.address?.phone_number}</p>
                </div>
                <div className='account-address-button' style={{display:this.props.hideButtons?'none':'flex'}}>
                    {/* <button onClick={()=>{this.props.navigate(`/acer/account/addressForm/${this.state?.address?.address_id}`,{state:{...this.props.location.state,lastPage:this.props.location.pathname}})}}>EDIT</button> */}
                    <button onClick={()=>{this.props.navigate(`/acer/account/addressForm/${this.state?.address?.address_id}`,{state:{from:this.props.location.pathname}})}}>EDIT</button>
                    <button onClick={()=>this.deleteAddress()}>DELETE</button>
            </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Address)