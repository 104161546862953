import React, { Component } from 'react'
import './index.css'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import { Link } from 'react-router-dom'
import PubSub from 'pubsub-js'
class TermOfSale extends Component {
  componentDidMount(){
    PubSub.publish('routes',[{pathname:'/acer/term-of-sale',name:'Terms of Sale',isCurrent:true}])
  }
  render() {
    return (
      <div className='termofsale'>
        <PageRoute/>
        <div className='termofsale-content'>
            <h1>Terms of Sale</h1>
            <h4>Pricing, Availability and Errors</h4>
            <p>{`Prices and availability of products and services are subject to change without notice. Errors will be corrected where discovered, and Acer reserves the right to revoke any stated offer and to correct any errors, inaccuracies or omissions including after an order has been submitted regardless of whether or not the order has been confirmed and or payment received. This includes, but is not limited to, pricing errors and unintentional misrepresentations of availability and/or product attributes. To the fullest extent permitted by law, Acer reserves the right to cancel orders where errors, mistakes or omissions are discovered.`}</p>
            <h4>Order Cancellations and Refunds</h4>
            <p>{`If you are not fully satisfied with your purchase, you may return it in accordance with Acer's Return Policy. If you have already been charged for a purchase and the order is canceled by Acer, Acer will issue a refund to the original method of payment account equal to the full amount charged. Where a customer elects to cancel an order or return an item the amount of the refund received may include a deduction for shipping and handling charges, non returned or missing parts, depreciation and/or other non-cancellable charges. Individual bank policies will dictate when this amount is credited to your account.`}</p>
            <h4>Sales Tax and governmental-imposed fees</h4>
            <p>{`Acer collects and remits sales tax and other fees (e.g. waste recycling fees) as required by law. Stated prices do not include these taxes and fees and these will be added to your order total.`}</p>
            <h4>Order Acceptance/Confirmation</h4>
            <p>{`Your receipt of an electronic or other form of order confirmation does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. To the fullest extent permitted by law, Acer reserves the right at any time after receipt of order to accept or decline an order for any reason.`}</p>
            <h4>Order Limitations</h4>
            <p>{`Acer reserves the right to reject any order placed, and/or or to limit quantities on any order, without giving any reason. If Acer rejects an order, Acer will generally attempt to notify the end-user using the e-mail address provided when the order is placed.`}</p>
            <h4>Order Limitations/Limited Quantities</h4>
            <p>{`Acer may, at its own discretion, limit or cancel quantities purchased per person, per household or per order. Acer also reserves the right to reject any order placed. These restrictions may include orders placed by the same Acer Web site account, the same credit card, and orders that use the same billing and/or shipping address. In the event Acer makes a change to an order, Acer will attempt to notify the purchaser by contacting the e-mail and/or billing address provided at the time the order was made. Acer reserves the right to limit or prohibit sales to dealers.`}</p>
            <h4>Acer Return Policy for Online Purchases</h4>
            <p>All items offer a 30 day return policy beginning on the item delivery date. All returns require prior authorization {`(RMA)`}, returns shipped back to Acer without authorization may be returned to the buyer at an additional expense. Please <Link to={{pathname:"/contact-us",state:{from:this.props.location.pathname}}}>Contact Us</Link> to initiate a return or notify us of any other issues with your order.</p>
            <ul>
                <li>The buyer is responsible for return shipping.</li>
                <li>YOU ARE RESPONSIBLE FOR PROPERLY PACKAGING PRODUCTS TO BE RETURNED AND FOR DELIVERING THE PRODUCTS TO THE COMMON CARRIER DESIGNATED BY ACER. <b>YOU ARE RESPONSIBLE FOR ANY DAMAGE TO YOUR PRODUCT DURING RETURN SHIPMENT</b>.</li>
                <li>Returned products must be in the same condition as you received them. A fee may be charged for any product returned without original packaging, missing parts or manuals, or otherwise not in like-new saleable condition.</li>
                <li>Refund for returned product may include a deduction for shipping and handling charges, non returned or missing parts, depreciation and/or other non-cancellable charges.</li>
            </ul>
            <h4>Warranties</h4>
            <p>{`Unless otherwise stated in the item description, all items purchased via the Acer Outlet Store carry a limited warranty of 90 days from the date of purchase and will be in accordance with the applicable Acer Limited Warranty Agreement. To the fullest extent permitted by law, Acer expressly disclaims any additional warranties or guaranties, including any implied warranties and warranties of fitness for a particular purpose.`}</p>
            <h4>LIMITED WARRANTY & QUICK START GUIDE</h4>
            <p>{`The company warrants this re-certified product against defects in materials or workmanship for a period of 90 days from the original date of purchase from the official remarketing agent. The Limited Warranty is only valid to the original purchaser and is not transferrable to anyone who purchases, rents or obtains the product from the original purchaser; any attempt to transfer this Limited Warranty will render it void. The company will, at its option, (i) repair the product, (ii) replace the product with an equivalent or comparable re-certified product or (iii) refund the buyer for the original purchase price. Repairs or replacements may be performed using factory refurbished parts or products. A dated purchase invoice or sales receipt is required for verification of original purchase date and price.`}</p>
            <p>{`The Limited Warranty only covers product issues caused by defects in materials or workmanship during normal consumer use. The Limited Warranty does not cover product issues caused by any other reason, including but not limited to, accidents, acts of God, misuse, abuse, negligence, product modification, use of product not approved by the company or damage resulting from improper packing of product returned for warranty service.`}</p>
            <p>{`To obtain warranty service, you must receive Return Merchandise Authorization by contacting support as shown below. You must ship the product, freight pre-paid, in its original packaging or packaging with an equal degree of protection. The product must be returned with all accessories, manuals and power cords. The company cannot be held responsible for in-transit damage or packages that are not delivered to us. It is your responsibility to backup any data, software or other materials on your product. Product returned without a Return Merchandise Authorization will not be accepted.`}</p>
            <p>{`THE COMPANY MAKES NO OTHER EXPRESS WARRANTY OR CONDITION WHETHER WRITTEN OR ORAL AND THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS NOT STATED IN THIS LIMITED WARRANTY. TO THE EXTENT ALLOWED BY THE LOCAL LAW OF JURISDICTIONS OUTSIDE THE UNITED STATES, THE COMPANY DISCLAIMS ALL IMPLIED WARRANTIES OR CONDITIONS, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. FOR ALL TRANSACTIONS OCCURRING IN THE UNITED STATES, ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, SATISFACTORY QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE IS LIMITED TO THE DURATION OF THE EXPRESS WARRANTY SET FORTH ABOVE. SOME STATES OR COUNTRIES DO NOT ALLOW A LIMITATION ON HOW LONG AN IMPLIED WARRANTY LASTS OR THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES FOR CONSUMER PRODUCTS. IN SUCH STATES OR COUNTRIES, SOME EXCLUSIONS OR LIMITATIONS OF THIS LIMITED WARRANTY MAY NOT APPLY TO YOU. FOR CONSUMER TRANSACTIONS, THE LIMITED WARRANTY TERMS CONTAINED IN THIS STATEMENT, EXCEPT TO THE EXTENT LAWFULLY PERMITTED, DO NOT EXCLUDE, RESTRICT, OR MODIFY BUT ARE IN ADDITION TO THE MANDATORY STATUTORY RIGHTS APPLICABLE TO THE SALE OF THIS PRODUCT TO YOU.`}</p>
            <p><b>LIMITATION ON DAMAGES:</b> IN NO EVENT WILL THE COMPANY BE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, LOSS OF PROFIT OR INJURY.</p>
            <p><b>INITIAL SETUP:</b>{` Before turning on your computer make sure to connect all accessories. For laptops, install the battery (make sure the battery is properly aligned with the guides to avoid damage to the pins) and for desktops, connect the mouse, keyboard and monitor. Only press the power button once all accessories are connected. Let the computer completely load to the Windows setup screen before turning off.`}</p>
            <p><b>USER MANUALS:</b> Manuals are located on the hard drive and can be accessed using the steps below:</p>
            <ul>
                <li>{`Acer: Start>All Programs>Acer System>User Guide`}</li>
                <li>{`eMachine: Start>All Programs>eMachines>eMachine Documentation`}</li>
                <li>{`Gateway: Start>All Programs>Gateway Documentation`}</li>
            </ul>
            <p><b>TECH SUPPORT SITES:</b>Acer Product: <a href="http://support.acer.com/">http://support.acer.com/</a> Gateway: <a href='http://support.gateway.com/support/default.aspx'>http://support.gateway.com/support/default.aspx</a></p>
            <h4>WHERE ELSE TO GET HELP</h4>
            <ul>
                <li>Call Support at 1-844-433-8476</li>
            </ul>
            <h4>Trademark and Copyright</h4>
            <p>The Acer name, logo, branding elements, websites and promotional materials are subject to various trademark and copyright protections. See <a href='http://us.acer.com/ac/en/US/content/legal'>http://us.acer.com/ac/en/US/content/legal</a> for details. Acer reserves all rights.</p>
            <h4>User Responses</h4>
            <p>Any information or materials or idea you transmit to or post on Acer Web will be regarded as non-confidential and non-proprietary and treated in accordance with Privacy Guideline. You are prohibited from posting or transmitting to or from this site any unlawful, threatening, libelous, defamatory, obscene or pornographic material, profane material or other material that would violate any law. Acer may, but is not obligated to, monitor user responses that are posted on this site and Acer disclaims any liability related to the content of any communications from any user.</p>
            <h4>Privacy</h4>
            <p>Information submitted to Acer {`(including via the placement of an order or the submission of an inquiry)`} is subject to Acer's privacy policy available at <a href="http://us.acer.com/ac/en/US/content/privacy">http://us.acer.com/ac/en/US/content/privacy</a>.</p>
            <h4>Software Information</h4>
            <p>{`Use of any software is subject to the license terms in the software license agreement that accompanies or is provided with the software. You may not download, use or install the software until you have read and accepted the terms of the applicable software license agreement. Some software that is bundled with systems may differ from its retail version (if available) and may not include user manuals or all program functionality.`}</p>
            <h4>Disclaimer</h4>
            <p>{`Acer makes no representations about the accuracy or suitability of the information included in this Web site for any purpose and makes no commitment to update the information. THIS INFORMATION IS PROVIDED "AS IS" AND ACER DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. Acer reserves the right to make any changes to the content or the products and/or the programs described at any time without notice. Applicable law may not allow the exclusion of implied warranties, so the above exclusion may not apply to you.`}</p>
            <h4>Limitation of Liability</h4>
            <p>IN NO EVENT SHALL ACER OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER, INCLUDING LOSS OF PROFITS OR DATA, IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS WEB SITE, ANY WEB SITE LINKS TO THIS SITE OR THE MATERIALS OR INFORMATION CONTAINED AT ANY OR ALL SUCH SITES, WHETHER IN AN ACTION IN CONTRACT OR TORT, EVEN IF ACER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
            <h4>Links to other Web Sites</h4>
            <p>Links to third party Web sites on this site are provided solely as a convenience to you. If you use these links, you will leave this site. Acer does not review or control these third party sites and does not endorse or make any representations about them. If you decide to access any of the third party sites linked to this site, you do this entirely at your own risk.</p>
            <p style={{fontStyle:"italic"}}>*Acer reserves the right to change this policy at any time. The notice contained herein is subject to change without notice.<br/>*Updated by Acer Inc. Legal Department on December 1, 2011.</p>
        </div>
      </div>
    )
  }
}
export default withRouter(TermOfSale)