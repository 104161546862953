import React, { Component } from 'react'
import './index.css'
import { FaArrowRight } from "react-icons/fa6";
import blogBreifURL from "../../../media/image/blogbreif.png"
import withRouter from '../../../withRouter';
import { Link } from 'react-router-dom';
class BlogBreif extends Component {
  render() {
    return (
        <div className='blogbreif'>
            <Link className='blogbreif-image' to={{pathname:'/acer/blog/test',state:{from:this.props.location.pathname}}}><img src={blogBreifURL} alt='err'/></Link>
            <div className='blogbreif-content'>
                <Link className="blogbreif-content-title" href='#' style={{color:'black'}} to={{pathname:'/acer/blog/test',state:{from:this.props.location.pathname}}}>Debunked: Top 5 Refurbished Myths About Acer Recertified Devices</Link>
                <p>Welcome, tech enthusiasts and savvy shoppers! You’re looking for a new device on a budget and so</p>
                <Link className="blogbreif-content-more" href='#' style={{color:'rgb(143,192,87)'}} to={{pathname:'/acer/blog/test',state:{from:this.props.location.pathname}}}>Learn more <FaArrowRight style={{fontSize:'10px'}}/></Link>
            </div>
        </div>
    )
  }
}
export default withRouter(BlogBreif)