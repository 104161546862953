import React from 'react'
import './index.css'
import PubSub from 'pubsub-js'
import PageRoute from '../PageRoute'
import { RiErrorWarningFill } from "react-icons/ri";
import withRouter from '../../withRouter';
import axios from 'axios'
import Cookies from 'universal-cookie'
import serverInfo from "../../config.js"
import {FaEye,FaEyeSlash} from "react-icons/fa";

class SignIn extends React.Component{
    constructor(props){
        super(props)
        this.cookie = new Cookies()
        this.state = {
            isLogin: this.cookie.get('login'),
            loginHeight:"",
            errorMessage:null,
            session:this.props.params.session?this.props.params.session:"signin",
            resetAccount:'',
            sendCodeDisable:false,
            resendCountDown:0,
            verificationCode:'',
            showPassword:false,
            showNewPassword:false,
            showConfirmPassword:false,
            // orderedQuantity:1,
        }
        this.username = React.createRef()
        this.password = React.createRef()
        this.register = React.createRef()
        // this.verificationCode = React.createRef()
        // this.account = React.createRef()
        // this.resetAccount = React.createRef()
        this.newPassword = React.createRef()
        this.confirmPassword = React.createRef()
        this.cookie = new Cookies()
        this.resendTimeout = null
        this.windowResize = function(){
            this.setState({loginHeight:this.register.current.clientHeight})
        }
        this.windowResizeHandler = this.windowResize.bind(this)
    }
    
    changeVerificationValue = (e,maxLength)=>{
        const newValue = e.target.value;
        // 使用正则表达式来确保只有数字被输入
        const numericRegex = /^[0-9]*$/;
        const newValueIsNumeric = numericRegex.test(newValue);
        
        if (newValueIsNumeric && newValue.toString().length <= maxLength) {
            this.setState({verificationCode:newValue})
        }
    }
    changeAccountValue = (e,maxLength)=>{
        const newValue = e.target.value;
        // 使用正则表达式来确保只有数字被输入
        if (newValue.length<=maxLength) {
            this.setState({resetAccount:newValue})
        }
    }
    sendVerificationCode = ()=>{
        this.setState({session:"reset",resendCountDown:60,sendCodeDisable:true})
        this.resendTimeout = setInterval(() => {
            if(this.state.resendCountDown - 1 > 0){
                this.setState({resendCountDown:this.state.resendCountDown-1})
            }else{
                this.setState({resendCountDown:0,sendCodeDisable:false})
                clearInterval(this.resendTimeout)
            }
        }, 1000);
        // this.setState({sendCodeDisable:true})
        // if(this.account.current.value && this.account.current.value!==''){
        //     axios.post(`${serverInfo.baseURL}/user/forget`,{username:this.account.current.value},{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
        //         res=>{
        //             if(res.data.code === 200){
        //                 alert('Verification code has been sent to you emial')
        //                 this.setState({session:"reset",resetAccount:this.account.current.value})
        //             }else{
        //                 alert(res.data.smessage)
                        
        //             }
        //         },
        //         rej=>console.log(rej)
        //     )
        // }
        // this.setState({sendCodeDisable:false})
    }
    resetPassword = () =>{
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,50}$/
        const newPasswordPattern = passwordRegex.exec(this.newPassword.current.value)
        if(newPasswordPattern && this.newPassword.current.value === this.confirmPassword.current.value){
            axios.post(`${serverInfo.baseURL}/user/forget/resetpassword`,{username:this.account.current.value,password:this.newPassword.current.value,verificationCode:this.state.verificationCode},{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.code === 200){
                        alert('Password has been changed, Please use new password to login.')
                        this.setState({session:"signin",resetAccount:'',verificationCode:'',showNewPassword:false,showConfirmPassword:false,showPassword:false})
                    }else{
                        alert(res.data.smessage)
                    }
                },
                rej=>console.log(rej)
            )
        }else{
            if(!newPasswordPattern){
                alert('New password does not meet the requirement, please check your new password.')
            }else{
                alert('New password and confirm password is not the same, please check.')
            }
        }
    }
    handleClick = (e) => {
        
        if(this.state.isLogin){
            // 设置cookie之后跳转回来时的页面
            this.cookie.set('login',false)
            this.cookie.set('customerid',null)
            this.cookie.set('contactPersonId',null)
            this.cookie.set('contactName',null)
            this.cookie.set('firstName',null)
            this.cookie.set('lastName',null)
            this.cookie.set('company',null)
            this.cookie.set('email',null)
            this.cookie.set('phoneNumber',null)
            this.cookie.set('username',null)
            PubSub.publish('customerInfo',null)
            this.jumpBack()
            
        } else {
            // 设置时间为负数, 取消设置的 cookie
            axios.post(`${serverInfo.baseURL}/user/login`,{username:this.username.current.value,password:this.password.current.value},{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
            res=>{
                console.log(res.data)
                if(res.data.scode === 200){
                    this.setState({
                        isLogin: true
                    })
                    this.cookie.set('login', true, {expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('customerid',res.data?.customerInfo?.customer_id,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('contactPersonId',res.data?.customerInfo?.contact_person_id,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('contactName',res.data?.customerInfo?.contact_name,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('firstName',res.data?.customerInfo?.first_name,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('lastName',res.data?.customerInfo?.last_name,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('company',res.data?.customerInfo?.company?res.data?.customerInfo?.company:"",{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('email',res.data?.customerInfo?.email,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('phoneNumber',res.data?.customerInfo?.phone_number?res.data?.customerInfo?.phone_number:"",{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('username',res.data?.customerInfo?.username,{expires:new Date(Date.now() + 2592000000)})
                    this.cookie.set('percentage',res.data?.customerInfo?.percentage,{expires:new Date(Date.now() + 2592000000)})
                    serverInfo.updateCustomerId(res.data?.customerInfo?.customer_id)
                    serverInfo.updateInfo('contactPersonId',res.data?.customerInfo?.contact_person_id)
                    serverInfo.updateInfo('contactName',res.data?.customerInfo?.contact_name)
                    serverInfo.updateInfo('percentage',res.data?.customerInfo?.percentage)
                    PubSub.publish('customerInfo',{...res.data.customerInfo})
                    PubSub.publish('isLogin',true)
                    console.log(res.data)
                    console.log(document.cookie)
                    this.props.navigate('/acer/account/orders',{state:{from:this.props.location.pathname}})
                }else{
                    this.setState({errorMessage:res.data.smessage})
                    alert(`${res.data.smessage}`)
                }
            },
            rej=>console.log(rej))
        }
        
    }
    // setCookie = (key, value, day) => {
    //     let expires = day * 86400 * 1000  // 时间转化成 ms
    //     let date = new Date( + new Date() + expires) // 当前时间加上要存储的时间
    //     document.cookie = `${key}=${value};expires=${date.toUTCString()}`
    // }
    jumpBack = () => {
        // 打哪儿来回哪去
        const { location } = this.props
        const from = location.state && location.state.from
       //  const article = location.state && location.state.article
        this.props.history.push({
            pathname: from,
            state: {
                // article
            }
        })
    }
    componentDidUpdate(){
        if(this.cookie.get("login") && this.cookie.get("customerid")){
            this.props.navigate('/acer/account/orders',{state:{from:this.props.location.pathname}})
        }
    }
    componentDidMount(){
        this.setState({loginHeight:this.register.current.clientHeight})
        console.log("Sign in cookies", this.cookie.get('customerid'))
        window.addEventListener('resize', this.windowResizeHandler)
        if(this.cookie.get("login") && this.cookie.get("customerid")){
            this.props.navigate('/acer/account/orders',{state:{from:this.props.location.pathname}})
        }
        PubSub.publish('routes',[{pathname:'/acer/signin',name:'signin',isCurrent:true}])
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.windowResizeHandler)
    }
    render() {
        return (
            <div className='signin'>
                <PageRoute/>
                <div className='signin-content' style={{display:this.state.session === "signin"?"flex":"none"}}>
                    <h1>Sign in</h1>
                    <div className='signin-error' style={{display:this.state.errorMessage?"flex":"none"}}>
                        <p><RiErrorWarningFill/></p>
                        <p>Your Customer ID or password is incorrect. Please try again. If you've forgotten your sign in details, please contact customer support for help.</p>
                    </div>
                    <div className='signin-main'>
                        <div className='signin-input' style={{display:this.cookie.get('login')?'none':'flex',height:window.innerHeight<=800?"":this.state.loginHeight}}>
                            <p>Customer ID:</p>
                            <input type='text' ref={this.username} onKeyUp={this.search} placeholder='Customer ID'/>
                            <p> </p>
                            <p>Password:</p>
                            <div className='signin-password-div'>
                                <input ref={this.password} type={this.state.showPassword ? 'text':'password'} onKeyUp={this.search} placeholder='password'/>
                                <button onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}>{this.state.showPassword ? <FaEye/> : <FaEyeSlash/>}</button>
                            </div>
                            <p> </p>
                            <div className='signin-input-button'>
                                <button className='signin-btn' onClick={()=>this.handleClick()}>{ this.state.isLogin ? 'SIGN OUT' : 'SIGN IN' }</button>
                                <button className='signin-forget-button' onClick={()=>{this.setState({session:"forget"})}} style={{display:"none"}}>Forgot your password?</button>
                            </div>
                        </div>
                        <div className='signin-new' ref={this.register} style={{display:"none"}}>
                            <h2>New Customer?</h2>
                            <ul>
                                Create an account with us and you'll be able to:
                                <li>Check out faster</li>
                                <li>Save multiple shipping addresses</li>
                                <li>Access your order history</li>
                                <li>Track new orders</li>
                                <li>Save items to your Wish List</li>
                            </ul>
                            <button>CREATE ACCOUNT</button>
                        </div>
                    </div>
                </div>
                <div className='signin-forget' style={{display:this.state.session === "forget"?"flex":"none"}}>
                    <h1>Rest Password</h1>
                    <p>Fill in your email below to request a new password. An email will be sent to the address below with 6-digit verification code.</p>
                    <p>Username/Email Address:</p>
                    <div>
                        <input className='active' value={this.state.resetAccount} onChange={(e)=>this.changeAccountValue(e,50)} disabled={this.state.sendCodeDisable} placeholder='username/email'/>
                        <button onClick={this.sendVerificationCode} disabled={this.state.sendCodeDisable}>SEND CODE</button>
                    </div>
                </div>
                <div className='signin-reset' style={{display:this.state.session === "reset"?"flex":"none"}}>
                    <h1>Change Password</h1>
                    <p>Password needs to contain at least one uppercase letter, one lowercase letter and one number. The length needs to between 8 and 50 characters. (only @$!%*?& are allowed be used as special characters)</p>
                    <p>Username/Email Address:</p>
                    <div className='sigin-rest-username'>
                        <input className={this.state.sendCodeDisable?"disabled":"active"} value={this.state.resetAccount} onChange={(e)=>this.changeAccountValue(e,50)} disabled={this.state.sendCodeDisable} placeholder='username/email'/>
                        <button className={this.state.sendCodeDisable?"disabled":""} onClick={this.sendVerificationCode} disabled={this.state.sendCodeDisable}>{this.state.resendCountDown===0?'RESEND CODE':this.state.resendCountDown}</button>
                    </div>
                    <p>Verification Code:</p>
                    <input type="text" maxLength="6" className='active' value={this.state.verificationCode} onChange={(e)=>this.changeVerificationValue(e,6)} placeholder='Verification Code'/>
                    <p>New  Password:</p>
                    <div className='signin-password-div'>
                        <input type={this.state.showNewPassword ? 'text':'password'} className='active' ref={this.newPassword} onKeyUp={this.search} placeholder='new password'/>
                        <button onClick={()=>{this.setState({showNewPassword:!this.state.showNewPassword})}}>{this.state.showNewPassword ? <FaEye/> : <FaEyeSlash/>}</button>
                    </div>
                    <p>Confirm Password:</p>
                    <div className='signin-password-div'>
                        <input type={this.state.showConfirmPassword ? 'text':'password'} className='active' ref={this.confirmPassword} onKeyUp={this.search} placeholder='confirm password'/>
                        <button onClick={()=>{this.setState({showConfirmPassword:!this.state.showConfirmPassword})}}>{this.state.showConfirmPassword ? <FaEye/> : <FaEyeSlash/>}</button>
                    </div>
                    <button onClick={()=>{this.setState({session:"signin"})}}>REST PASSWORD</button>
                </div>
            </div>
        );
    }
}
export default withRouter(SignIn)