import React, { Component } from 'react'
import './index.css'
import { FaArrowRight } from "react-icons/fa6";
import { BiSolidHomeAlt2 } from "react-icons/bi";
import { Link } from 'react-router-dom';
import PubSub from 'pubsub-js';
import withRouter from '../../withRouter'
 class PageRoute extends Component {
  constructor(props){
    super(props)
    this.state={
      routes:[]
    }
  }
  componentDidMount(){
    this.routes = PubSub.subscribe('routes',(_,data)=>this.setState({routes:[...data]}))
  }
  componentWillUnmount(){
    PubSub.unsubscribe(this.routes)
  }
  render() {
    return (
      <div className='pageroute'>
        <Link to={'/'}><BiSolidHomeAlt2/></Link>
        {
          this.state.routes?.map(route=>(
            <div key={`${route.name}-div`}>
              <p key={`${route.name}-p-1`}><FaArrowRight/></p>
              {!route.isCurrent ? <Link key={`${route.name}-link`} to={{pathname:route.pathname}} state={{from:this.props.location.pathname}}>{route.name}</Link>:<p key={`${route.name}-p-2`}>{route.name}</p>}
            </div>
          ))
        }
        {/* <p><FaArrowRight/></p>
        <Link to={'/acer/category/on-the-go'}>On-The-Go</Link>
        <p><FaArrowRight/></p>
        <Link to={'/acer/category/on-the-go/Tablets'}>Tablets</Link>
        <p><FaArrowRight/></p>
        <p>Acer Chromebook Tab 10 - 9.7" Tablet ARM Cortex A53 4GB Ram 32GB Flash Chrome OS | D651N-K9WT | NX.H0BAA.001</p> */}
      </div>
    )
  }
}
export default withRouter(PageRoute)