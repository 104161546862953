import React, { Component } from 'react'
import './index.css'
import imageNotFound from '../../media/image/image-not-found.jpeg'
import withRouter from '../../withRouter'
import axios from 'axios'
import serverInfo from '../../config'
// import userInfo from '../../userInfo'
import PubSub from 'pubsub-js'
import PageRoute from '../PageRoute'
// import { redirect } from 'react-router-dom'
class OrderPage extends Component {
    // orderInfo is used to store both orderInfo and invoiceInfo
    constructor(props){
        super(props)
        this.state={
            smessage:null,
            orderId:null,
            orderNumber:null,
            orderInfo:null,
            invoiceId:null,
            billingAddress:null,
            shippingAddress:null,
            status:null,
            isFirstTime:false,
            missingItems:[]
        }
        this.preventRefresh = function(){
            if(this.props.params.orderid === 'placed'){
                alert('Page has expired, please check your order in your order list.')
                this.props.navigate('/acer/account/orders',{state:this.props.location.pathname,replace:true})
            }
        }
        this.preventRefreshHandler = this.preventRefresh.bind(this)
    }
    componentDidUpdate(){
        
    }
    componentDidMount(){
        if(this.props.params.orderid === 'placed'){
            // console.log(this.props.location?.state?.orderData)
            if(this.props.location?.state?.isFirstTime && this.props.location?.state?.orderData){
                this.props.location.state.isFirstTime = false
                // console.log(this.props.params.orderid,this.props.location.state.orderData)
                const orderInfo = {...this.props.location.state.orderData.orderInfo}
                for(const item of orderInfo.line_items){
                    const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
                    const googleImageId = imageRegex.exec(item.main_image)[1]
                    const mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
                    item['main_image'] = mainImageUrl
                }
                this.setState({
                    orderId:this.props.params.orderid,
                    billingAddress:this.props.location.state.billingAddress,
                    shippingAddress:this.props.location.state.shippingAddress,
                    orderInfo,
                    status:"ordering",
                    isFirstTime:true,
                })
                axios.post(`${serverInfo.baseURL}/placeorder`,this.props.location.state.orderData,{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
                    res=>{
                        if(res.data.scode === 200){
                            PubSub.publish('cartItems',[])
                            this.setState({orderId:res.data.orderId,orderNumber:res.data.orderNumber,status:"confirmed"})
                        }else{
                            this.setState({status:"failed"})
                        }
                        this.setState({smessage:res.data.smessage})
                    },
                    rej=>console.log(rej)
                )
            }else{
                if(this.props.location?.state?.orderData){
                    this.setState({
                        isFirstTime:false,
                    })
                    this.props.navigate('/acer/account/orders',{state:this.props.location.pathname,replace:true})
                }else{
                    // redirect('/acer')                    
                }
            }
        }
        if(this.props.params.orderid && this.props.params.orderid !== 'placed'){
            axios.get(`${serverInfo.baseURL}/user/${serverInfo.customerid}/orders/${this.props.params.orderid}`,{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
                res=>{
                    
                    if(res.data.scode === 200){
                        const orderInfo = {...res.data.orderInfo.order}
                        // console.log('orders page',orderInfo)
                        for(const item of orderInfo.line_items){
                            const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
                            const googleImageId = imageRegex.exec(item.main_image)[1]
                            const mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
                            item['main_image'] = mainImageUrl
                            // console.log('orders page', item)
                        }
                        const missingItems = [],recordItem = {}
                        for(const item of orderInfo.line_items){
                            recordItem[item.item_id] = true
                        }
                        for(const item of orderInfo.zoho_line_items){
                            if(!recordItem[item.item_id]){
                                missingItems.push(item)
                            }
                        }

                        this.setState({
                            orderInfo:orderInfo,
                            orderId:res.data.orderInfo.order.order_id,
                            orderNumber: res.data.orderInfo.order.zoho_order_id,
                            billingAddress:res.data.orderInfo.billingAddress,
                            shippingAddress:res.data.orderInfo.shippingAddress,
                            stauts:orderInfo.status,
                            missingItems:missingItems
                        })
                    }else{
                        alert(res.data.smessage)
                    }
                },
                rej=>console.log(rej)
            )
        }
        if(this.props.params.invoiceid){
            axios.get(`${serverInfo.baseURL}/user/${serverInfo.customerid}/invoices/${this.props.params.invoiceid}`,{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.scode === 200){
                        const orderInfo = {...res.data.orderInfo.order}
                        for(const item of orderInfo.line_items){
                            const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
                            const googleImageId = imageRegex.exec(item.main_image)?.[1]
                            let mainImageUrl = null
                            if(googleImageId){
                                mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
                            }
                            item['main_image'] = mainImageUrl
                        }
                        const missingItems = [],recordItem = {}
                        for(const item of orderInfo.line_items){
                            recordItem[item.item_id] = true
                        }
                        for(const item of orderInfo.zoho_line_items){
                            if(!recordItem[item.item_id]){
                                missingItems.push(item)
                            }
                        }
                        this.setState({
                            orderInfo:orderInfo,
                            orderId:res.data.orderInfo.order.invoice_id,
                            orderNumber:res.data.orderInfo.order.zoho_invoice_id,
                            billingAddress:res.data.orderInfo.billingAddress,
                            shippingAddress:res.data.orderInfo.shippingAddress,
                            status:orderInfo.status,
                            missingItems:missingItems
                        })
                    }else{
                        alert(res.data.smessage)
                    }
                },
                rej=>console.log(rej)
            )
        }
        PubSub.publish('routes',[
            {pathname:'/acer/account',name:"Your Account",isCurrent:false},
            {pathname:this.props.params.orderid?`/acer/order/${this.props.params.orderid}`:`/acer/invoice/${this.props.params.invoiceid}`,name:this.props.params.orderid?"Your Order":"Your Invoice",isCurrent:true}
        ])
        window.addEventListener('beforeunload', this.preventRefreshHandler);
    }
    componentWillUnmount(){
        window.removeEventListener('beforeunload', this.preventRefreshHandler);
    }
  render() {
    console.log('order page ',this.state.orderInfo)
    return (
      <div className='orderpage'>
        <PageRoute/>
        <h2 style={{display:this.props.params.orderid === 'placed' && this.props.location?.state?.orderData && !this.state.isFirstTime?"":"none"}}>Order has been placed or page has expired, please check your orders in your order list.</h2>
        <h2 style={{display:this.props.params.orderid === 'placed' && !this.props.location?.state?.orderData?"":"none"}}>There is no order need to be placed.</h2>
        <div className='orderpage-content' style={{display:(this.props.params.orderid === 'placed' && !this.props.location?.state?.orderData) || (this.props.params.orderid === 'placed' && !this.state.isFirstTime)?"none":"flex"}}>
            <div className='orderpage-placing' style={{display:this.props.params.orderid === 'placed'?"flex":"none"}}>
                <h2>{this.state.smessage ? this.state.smessage:'Your order is processing, please do not refresh the page.'}</h2>
            </div>
            <div className='orderpage-title'>
                <h2>ORDER {!this.state.orderNumber || this.state.orderNumber === 'placed'?'PLACING':`#${this.state.orderNumber}`}</h2>
                <p>Order Date: {this.state.orderInfo?.created_time?.split('T')?.[0]}</p>
            </div>
            <div className='orderpage-summary'>
                <div className='orderpage-summary-customerinfo'>
                    <div>
                        <h4>Shipping Address</h4>
                        <p>{this.state.orderInfo?.shipping_attention ? this.state.orderInfo?.shipping_attention : `${this.state.shippingAddress?.first_name} ${this.state.shippingAddress?.last_name}`}</p>
                        <p>{this.state.shippingAddress?.company_name}</p>
                        <p>{this.state.orderInfo?.shipping_address_line_1 ? this.state.orderInfo?.shipping_address_line_1 : this.state.shippingAddress?.address_line_1}</p>
                        <p>{this.state.orderInfo?.shipping_address_line_2 ? this.state.orderInfo?.shipping_address_line_2 : this.state.shippingAddress?.address_line_2}</p>
                        <p>{this.state.orderInfo?.shipping_city && this.state.orderInfo?.shipping_province && this.state.orderInfo?.shipping_zip ? `${this.state.orderInfo?.shipping_city}, ${this.state.orderInfo?.shipping_province}, ${this.state.orderInfo?.shipping_zip}`: `${this.state.shippingAddress?.city}, ${this.state.shippingAddress?.province}, ${this.state.shippingAddress?.zip}`}</p>
                        <p>{this.state.orderInfo?.shipping_country ? this.state.orderInfo?.shipping_country : this.state.shippingAddress?.country}</p>
                    </div>
                    <div>
                        <h4>Billing Address</h4>
                        <p>{this.state.orderInfo?.billing_attention ? this.state.orderInfo?.billing_attention :`${this.state.billingAddress?.first_name} ${this.state.billingAddress?.last_name}`}</p>
                        <p>{this.state.billingAddress?.company_name}</p>
                        <p>{this.state.orderInfo?.billing_address_line_1 ? this.state.orderInfo?.billing_address_line_1 : this.state.billingAddress?.address_line_1}</p>
                        <p>{this.state.orderInfo?.billing_address_line_2 ? this.state.orderInfo?.billing_address_line_2 : this.state.billingAddress?.address_line_2}</p>
                        <p>{this.state.orderInfo?.billing_city && this.state.orderInfo?.billing_province && this.state.orderInfo?.billing_zip ? `${this.state.orderInfo?.billing_city}, ${this.state.orderInfo?.billing_province}, ${this.state.orderInfo?.billing_zip}`: `${this.state.billingAddress?.city}, ${this.state.billingAddress?.province}, ${this.state.billingAddress?.zip}`}</p>
                        <p>{this.state.orderInfo?.billing_country ? this.state.orderInfo?.billing_country : this.state.billingAddress?.country}</p>
                    </div>
                    <div>
                        <h4>Shipping Method</h4>
                        <p>{this.state.orderInfo?.shipping_method}</p>
                    </div>
                    <div>
                        <h4>Purchase Order No.</h4>
                        <p>{this.state.orderInfo?.purchase_order_id}</p>
                    </div>
                </div>
                <div className='orderpage-summary-price'>
                    <h4>Order Summary</h4>
                    <div>
                        <p>{`Subtotal`}</p>
                        <p>{`$${this.state.orderInfo?.subtotal?.toFixed(2)}`}</p>
                    </div>
                    <div style={{display:typeof(this.state.orderInfo?.shipping) !== 'object' && typeof(this.state.orderInfo?.shipping) !== 'undefined'?'flex':"none"}}>
                        <p>Shipping(tax inclusive)</p>
                        <p>${(this.state.orderInfo?.shipping + this.state.orderInfo?.shipping_tax)?.toFixed(2)}</p>
                    </div>
                    <div>
                        <p>Taxes</p>
                        <p>${this.state.orderInfo?.items_tax?.toFixed(2)}</p>
                    </div>
                    <div>
                        <p style={{color:"black"}}>Total</p>
                        <p>${this.state.orderInfo?.total?.toFixed(2)}</p>
                    </div>
                </div>
            </div>
            {/* <div className='orderpage-shipping'>
                <div>
                    <h2>SHIPMENT</h2>
                    <p>SHIPPED 03/01/2024</p>
                </div>
                <button style={{display:"none"}}>TRACK</button>
            </div> */}
            <div className='orderpage-itemlist'>
                <table>
                    <tbody>
                        <tr>
                            <td>ITEM</td>
                            <td>PRICE</td>
                            <td>QTY</td>
                            <td>STATUS/ACTION</td>
                        </tr>
                        {
                            this.state.orderInfo?.line_items?.map(item=>(
                                <tr key={`${item.item_id}-tr`}className='oderpage-itemlist-item'>
                                    <td key={`${item.item_id}-td-1`}>
                                        <div key={`${item.item_id}-div`}>
                                            <img key={`${item.item_id}-img`} src={item.main_image?item.main_image:imageNotFound} alt='err'/>
                                            <p key={`${item.item_id}-p`} >{`(${item.item_condition}) ${item.item_name}`}</p>
                                        </div>
                                    </td>
                                    <td key={`${item.item_id}-td-2`}>${item.item_rate?.toFixed(2)}</td>
                                    <td key={`${item.item_id}-td-3`}>{item.quantity}</td>
                                    <td key={`${item.item_id}-td-4`}>{this.state.status}</td>
                                </tr>
                            ))
                        }
                        {
                            this.state.missingItems?.map(item=>(
                                <tr key={`${item.item_id}-tr`}className='oderpage-itemlist-item'>
                                    <td key={`${item.item_id}-td-1`}>
                                        <div key={`${item.item_id}-div`}>
                                            <img key={`${item.item_id}-img`} src={imageNotFound} alt='err'/>
                                            <p key={`${item.item_id}-p`} >{`${item.item_condition ? `(${item.item_condition}) `:""}${item.zoho_item_name}`}</p>
                                        </div>
                                    </td>
                                    <td key={`${item.item_id}-td-2`}>${item.item_rate?.toFixed(2)}</td>
                                    <td key={`${item.item_id}-td-3`}>{item.quantity}</td>
                                    <td key={`${item.item_id}-td-4`}>{this.state.status}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>

      </div>
    )
  }
}

export default withRouter(OrderPage)