import React, { Component } from 'react'
import './index.css'
import Logo from '../Header/Logo'
import {FaFacebookF,FaInstagram,FaRss,FaTwitter,FaPaypal} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import withRouter from '../../withRouter'
class Footer extends Component {
    render() {
        return (
            <div className="coupon-footer">
                <div className='coupon-footer-content'>
                    <div className="footer-main-content">
                        <div className="footer-kbc-info">
                            <div>
                                <Logo width={'100%'}/>
                            </div>
                            <div>
                                <h4>Contact Us</h4>
                                <p>Phone: <a href="tel:905-731-1928">1-905-731-1928</a></p>
                                <p>General Inquiries: <a href="mailto:admin@alcmicro.com">admin@alcmicro.com</a></p>
                                <p>Sales Info: <a href="mailto:sales@alcmicro.com">sales@alcmicro.com</a></p>
                            </div>
                        </div>
                        <div className='footer-category footer-category-navigate'>
                            <h2>Navigate</h2>
                            {/* <div className="footer-store-categories" > */}
                            <ul className="footer-pages">
                                <li><Link to={{pathname:'/acer/contact-us'}} state={{from:this.props.location.pathname}}>Contact Us</Link></li>
                                {/* <li><Link to={{pathname:'/acer/faq'}} state={{from:this.props.location.pathname}}>FAQ</Link></li> */}
                                <li><a href="https://rma.alcmicro.com/Data/request.html">RMA Request</a></li>
                                <li><Link to={{pathname:'/acer/location'}} state={{from:this.props.location.pathname}}>Our Location</Link></li>
                                {/* <li><a href="https://store.acer.com/en-ca/">Acer Store for New</a></li> */}
                                {/* <li><Link to={{pathname:'/acer/subscribe'}} state={{from:this.props.location.pathname}}>Subscribe</Link></li> */}
                                {/* <li><Link to={{pathname:'/acer/warranty'}} state={{from:this.props.location.pathname}}>Warranty</Link></li> */}
                                {/* <li><Link to={{pathname:'/acer/extended-warranty/main'}} state={{from:this.props.location.pathname}}>Extended Warranty</Link></li> */}
                                {/* <li><Link to={{pathname:'/acer/blog'}} state={{from:this.props.location.pathname}}>Blog</Link></li> */}
                                {/* <li><Link to={{pathname:'/acer/legal-notice'}} state={{from:this.props.location.pathname}}>Legal Notice</Link></li> */}
                                {/* <li><Link to={{pathname:'/acer/term-of-sale'}} state=[{from:this.props.location.pathname}}>Terms of Sale</Link></li> */}
                                {/* <li><a href="https://kickbackclub.ca/shop-store-category/health-and-beauty/">Sitemap</a></li> */}
                            </ul>
                            {/* </div> */}
                        </div>
                        <div className='footer-category'>
                            <h2>Categories</h2>
                            <ul className="footer-pages">
                                <li><Link to={{pathname:'/acer/category/Sale'}} state={{from:this.props.location.pathname}}>Sale</Link></li>
                                <li><Link to={{pathname:'/acer/category/BrandNew'}} state={{from:this.props.location.pathname}}>Brand New</Link></li>
                                <li><Link to={{pathname:'/acer/category/Notebook'}} state={{from:this.props.location.pathname}}>Notebook</Link></li>
                                <li><Link to={{pathname:'/acer/category/Desktop'}} state = {{from:this.props.location.pathname}}>Desktop</Link></li>
                                <li><Link to={{pathname:'/acer/category/AIO'}} state={{from:this.props.location.pathname}}>All-In-One</Link></li>
                                <li><Link to={{pathname:'/acer/category/Chromebook'}} state={{from:this.props.location.pathname}}>Chromebook</Link></li>
                                <li><Link to={{pathname:'/acer/category/Monitor'}} state={{from:this.props.location.pathname}}>Monitor</Link></li>
                                <li><Link to={{pathname:'/acer/category/Tablet'}} state={{from:this.props.location.pathname}}>Tablet</Link></li>
                                <li><Link to={{pathname:'/acer/category/Accessories'}} state={{from:this.props.location.pathname}}>Accessories</Link></li>
                                <li><Link to={{pathname:'/acer/category/Gaming'}} state={{from:this.props.location.pathname}}>Gaming</Link></li>
                                <li><Link to={{pathname:'/acer/category/ScratchDent'}} state={{from:this.props.location.pathname}}>Scratch & Dent</Link></li>
                                <li><Link to={{pathname:'/acer/category/Warranty'}} state={{from:this.props.location.pathname}}>Warranty</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                    {/* <div className="footer-top-bar">
                        <p>Kickback Club offers a diverse gallery of stores with the best offers on the market</p>
                    </div> */}
                </div>
                <div className="footer-bottom-bar">
                        <div className='footer-bottom-content'>
                            <div className="footer-bottom-text footer-bottom-web">
                                <p>© 2024 AlcMicro Reserved</p>
                            </div>
                            {/* <div className="footer-bottom-media">
                                <div><a href="#"><FaFacebookF/></a></div>
                                <div><a href="#"><FaInstagram/></a></div>
                                <div><a href="#"><FaRss/></a></div>
                                <div><a href="#"><FaTwitter/></a></div>
                                <div><a href="#"><FaPaypal/></a></div>
                            </div> */}
                            <div className="footer-bottom-text footer-bottom-mobile">
                                <p>© 2024 AlcMicro Reserved</p>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Footer)