import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import imageNotFound from '../../media/image/image-not-found.jpeg'
import withRouter from '../../withRouter'
import serverInfo from '../../config'
import axios from 'axios'
import PubSub from 'pubsub-js'
import Cookies from 'universal-cookie'
class ProductDetail extends Component {
  constructor(props){
    super(props)
    this.cookie = new Cookies()
    this.state={
        itemAdd:true,
        item_id:null,
        product:null,
        imageUrl:null,
        isLogin:this.cookie.get('login') ? true : false,
        percentage:this.cookie.get('percentage') ? this.cookie.get('percentage') : 0,
        discountPrice: null
    }
  }
  addToCart(){
    if(this.cookie.get('login') && this.cookie.get('customerid') && this.state?.product?.stock > 0 ){
        // console.log(this.cookie.get('customerid'))
        const itemData = {
            itemData:{
                customer_id: this.cookie.get('customerid',{doNotParse:1}),
                item_id:this.state.item_id,
                sku:this.state.product?.sku,
                quantity:1,
                item_condition:this.state.product.item_condition
            }
        }
        axios.post(`${serverInfo.baseURL}/user/cart/add`,itemData,{headers:{'customerid':serverInfo.customerid,'percentage':serverInfo.percentage,'authorization':serverInfo.token}}).then(
            res=>{
                if(res.data.scode === 200){
                    // console.log('cartItems',res.data.cartItems)
                    PubSub.publish('cartItems',[...res.data.cartItems])
                    const cartItems = [...res.data.cartItems]
                    const cartItemsNumber = res.data.cartItems?.reduce((total,current)=>total+current.quantity,0)
                    const cartItemsSubtotal = res.data.cartItems?.reduce((total,current)=>{
                      if(current.item_condition === 'ScratchDent' && current.personal_scratch_dent_price && current.personal_scratch_dent_price!==0){
                        return total+(current.personal_scratch_dent_price*current.quantity)
                      }else{
                          if(current.is_sale && current.personal_sale_price && current.personal_sale_price !==0){
                              return total+(current.personal_sale_price*current.quantity)
                          }else{
                              return total+(current.personal_price*current.quantity)
                          }
                      }
                    },0)
                    this.props.navigate(`/acer/add-to-cart`,{state:{from:this.props.location.pathname,product:this.state.product,imageUrl:this.state.imageUrl,quantity:1,windowYOffset:window.pageYOffset,cartItemsNumber,cartItemsSubtotal,cartItems}})
                }else{
                    console.log(res.data.scode,res.data.smessage)
                    alert(res.data.smessage)
                }
            },
            rej=>console.log(rej)
        )
    }
    
  }
  componentDidUpdate(){
      if(this.props.product && !this.state.product){
        //   console.log(this.props.product)
          const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
          const googleImageId = imageRegex.exec(this.props.product.main_image)?.[1]
          let mainImageUrl = null
          if(googleImageId){
              mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
          }else{
              console.log("product Detail",this.props.product.main_image,this.props.product.zoho_id)
          }
          const item_id = this.props.product.zoho_id ? this.props.product.zoho_id : this.props.product.item_id
          this.setState({product:this.props.product, imageUrl:mainImageUrl,item_id})
      }
  }
  componentDidMount(){
      this.isLoginSub = PubSub.subscribe('isLogin',(_,data)=>{
          this.setState({isLogin:data})
      })
      this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{
        this.setState({percentage:data})
    })
      if(this.props.product){
        //   console.log(this.props.product)
          const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
          const googleImageId = imageRegex.exec(this.props.product.main_image)?.[1]
          let mainImageUrl = null
          if(googleImageId){
              mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
          }else{
              console.log("product Detail",this.props.product.main_image,this.props.product.zoho_id)
          }
          const item_id = this.props.product.zoho_id ? this.props.product.zoho_id : this.props.product.item_id
        //   console.log(mainImageUrl)
          this.setState({
              product:this.props.product,
              imageUrl:mainImageUrl,
              item_id,
              discountPrice:this.props.product.item_condition === "ScratchDent" && this.props.product.personal_scratch_dent_price && this.props.product.personal_scratch_dent_price !== 0 ? this.props.product.personal_scratch_dent_price : (this.props.product.is_sale && this.props.product.personal_sale_price && this.props.product.personal_sale_price !== 0? this.props.product.personal_sale_price : null)
          })
      }
  }
  componentWillUnmount(){
    PubSub.unsubscribe(this.isLoginSub)
    PubSub.unsubscribe(this.percentageSub)
  }
  render() {
    return (
      <div className='productdetail'>
        <div className='productdetail-display'>
          <div className='productdetail-tag-group'>
            <p className='productdetail-tag' style={{display:this.state?.product?.is_sale && this.state.discountPrice?"flex":"none"}}>SALE</p>
            <p className='productdetail-tag' style={{display:this.state?.product?.item_condition === "BrandNew"?"flex":"none",backgroundColor:"rgb(177,211,136)"}}>NEW</p>
            <p className='productdetail-tag' style={{display:this.state?.product?.item_condition === "ScratchDent" && this.state.discountPrice?"flex":"none",backgroundColor:"RED",fontSize:"8px",fontWeight:"bold"}}>SCRATCH</p>
          </div>
          <Link to={{pathname:`/acer/product/${this.state.item_id}/${this.state.product?.item_condition}`}} state={{from:this.props.location.pathname}} target='_blank'><img src={this.state.product?.is_active && this.state.imageUrl ? this.state.imageUrl : imageNotFound} alt="err" onError={(e)=>{e.target.onError = null; e.target.src=imageNotFound}}/></Link>
        </div>
        <div className='productdetail-information'>
          <h3><Link to={{pathname:`/acer/product/${this.state.item_id}/${this.state.product?.item_condition}`}} state={{from:this.props.location.pathname}} target='_blank'>{this.state?.product?.item_name}</Link></h3>
            <div className='productdetail-price' style={{display:this.state.isLogin?"flex":"none"}}>
                <p>PRICE:</p>
                <p className={(this.state?.product?.item_condition === "ScratchDent" || this.state?.product?.is_sale) && this.state.discountPrice ?'origin-price':'sell-price'}>{`$${this.state?.product?.personal_price?.toFixed(2)}`}</p>
                <p className='sell-price' style={{display:(this.state?.product?.item_condition === "ScratchDent" || this.state?.product?.is_sale) && this.state.discountPrice ?"flex":"none"}}>{`$${this.state?.product?.item_condition === "ScratchDent"?this.state?.product?.personal_scratch_dent_price?.toFixed(2):this.state?.product?.personal_sale_price?.toFixed(2)}`}</p>
            </div>
            <div className='productdetail-description'>
                {this.state?.product?.description}
            </div>
            <button onClick={()=>this.addToCart()} 
                disabled={!this.state.isLogin ? true : (this.state?.product?.item_condition === "ScratchDent" ? (this.state?.product?.scratch_dent_stock > 0 ? false : true) : (this.state?.product?.stock > 0 ? false:true))} 
                className={!this.state.isLogin ? "disabled" : (this.state?.product?.item_condition === "ScratchDent" ? (this.state?.product?.scratch_dent_stock > 0 ? "" : "disabled") : (this.state?.product?.stock > 0 ? "":"disabled"))}
            >
                {!this.state.isLogin ? "Please Login" : (this.state?.product?.item_condition === "ScratchDent" ? (this.state?.product?.scratch_dent_stock > 0 ? "ADD TO CART" : "OUT OF STOCK") : (this.state?.product?.stock > 0 ? "ADD TO CART":"OUT OF STOCK"))}
            </button>
            {/* <button onClick={()=>this.addToCart()} disabled={this.state?.product?.stock > 0 && this.state.isLogin ? false : true} className={this.state.product?.stock > 0 && this.state.isLogin ? "":"disabled"}>
                {this.state.isLogin ? (this.state?.product?.stock >0?"ADD TO CART":"OUT OF STOCK"):"Please Login"}
            </button> */}
        </div>
      </div>
    )
  }
}
export default withRouter(ProductDetail)