import React, { Component } from 'react'
import './index.css'
import PageRoute from '../PageRoute'
import Address from './Address'
import { IoMdAdd } from "react-icons/io";
import { MdOutlineCreditCard } from "react-icons/md";
import ProductBreif from '../ProductBrief';
import withRouter from '../../withRouter';
import { Link } from 'react-router-dom';
import PubSub from 'pubsub-js';
import axios from 'axios'
import Cookies from 'universal-cookie'
import serverInfo from "../../config.js"
import userInfo from '../../userInfo';
import { CSVLink } from 'react-csv'

class AccountPage extends Component {
    constructor(props){
        super(props)
        this.cookie = new Cookies()
        // for account setting update 
        this.firstNameRef = React.createRef()
        this.lastNameRef = React.createRef()
        this.companyRef = React.createRef()
        this.emailRef = React.createRef()
        this.phoneNumberRef = React.createRef()
        this.currentPasswordRef = React.createRef()
        this.newPasswordRef = React.createRef()
        this.confirmPasswordRef = React.createRef()
        // for address update and creation
        this.addressFirstNameRef = React.createRef()
        this.addressLastNameRef = React.createRef()
        this.addressCompanyRef = React.createRef()
        this.addressPhoneNumberRef = React.createRef()
        this.addressLine1Ref = React.createRef()
        this.addressLine2Ref = React.createRef()
        this.addressCityRef = React.createRef()
        this.addressCountryRef = React.createRef()
        this.addressProvinceRef = React.createRef()
        this.addressZipRef = React.createRef()
        // for update and create wish list
        this.wishListNameRef = React.createRef()
        this.wishListShareableRef = React.createRef()
        this.state={
            isMobile:window.innerWidth<=800?true:false,
            previousPropsSession:this.props.params.session?this.props.params.session:"orders",
            session:this.props.params.session?this.props.params.session:"orders",
            currentProvince:"Choose a State",
            currentCountry:"Canada",
            errors:{},
            data:{
                orders:null,
                messages:null,
                addresses:null,
                'wishLists':null,
                "recentlyViewed":null,
                "accountSettings":null,
                "addressForm":null,
                "wishListCreate":null,
                "wishListItems":null,
                "subscription":null
            },
            currentWishItemsListId:null, //wishlistitems list id
            currentWishListId:null, // wishlistcreate list id
            currentAddressId:null,
            title:{
                orders:"Orders",
                messages:"Messages",
                addresses:"Addresses",
                'wishLists':"Wish Lists",
                "recentlyViewed":"Recently Viewed",
                "accountSettings":"Account Settings",
                "addressForm":"Address Edit",
                "wishListCreate":"Wish List Create",
                "wishListItems":"Wish List Items",
                subscription:"Subscription"
            },
            percentage: this.cookie.get('percentage') ? this.cookie.get('percentage') : 0,
            exportItems:[]
        }
        this.windowResize = function(){
            if(window.innerWidth<=800 && !this.state.isMobile){
                this.setState({isMobile:true})
            }
            if(window.innerWidth>800 && this.state.isMobile){
                this.setState({isMobile:false})
            }
        }
        this.windowResizeHandler = this.windowResize.bind(this)
    }
    logout =() =>{
        this.cookie.set('login',false)
        this.cookie.set('customerid',null)
        this.cookie.set('contactPersonId',null)
        this.cookie.set('contactName',null)
        this.cookie.set('firstName',null)
        this.cookie.set('lastName',null)
        this.cookie.set('company',null)
        this.cookie.set('email',null)
        this.cookie.set('phoneNumber',null)
        this.cookie.set('username',null)
        console.log(this.cookie.get('login'))
        PubSub.publish('isLogin',false)
        PubSub.publish('cartItems',[])
        PubSub.publish('addresses',[])
        serverInfo.resetConfig()
        userInfo.resetUserInfo()
        this.props.navigate('/acer/signin',{state:{from:this.props.location.pathname}})
    }
    updateAccountInfo = (event) => {
        const errors = {}
        // 10 digits number 
        const phoneRegex = /^\d{10}$/
        // at least 8 number and at least contain one uppercase letter, one lowercase letter and a number
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,50}$/
        // email form regex check
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        errors['firstName'] = this.dataFormCheck(this.firstNameRef.current.value,true,50) ? null: "First name cannot be empty and the length needs to be under 50 characters"
        errors['lastName'] = this.dataFormCheck(this.lastNameRef.current.value,true,50) ? null: "Last name cannot be empty and the length needs to be under 50 characters"
        errors['email'] = emailRegex.exec(this.emailRef.current.value) ? null: "Email cannot be empty, or Email format error, or Email length exceeds 50 chracters"
        errors['company'] = this.dataFormCheck(this.firstNameRef.current.value,false,50) ? null: "Comapny length needs to be under 50 characters"
        errors['phoneNumber'] = this.phoneNumberRef.current.value.length === 0 || (this.phoneNumberRef.current.value.length > 0 && phoneRegex.exec(this.phoneNumberRef.current.value)) ? null : "Phone Number error, Please only enter 10 digit Number."
        errors['newPassword'] =  this.newPasswordRef.current.value.length === 0 || passwordRegex.exec(this.newPasswordRef.current.value)? null : "Password needs to contain at least one uppercase letter, one lowercase letter and one number. The length needs to between 8 and 50 characters. (only @$!%*?& are allowed be used as special characters)"
        errors['confirmPassword'] = this.newPasswordRef.current.value === this.confirmPasswordRef.current.value ? null : "Confirm password is not the same as the new pasword."
        errors['currentPassword'] = this.newPasswordRef.current.value.length !== 0 && this.currentPasswordRef.current.value.length === 0 ? "Current password cannot be empty when you try to update password." : null
        if(!errors['firstName'] && !errors['lastName'] && !errors['email'] && !errors['company'] && !errors['phoneNumber'] && 
        !errors['newPassword'] && !errors['confirmPassword'] && !errors['currentPassword']){
            const accountInfo = {
                customerInfo:{
                    customer_id:serverInfo.customerid,
                    contact_person_id: serverInfo.contactPersonId,
                    contact_name: serverInfo.contactName,
                    first_name: this.firstNameRef.current.value,
                    last_name: this.lastNameRef.current.value,
                    email: this.emailRef.current.value,
                    company: this.companyRef.current.value,
                    phone_number: this.phoneNumberRef.current.value,
                    password: this.currentPasswordRef.current.value,
                    newPassword: this.newPasswordRef.current.value.length > 0 ? this.newPasswordRef.current.value : null
                }
            }
            axios.post(`${serverInfo.baseURL}/user/information/update`,accountInfo,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.scode === 200 || res.data.scode === 201){
                        this.cookie.set('firstName',res.data?.customerInfo?.first_name)
                        this.cookie.set('lastName',res.data?.customerInfo?.last_name)
                        this.cookie.set('company',res.data?.customerInfo?.company?res.data?.customerInfo?.company:"")
                        this.cookie.set('email',res.data?.customerInfo?.email)
                        this.cookie.set('phoneNumber',res.data?.customerInfo?.phone_number?res.data?.customerInfo?.phone_number:"")
                        alert('Acctount Information Updated successfully!')
                    }else if(res.data.scode === 401){
                        alert(res.data.smessage)
                        errors['currentPassword'] = 'Current password is wrong.'
                        this.setState({errors:{...errors}})
                    }else{
                        alert('Failed to update acctount information, please try again.')
                    }
                },
                rej=>console.log(rej)
            )
        }
        this.setState({errors:{...errors}})
        // console.log(this.newPasswordRef.current.value.length)
        event.preventDefault()
    }
    //true means right, false means wrong
    dataFormCheck = (value,isMandatory,maxLength) =>{
        if(isMandatory){
            return (!value || value === "" || value.length > maxLength) ? false : true
        }else{
            return (!value?.length || value?.length <= maxLength) ? true : false
        }
    }
    saveWishList = (event) =>{
        const errors = {}
        errors['wishListName'] = this.dataFormCheck(this.wishListNameRef.current.value,true,50) ? null : "Wish list name cannot be empty and the length needs to be under 50 characters"
        if(!errors['wishListName']){
            const listData = {
                wishList:{
                    list_name:this.wishListNameRef.current.value,
                    // is_public:this.wishListShareableRef.current.checked
                    is_public:false
                }
            }
            if(this.state.currentWishListId){
                axios.post(`${serverInfo.baseURL}/user/${serverInfo.customerid}/wishLists/${this.state.currentWishListId}/edit`,listData,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                        if(res.data.scode === 200){
                            const newData = {...this.state.data}
                            newData['wishLists'] = [...res.data.wishLists]
                            userInfo.updateInfo('wishLists',res.data.wishLists)
                            PubSub.publish('wishLists',res.data.wishLists)
                            this.setState({data:{...newData}})
                            this.props.navigate('/acer/account/wishLists',{state:{...this.props.location.state},replace:true})
                        }else if(res.data.scode === 201){
                            alert('Wish lists has been updated succesfully, if updated it is not showing correctly, please refresh the page!')
                            this.props.navigate('/acer/account/wishLists',{state:{...this.props.location.state},replace:true})
                        }else{
                            alert('wish list failed to updated, please try again!')
                        }
                    },
                    rej=>console.log(rej)
                )
            }else{
                listData['wishList']['customer_id'] = serverInfo.customerid
                listData['wishList']['list_id'] = `${serverInfo.customerid}-${new Date().getTime()}`
                axios.post(`${serverInfo.baseURL}/user/wishList/create`,listData,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                        if(res.data.scode === 200){
                            const newData = {...this.state.data}
                            newData['wishLists'] = [...res.data.wishLists]
                            userInfo.updateInfo('wishLists',res.data.wishLists)
                            PubSub.publish('wishLists',res.data.wishLists)
                            this.setState({data:{...newData}})
                            this.props.navigate('/acer/account/wishLists',{state:{...this.props.location.state},replace:true})
                        }else if(res.data.scode === 201){
                            alert('Wish lists has been created succesfully, if updated it is not showing correctly, please refresh the page!')
                            this.props.navigate('/acer/account/wishLists',{state:{...this.props.location.state},replace:true})
                        }else{
                            alert(res.data.smessage)
                        }
                    },
                    rej=>console.log(rej)
                )
            }
        }
        event.preventDefault()
        // this.props.navigate('/acer/account/wishLists',{state:{from:this.props.location.pathname}})
    }
    saveAddress = (event)=>{
        const errors = {}
        const zipRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\d[ABCEGHJ-NPRSTV-Z]\d$/i
        const phoneRegex = /^\d{10}$/
        errors['addressFirstName'] = this.dataFormCheck(this.addressFirstNameRef.current.value,true,50) ? null : "First Name cannot be empty or exceed max length (50)."
        errors['addressLastName'] = this.dataFormCheck(this.addressLastNameRef.current.value,true,50) ? null : "Last Name cannot be empty or exceed max length (50)."
        errors['addressCompany'] = this.dataFormCheck(this.addressCompanyRef.current.value,false,50) ? null : "Exceed max length (50)."
        errors['addressLine1'] = this.dataFormCheck(this.addressLine1Ref.current.value,true,500) ? null : "Address Line 1 cannot be empty or exceed max length (500)."
        errors['addressLine2'] = this.dataFormCheck(this.addressLine2Ref.current.value,false,500) ? null : "Exceed max length (500)."
        errors['addressCity'] = this.dataFormCheck(this.addressCityRef.current.value,true,100) ? null : "City cannot be empty or exceed max length (100)."
        errors['addressProvince'] = this.addressProvinceRef.current.value !== 'Choose a State'? null : "Please select province"
        errors['addressZip'] = zipRegex.exec(this.addressZipRef.current.value) ? null : "Zip code error, Do not split zip code with space or any other chracter."
        errors['addressPhoneNumber'] = phoneRegex.exec(this.addressPhoneNumberRef.current.value) ? null : "Phone Number error, Please only enter 10 digit Number."
        this.setState({
            errors:{...errors}
        })
        if(!errors['addressFirstName'] && !errors['addressLastName'] && !errors['addressCompany'] && !errors['addressLine1'] &&
        !errors['addressLine2'] && !errors['addressCity'] && !errors['addressProvince'] && !errors['addressZip'] && !errors['addressPhoneNumber']){
            const addressData = {
                addressInfo:{
                    first_name: this.addressFirstNameRef.current.value,
                    last_name: this.addressLastNameRef.current.value,
                    company_name: this.addressCompanyRef.current.value?this.addressCompanyRef.current.value:"",
                    phone_number: this.addressPhoneNumberRef.current.value,
                    address_line_1: this.addressLine1Ref.current.value,
                    address_line_2: this.addressLine2Ref.current.value ? this.addressLine2Ref.current.value : "",
                    city: this.addressCityRef.current.value,
                    country: this.addressCountryRef.current.value,
                    province: this.addressProvinceRef.current.value,
                    zip: this.addressZipRef.current.value
                }
            }
            if(this.state.currentAddressId){
                axios.post(`${serverInfo.baseURL}/user/${serverInfo.customerid}/address/${this.state.currentAddressId}/edit`,addressData,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                        if(res.data.scode === 200){
                            const newData = {...this.state.data}
                            newData['addresses'] = [...res.data.addresses]
                            userInfo.updateInfo('addresses',res.data.addresses)
                            PubSub.publish('addresses',res.data.addresses)
                            this.setState({data:{...newData}})
                            this.props.navigate(this.props.location.state.from?this.props.location.state.from:'/acer/account/addresses',{state:{...this.props.location.state},replace:true})
                        }else if(res.data.scode === 201){
                            alert('Address has been updated succesfully, if updated address is not showing correctly, please refresh the page!')
                            this.props.navigate(this.props.location.state.from?this.props.location.state.from:'/acer/account/addresses',{state:{...this.props.location.state},replace:true})
                        }else{
                            alert('Address failed to updated, please try again!')
                        }
                    },
                    rej=>console.log(rej)
                )
            }else{
                addressData['addressInfo']['customer_id'] = serverInfo.customerid
                axios.post(`${serverInfo.baseURL}/user/address/create`,addressData,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                        if(res.data.scode === 200){
                            const newData = {...this.state.data}
                            newData['addresses'] = [...res.data.addresses]
                            userInfo.updateInfo('addresses',res.data.addresses)
                            PubSub.publish('addresses',res.data.addresses)
                            this.setState({data:{...newData}})
                            this.props.navigate(this.props.location.state.from?this.props.location.state.from:'/acer/account/addresses',{state:{...this.props.location.state},replace:true})
                        }else if(res.data.scode === 201){
                            alert('Address has been created succesfully, if new address is not showing, please refresh the page!')
                            this.props.navigate(this.props.location.state.from?this.props.location.state.from:'/acer/account/addresses',{state:{...this.props.location.state},replace:true})
                        }else{
                            alert(res.data.smessage)
                        }
                    },
                    rej=>console.log(rej)
                )
            }
            if(this.props.location.state.from){
                this.props.navigate(this.props.location.state.from,{state:{...this.props.location.state},replace:true})
            }else{
                this.props.navigate('/acer/account/addresses',{state:{from:'/acer/account/addresses'},replace:true})
            }
        }else{
            alert('Some information you entered is not meet the requirement, please check!')
        }
        
        event.preventDefault()
    }

    getExportedData = () =>{
        if(this.state.exportItems.length <= 0){
            axios.get(`${serverInfo.baseURL}/export`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.scode === 200){
                        const csvData = []
                        // console.log(res.data.itemList)
                        for(const item of res.data.itemList){
                            
                            const csvItem = {
                                "Product Model": item.product_model.replace('\"','\"\"'),
                                "SKU":item.sku,
                                "Category":item.category,
                                "Stock":item.stock ? item.stock : 0 ,
                                "S&D Stock":item.scratch_dent_stock ? item.scratch_dent_stock : 0,
                                "Price":item.personal_price ? item.personal_price?.toFixed(2):null,
                                "Sale Price":item.personal_sale_price ? item.personal_sale_price?.toFixed(2):null,
                                "S&D Price":item.personal_scratch_dent_price ? item.personal_scratch_dent_price?.toFixed(2) : null,
                            }
                            csvData.push(csvItem)
                        }
                        this.setState({
                            exportItems:[...csvData],
                            session:'export'
                        })
                    }else{
                        alert(res.data.smessage)
                    }
                },
                rej=>{
                    console.log(rej)
                }
            )
        }else{
            this.setState({
                session:'export'
            })
        }
        
    }

    componentDidUpdate(){
        const session = this.props.params.session ? this.props.params.session : 'orders'
        if(!this.cookie.get('login')){
            this.props.navigate('/acer/signin',{state:{from:this.props.location.pathname}})
        }else{
            // if the session is changed, reset the session as current session and clear errors records
            if(session && session !== this.state.previousPropsSession){
                // console.log('update',session, this.state.previousPropsSession)
                this.setState({session:session, previousPropsSession:session,errors:{}})
            }
            //get information of current session information from server if there is no data yet, and clear errors information
            if((!this.state.data[session] && session!=="addressForm" && session!=="wishListCreate" && session!=="accountSettings") || 
            (this.props.params.sessionid && ((session === 'addressForm' && this.props.params.sessionid !== this.state.currentAddressId) || 
                (session === 'wishListItems' && this.props.params.sessionid !== this.state.currentWishItemsListId) || 
                (session === 'wishListCreate' && this.props.params.sessionid !== this.state.currentWishListId)))){
                const sessionIdString = this.props.params.sessionid ? `/${this.props.params.sessionid}` : ""
                // console.log('account update',this.state.percentage)
                axios.get(`${serverInfo.baseURL}/user/${this.cookie.get('customerid',{doNotParse:1})}/${session}${sessionIdString}`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                        console.log(res.data)
                        if(res.data.scode === 200){
                            // console.log('update',res.data[session])
                            const newData = {...this.state.data}
                            newData[session] = [...res.data[session]]
                            this.setState({data:{...newData},errors:{}})
                            if(this.props.params.sessionid){
                                if(session === 'wishListItems'){
                                    this.setState({currentWishItemsListId:this.props.params.sessionid})
                                }
                                if(session === 'wishListCreate'){
                                    this.setState({currentWishListId:this.props.params.sessionid})
                                }
                                if(session === 'addressForm'){
                                    this.setState({
                                        currentAddressId:this.props.params.sessionid,
                                        currentCountry:res.data[session][0].country,
                                        currentProvince:res.data[session][0].province
                                    })
                                }
                            }
                        }else{
                            alert(`${res.data.smessage}`)
                        }
                    },
                    rej=>console.log(rej)
                )
            }
        }
    }
    componentDidMount(){
        // console.log('mount',session)
        window.addEventListener('resize',this.windowResizeHandler)
        if(!this.cookie.get('login')){
            this.props.navigate('/acer/signin',{state:{from:this.props.location.pathname}})
        }
        if(!this.props.params.session){
            this.setState({session:"orders"})
        }
        const session = this.props.params.session ? this.props.params.session : 'orders'
        if((!this.state.data[session] && session!=="addressForm" && session!=="wishListCreate" && session!=="accountSettings") || 
        (this.props.params.sessionid && this.props.params.sessionid !== this.state.currentAddressId && this.props.params.sessionid !== this.state.currentWishItemsListId && 
        this.props.params.sessionid !== this.state.currentWishListId)){
            const sessionIdString = this.props.params.sessionid ? `/${this.props.params.sessionid}` : ""
            axios.get(`${serverInfo.baseURL}/user/${this.cookie.get('customerid',{doNotParse:1})}/${session}${sessionIdString}`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                    console.log(`ACCOUNTmount`,res.data)
                    // console.log('mount',res.data[session])
                    if(res.data.scode === 200){
                        const newData = {...this.state.data}
                        newData[session] = [...res.data[session]]
                        // console.log('mount',res.data[session])
                        this.setState({data:{...newData}})
                        if(this.props.params.sessionid){
                            if(session === 'wishListItems'){
                                this.setState({currentWishItemsListId:this.props.params.sessionid})
                            }
                            if(session === 'wishListCreate'){
                                this.setState({currentWishListId:this.props.params.sessionid})
                            }
                            if(session === 'addressForm'){
                                this.setState({
                                    currentAddressId:this.props.params.sessionid,
                                    currentCountry:res.data[session][0].country,
                                    currentProvince:res.data[session][0].province
                                })
                            }
                        }
                    }else{
                        alert(`${res.data.smessage}`)
                    }
                },
                rej=>console.log(rej)
            )
        }
        // this.wishListItemsSub = PubSub.subscribe('wishListItems',(_,data)=>{
        //     const newData = {...this.state.data}
        //     newData['wishListItems'] = [...data]
        //     console.log(`wishlistItems`,data)
        //     this.setState({data:{...newData}})
        // })
        // this.wishListsSub = PubSub.subscribe('wishLists',(_,data)=>{
        //     const newData = {...this.state.data}
        //     newData['wishLists'] = [...data]
        //     this.setState({data:{...newData}})
        // })
        this.wishListItemsUpdate = PubSub.subscribe('wishListItemsUpdate',(_,data)=>{
            const newData = {...this.state.data}
            newData['wishLists'] = [...data.wishLists]
            newData['wishListItems'] = [...data.wishListItems]
            this.setState({data:{...newData}})
        })
        this.addressesSub = PubSub.subscribe('addresses',(_,data)=>{
            const newData = {...this.state.data}
            newData['addresses'] = [...data]
            this.setState({data:{...newData}})
        })
        this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{
            this.setState({percentage:data})
        })
        PubSub.publish("routes",[{pathname:'/acer/account',name:'Your Account',isCurrent:true}])
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.windowResizeHandler)
        PubSub.unsubscribe(this.addressesSub)
        // PubSub.unsubscribe(this.wishListItemsSub)
        // PubSub.unsubscribe(this.wishListsSub)
        PubSub.unsubscribe(this.wishListItemsUpdate)
        PubSub.unsubscribe(this.percentageSub)
    }
  render() {
    console.log('render',this.props.location.state)
    return (
      <div className='accountpage'>
        <PageRoute/>
        <h1>{this.state.title[this.state.session] === 'Addresses' ? `${this.state.title[this.state.session]}(${this.state.data.addresses?.length?this.state.data.addresses?.length:0}/10)`:`${this.state.title[this.state.session]}`}</h1>
        <div className='accountpage-content' >
            <div className='accountpage-navi'>
                <table>
                    <tbody>
                        <tr>
                            <td><button className={this.state.session==='orders'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/orders',{state:{from:this.props.location.pathname}})}}>Orders</button></td>
                            <td><button className={this.state.session==='messages'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/messages',{state:{from:this.props.location.pathname}})}}>{`Messages(0)`}</button></td>
                            <td><button className={this.state.session==='addresses'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/addresses',{state:{from:this.props.location.pathname}})}}>Addresses</button></td>
                            <td><button className={this.state.session==='wishLists'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/wishLists',{state:{from:this.props.location.pathname}})}}>Wish Lists</button></td>
                            <td><button className={this.state.session==='recentlyViewed'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/recentlyViewed',{state:{from:this.props.location.pathname}})}}>Recently Viewed</button></td>
                            <td><button className={this.state.session==='accountSettings'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/accountSettings',{state:{from:this.props.location.pathname}})}}>Account Settings</button></td>
                            <td><button className={this.state.session==='subscription'?'':"active"} onClick={()=>{this.props.navigate('/acer/account/subscription',{state:{from:this.props.location.pathname}})}}>Subscription</button></td>
                            <td><button className={this.state.session==='export'?'':"active"} onClick={()=>this.getExportedData()}>Export Items</button></td>
                            <td><button className="active" onClick={()=>this.logout()}>Sign out</button></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='accountpage-orderlist' style={{display:this.state.session === "orders"?"flex":"none"}}>
                <table>
                    <tbody>
                        <tr>
                            <td>Date</td>
                            <td>Order Number</td>
                            <td>Invoice Number</td>
                            <td>Amount</td>
                            <td>Status</td>
                        </tr>
                        {
                            this.state.data.orders ?
                            this.state.data.orders?.map(order=>(
                                <tr key={`${order.order_id}-tr`}>
                                    <td key={`${order.order_id}-td-1`}>{order.created_time.split('T')[0]}</td>
                                    <td key={`${order.order_id}-td-2`}><Link key={`${order.order_id}-link-1`} to={{pathname:`/acer/order/${order.order_id}`}} state={{from:"/acer/account/orders"}}>{order.zoho_order_id}</Link></td>
                                    <td key={`${order.order_id}-td-3`}><Link key={`${order.order_id}-link-2`} to={{pathname:`/acer/invoice/${order.invoice_id}`}} state={{from:"/acer/account/orders"}}>{order.zoho_invoice_id}</Link></td>
                                    <td key={`${order.order_id}-td-4`}>${order.total}</td>
                                    <td key={`${order.order_id}-td-5`} style={{color:order.status==="Canceled"?"red":"black"}}>{order.status}</td>
                                </tr>
                            )):""
                        }
                    </tbody>
                </table>
            </div>
            <div className='accountpage-messagelist'style={{display:this.state.session === "messages"?"flex":"none"}}>
                <div className='accountpage-empty'><MdOutlineCreditCard style={{fontSize:'30px',color:"rgb(24,171,157)",marginRight:'10px'}}/> Once you place an order you'll have full access to send messages from this page.</div>
            </div>
            <div className='accountpage-addresseslist' style={{display:this.state.session === "addresses"?"flex":"none"}}>
                {this.state?.data?.addresses?.map(address=>(<Address key={address.address_id} address={address} isChecked={false}/>))}
                <button className='accountpage-add-addres' style={{display:this.state.data.addresses?.length >= 10? "none":"flex"}} onClick={()=>{this.props.navigate('/acer/account/addressForm',{state:{from:'/acer/account/addresses'}})}}><IoMdAdd style={{fontSize:'50px',color:'rgb(129,194,68)'}}/><br/>New Address</button>
            </div>
            <div className='accountpage-addressesform' style={{display:this.state.session === "addressForm"?"flex":"none"}}>
                <form action="">
                    <div>
                        <p>
                            <label className='mandatory'>First Name</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.first_name?this.state.data?.addressForm?.[0]?.first_name:""} ref={this.addressFirstNameRef} className={this.state.errors.addressFirstName ? 'error':""}/>
                            <label className={this.state.errors.addressFirstName ? 'error':""}>{this.state.errors.addressFirstName ? this.state.errors.addressFirstName:""}</label>
                        </p>
                        <p>
                            <label className='mandatory'>Last Name</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.last_name?this.state.data?.addressForm?.[0]?.last_name:""} ref={this.addressLastNameRef} className={this.state.errors.addressLastName ? 'error':""}/>
                            <label className={this.state.errors.addressLastName ? 'error':""}>{this.state.errors.addressLastName ? this.state.errors.addressLastName:""}</label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label>Company Name</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.company?this.state.data?.addressForm?.[0]?.company:""} ref={this.addressCompanyRef} className={this.state.errors.addressCompany ? 'error':""}/>
                            <label className={this.state.errors.addressCompany ? 'error':""}>{this.state.errors.addressCompany ? this.state.errors.addressCompany:""}</label>
                        </p>
                        <p>
                            <label className='mandatory'>Phone Number</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.phone_number?this.state.data?.addressForm?.[0]?.phone_number:""} ref={this.addressPhoneNumberRef} className={this.state.errors.addressPhoneNumber ? 'error':""}/>
                            <label className={this.state.errors.addressPhoneNumber ? 'error':""}>{this.state.errors.addressPhoneNumber ? this.state.errors.addressPhoneNumber:""}</label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label className='mandatory'>Address Line 1</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.address_line_1?this.state.data?.addressForm?.[0]?.address_line_1:""} ref={this.addressLine1Ref} className={this.state.errors.addressLine1 ? 'error':""}/>
                            <label className={this.state.errors.addressLine1 ? 'error':""}>{this.state.errors.addressLine1 ? this.state.errors.addressLine1:""}</label>
                        </p>
                        <p>
                            <label>Address Line 2</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.address_line_2?this.state.data?.addressForm?.[0]?.address_line_2:""} ref={this.addressLine2Ref} className={this.state.errors.addressLine2 ? 'error':""}/>
                            <label className={this.state.errors.addressLine2 ? 'error':""}>{this.state.errors.addressLine2 ? this.state.errors.addressLine2:""}</label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label className='mandatory'>Suburb/City</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.city?this.state.data?.addressForm?.[0]?.city:""} ref={this.addressCityRef} className={this.state.errors.addressCity ? 'error':""}/>
                            <label className={this.state.errors.addressCity ? 'error':""}>{this.state.errors.addressCity ? this.state.errors.addressCity:""}</label>
                        </p>
                        <p>
                            <label className='mandatory'>Country</label>
                            <select value={this.state.currentCountry} ref={this.addressCountryRef} onChange={()=>this.setState({currentCountry:this.addressCountryRef.current.value})}>
                                <option>Canada</option>
                            </select>
                            <label></label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label className='mandatory'>State/Province</label>
                                <select value={this.state.currentProvince} 
                                ref={this.addressProvinceRef}
                                onChange={()=>this.setState({currentProvince:this.addressProvinceRef.current.value})}
                                className={this.state.errors.addressProvince ? 'error':""}
                                >
                                    <option>Choose a State</option>
                                    <option>Ontario</option>
                                    <option>Quebec</option>
                                    <option>Nova Scotia</option>
                                    <option>New Brunswick</option>
                                    <option>Manitoba</option>
                                    <option>British Columbia</option>
                                    <option>Prince Edward Island</option>
                                    <option>Saskatchewan</option>
                                    <option>Alberta</option>
                                    <option>Newfoundland and Labrador</option>
                                    <option>Northwest Territories</option>
                                    <option>Yukon</option>
                                    <option>Nunavut</option>
                                </select>
                                <label className={this.state.errors.addressProvince ? 'error':""}>{this.state.errors.addressProvince ? this.state.errors.addressProvince:""}</label>
                        </p>
                        <p>
                            <label className='mandatory'>Zip/Postcode</label>
                            <input type='text' defaultValue={this.props.params.sessionid && this.state.data?.addressForm?.[0]?.zip?this.state.data?.addressForm?.[0]?.zip:""} ref={this.addressZipRef} className={this.state.errors.addressZip ? 'error':""}/>
                            <label className={this.state.errors.addressZip ? 'error':""}>{this.state.errors.addressZip ? this.state.errors.addressZip:""}</label>
                        </p>
                    </div>
                    <div className='accountpage-addressesform-button'>
                        <button onClick={this.saveAddress}>{this.props.params.sessionid?'UPDATE ADDRESS':'SAVE ADDRESS'}</button>
                        <button onClick={(e)=>{this.props.navigate(-1);e.preventDefault()}}>CANCEL</button>
                    </div>
                </form>
            </div>
            <div className='accountpage-wishlists'style={{display:this.state.session === "wishLists"?"flex":"none"}}>
                <div style={{display:this.state.data.wishLists?.length > 0 ? 'none':"flex"}}className='accountpage-empty'><MdOutlineCreditCard style={{fontSize:'30px',color:"rgb(24,171,157)",marginRight:'10px'}}/> You have no Wish Lists, add one now.</div>
                <table>
                    <tbody>
                        <tr>
                            <td>Wish List Name ({this.state.data.wishLists?.length}/5)</td>
                            <td>Items</td>
                            {/* <td>Shared</td> */}
                            <td>Action</td>
                        </tr>
                        {
                            this.state.data.wishLists?.map(wishList=>(
                                <tr key={`${wishList.list_id}-tr`}>
                                    <td key={`${wishList.list_id}-td-1`}>
                                        <button key={`${wishList.list_id}-button-1`} className='accountpage-wishlists-list' onClick={()=>{this.props.navigate(`/acer/account/wishListItems/${wishList.list_id}`,{state:{from:this.props.location.pathname}})}}>{wishList.list_name}</button>
                                    </td>
                                    <td key={`${wishList.list_id}-td-2`}>{wishList.item_number}/50</td>
                                    {/* <td key={`${wishList.list_id}-td-3`}>{wishList.is_public?"Yes":"No"}</td> */}
                                    <td key={`${wishList.list_id}-td-4`}>
                                        {/* <button key={`${wishList.list_id}-button-2`} style={{display:wishList.is_public?"":"none"}}>SHARE</button> */}
                                        <button key={`${wishList.list_id}-button-3`} onClick={()=>{this.props.navigate(`/acer/account/wishListCreate/${wishList.list_id}`,{state:{from:this.props.location.pathname}})}}>EDIT</button>
                                        <button key={`${wishList.list_id}-button-4`}>DELETE</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className='accountpage-wishlists-button'>
                    <button style={{display:this.state.data.wishLists?.length>=5?"none":""}}onClick={()=>{this.props.navigate('/acer/account/wishListCreate',{state:{from:this.props.location.pathname}})}}>NEW WISH LIST</button>
                    {/* <button onClick={()=>{}}>DELETE ALL</button> */}
                </div>
            </div>
            <div className='accountpage-wishcreate' style={{display:this.state.session === "wishListCreate"?"flex":"none"}}>
                <form action="">
                    <p>
                        <label>Wish List Name:</label>
                        <input type='text' defaultValue={this.state.data.wishListCreate?this.state.data.wishListCreate?.[0].list_name:""}className={this.state.errors.wishListName?'error':""} ref={this.wishListNameRef}/>
                        <label className={this.state.errors.wishListName?'error':""}>{this.state.errors.wishListName?this.state.errors.wishListName:""}</label>
                    </p>
                    {/* <p>
                        <label><input type='checkbox' defaultChecked={this.state.data.wishListCreate?.[0]?.is_public} ref={this.wishListShareableRef}/>Share Wish List?</label>
                    </p> */}
                    <div className='accountpage-wishlists-button'>
                        <button onClick={this.saveWishList}>CREATE WISH LIST</button>
                        <button onClick={(e)=>{this.props.navigate(-1);e.preventDefault()}}>CANCEL</button>
                    </div>
                </form>
            </div>
            <div className='accountpage-wishitems' style={{display:this.state.session === "wishListItems"?"flex":"none"}}>
                <div className='accountpage-empty' style={{display:this.state.data.wishListItems?.length >0 ? "none":"flex"}}><MdOutlineCreditCard style={{fontSize:'30px',color:"rgb(24,171,157)",marginRight:'10px'}}/> Your Wish List is empty. When you add items to your Wish List they will appear here.</div>
                <div className='accountpage-items-list'>
                    {
                        this.state.data.wishListItems?.map(item =>(<ProductBreif key={`${item.list_id}-${item.item_id}`} listId={item.list_id} width={this.state.isMobile?`calc((100% - 20px) / 2)`:`calc((100% - 60px) / 4)`} product={item}/>))
                    }
                </div>
            </div>
            <div className='accountpage-recentlist' style={{display:this.state.session === "recentlyViewed"?"flex":"none"}}>
                <div className='accountpage-empty' style={{display:this.state.data.recentlyViewed?.length > 0?"felx":"none"}}><MdOutlineCreditCard style={{fontSize:'30px',color:"rgb(24,171,157)",marginRight:'10px'}}/> Your Recent Viewed List is empty. When you check items they will appear here.</div>
                <div className='accountpage-items-list'>
                    {
                        this.state.data.recentlyViewed?.map(product=>(
                            <ProductBreif key={`recentlyViewed-${product.item_id}`} width={this.state.isMobile?`calc((100% - 20px) / 2)`:`calc((100% - 60px) / 4)`} product={product}/>
                        ))
                    }
                </div>
            </div>
            <div className='accountpage-accountsetting' style={{display:this.state.session === "accountSettings"?"flex":"none"}}>
                <div className='accountpage-empty'><MdOutlineCreditCard style={{fontSize:'30px',color:"rgb(24,171,157)",marginRight:'10px'}}/> Some information currently cannot be changed for B2B account, if you need to change them, please contact support team.</div>
                <form action="">
                    <div>
                        <p>
                            <label className='mandatory'>First Name</label>
                            <input disabled type='text' defaultValue={this.cookie.get("firstName")?this.cookie.get("firstName"):""} ref={this.firstNameRef} className={this.state.errors.firstName?'error':''}/>
                            <label className={this.state.errors.firstName?'error':''}>{this.state.errors.firstName?this.state.errors.firstName:''}</label>
                        </p>
                        <p>
                            <label className='mandatory'>Last Name</label>
                            <input disabled type='text' defaultValue={this.cookie.get("lastName")?this.cookie.get("lastName"):""} ref={this.lastNameRef} className={this.state.errors.lastName?'error':''}/>
                            <label className={this.state.errors.lastName?'error':''}>{this.state.errors.lastName?this.state.errors.lastName:''}</label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label>Company</label>
                            <input disabled type='text' defaultValue={this.cookie.get("company")?this.cookie.get("company"):""} ref={this.companyRef} className={this.state.errors.company?'error':''}/>
                            <label className={this.state.errors.compny?'error':''}>{this.state.errors.company?this.state.errors.company:''}</label>
                        </p>
                        <p>
                            <label>Phone Number</label>
                            <input disabled type='text' defaultValue={this.cookie.get("phoneNumber")?this.cookie.get("phoneNumber"):""} ref={this.phoneNumberRef} className={this.state.errors.phoneNumber?'error':''}/>
                            <label className={this.state.errors.phoneNumber?'error':''}>{this.state.errors.phoneNumber?this.state.errors.phoneNumber:''}</label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label className='mandatory'>Email Address</label>
                            <input disabled type='text' defaultValue={this.cookie.get("email")?this.cookie.get("email"):""} ref={this.emailRef} className={this.state.errors.email?'error':''}/>
                            <label className={this.state.errors.email?'error':''}>{this.state.errors.email?this.state.errors.email:''}</label>
                        </p>
                        <p>
                            <label>Current Password</label>
                            <input type='password' ref={this.currentPasswordRef} className={this.state.errors.currentPassword?'error':''}/>
                            <label className={this.state.errors.currentPassword?'error':''}>{this.state.errors.currentPassword?this.state.errors.currentPassword:''}</label>
                        </p>
                    </div>
                    <div>
                        <p>
                            <label>New Password</label>
                            <input type='password' ref={this.newPasswordRef} className={this.state.errors.newPassword?'error':''}/>
                            <label className={this.state.errors.newPassword?'error':''}>{this.state.errors.newPassword?this.state.errors.newPassword:''}</label>
                        </p>
                        <p>
                            <label>Confirm Password</label>
                            <input type='password' ref={this.confirmPasswordRef} className={this.state.errors.confirmPassword?'error':''}/>
                            <label className={this.state.errors.confirmPassword?'error':''}>{this.state.errors.confirmPassword?this.state.errors.confirmPassword:''}</label>
                        </p>
                    </div>
                    <div className='accountpage-accountsetting-button'>
                        <button onClick={(e)=>this.updateAccountInfo(e)}>UPDATE DETAILS</button>
                    </div>
                </form>
            </div>
            <div className='accountpage-export' style={{display:this.state.session === "export"?"flex":"none"}}>
                <div>
                    <h2>{this.state.exportItems.length > 0 ? "Items are ready to export":"Preparing Exported Items"}</h2>
                    <td><CSVLink data={this.state.exportItems} filename="ALCMicro_Inventory.csv" className='csv-export' disabled={this.state.exportItems.length > 0 ? false : true}>Export</CSVLink></td>
                </div>
            </div>
            <div className='accountpage-subscription' style={{display:this.state.session === "subscription"?"flex":"none"}}>
                <div>
                    <p>Sales Subscription</p>
                    <button>{this.state.data.subscription ? "Unsubscribe":"Subscribe"}</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AccountPage)