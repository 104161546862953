import React, { Component } from 'react'
import './index.css'
import {FaUser,FaUserEdit} from 'react-icons/fa'
import PubSub from 'pubsub-js'
import { Link } from 'react-router-dom'
import { FaShoppingCart } from "react-icons/fa";
import Cookies from 'universal-cookie'

export default class User extends Component {
    cookies = new Cookies()
    state ={
        cartItemsNumber:0,
        cartItems:[],
        isLogin:this.cookies.get('login')
    }
    componentDidUpdate(){
        if(this.props.cartItemsNumber!== this.state.cartItemsNumber){
            this.setState({cartItems:[...this.props.cartItems],cartItemsNumber:this.props.cartItemsNumber})
        }
    }
    componentDidMount(){
        console.log(this.props)
        if(this.props.cartItems?.length > 0 || this.props.cartItemsNumber > 0){
            this.setState({cartItems:[...this.props.cartItems],cartItemsNumber:this.props.cartItemsNumber})
        }
        this.token = PubSub.subscribe('isLogin',(_,data)=>{
            this.setState({isLogin:data})
        })
        // this.setState({isLogin:document.cookie.includes('login=true')})
    }
    componentWillUnmount(){
        PubSub.unsubscribe(this.token)
    }
    render() {
        return (
            <div className="header-user">
                <Link to='/acer/signin'><button><FaUser color='black' fontSize="25px" style={{margin:'0px'}}/> <p>{this.state.isLogin?'Account':'Sign in'}</p></button></Link>
                <Link to='/acer/cart' state={{cartItems:this.props.cartItems}}><button><FaShoppingCart color='black' fontSize="30px" style={{margin:'0px'}}/> <p>Cart{this.state.cartItemsNumber?`(${this.state.cartItemsNumber})`:""}</p></button></Link>
            </div>
        )
    }
}
