import { Component } from 'react'
import { useNavigate } from 'react-router';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps){
        if(this.props.location !== prevProps.location){
            window.scrollTo(0,0)
        }
    }
    render() {
        return this.props.children
    }
}

const withRouter = (Component) =>{
    const Wrapper = (props) =>{
        const history = useNavigate();
        return <Component history={history} {...props}/>
    } 
    return Wrapper;
}

export default withRouter(ScrollToTop)



