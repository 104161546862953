import React, { Component,createRef} from 'react'
import './index.css'
import ImageGallery from './ImageGallery'
import { FaRegHeart,FaHeart,FaStar, FaSleigh} from "react-icons/fa";
import PageRoute from '../PageRoute';
import axios from 'axios'
import serverInfo from "../../config.js"
import loadingGif from '../../media/image/Spinner.gif'
import withRouter from '../../withRouter';
import PubSub from 'pubsub-js';
import Cookies from 'universal-cookie';
// import { Link } from 'react-router-dom';
import userInfo from '../../userInfo';
class DetailPage extends Component {
    // length = 1
    // likeRate = 113/500
    // isreact = true
    
    constructor(props) {
        super(props);
        this.infoNavRef = createRef()
        this.descriptionRef = createRef()
        this.detailRef = createRef()
        this.warrantyRef = createRef()
        this.reviewRef = createRef()
        this.quantityRef = createRef()
        this.wishListDropdown = createRef()
        this.heartButton = createRef()
        this.cookie = new Cookies()
        this.state = {
            offset: window.scrollY, // 初始化时设置默认值
            isDes:false,
            isDetail:false,
            isWarranty:false,
            isReview:false,
            showWishList:false,
            isLogin: this.cookie.get('login') ? true : false,
            wishListDropdownRight: 0,
            infoNavHeight:72,
            desY:0,
            detailY:0,
            warrantyY:0,
            reviewY:0,
            itemDetail:null,
            itemImages:null,
            itemReviews:null,
            product:null,
            imageUrl:null,
            details:null,
            isInWishLists:false,
            orderedQuantity:1,
            inProcessing:false,
            wishLists:[],
            wishListsItems:[],
            wishListContians:{},
            urlCondition:this.props.params.condition, // for component update, in case users typed value istead of 'ScratchDent', 'BrandNew', 'Recertified'
            condition:this.props.params.condition ? this.props.params.condition : null,
            percentage:this.cookie.get('percentage') ? this.cookie.get('percentage') : 0,
            stock:0,
            discountPrice: null
        };
        this.handleScroll = function() {
            this.setState({ 
                offset: window.pageYOffset,
                isDes: this.state.offset + this.infoNavRef.current.clientHeight<this.detailRef.current.offsetTop?true:false,
                isDetail: this.state.offset + this.infoNavRef.current.clientHeight>=this.detailRef.current.offsetTop && this.state.offset + this.infoNavRef.current.clientHeight<this.warrantyRef.current.offsetTop?true:false,
                isWarranty: this.state.offset + this.infoNavRef.current.clientHeight>=this.warrantyRef.current.offsetTop && this.state.offset + this.infoNavRef.current.clientHeight<this.reviewRef.current.offsetTop?true:false,
                isReview: this.state.offset + this.infoNavRef.current.clientHeight>=this.reviewRef.current.offsetTop?true:false
            }); // 更新状态
            // console.log('window listner:',this.state.offset,this.descriptionRef.current.offsetTop, this.infoNavRef.current.clientHeight)
        }
        this.handleScrollHandler = this.handleScroll.bind(this)
        this.handleClickOutsideHandler = this.handleClickOutside.bind(this);
    }
    addToCart(){
        if(this.cookie.get('login') && serverInfo.customerid !== 'visitors'){
            let isExceedStock = false
            if(this.state.condition === "ScratchDent"){
                isExceedStock = this.state.itemDetail?.scratch_dent_stock >= this.state.orderedQuantity ? false : true
            }else{
                isExceedStock = this.state.itemDetail?.stock >= this.state.orderedQuantity ? false : true
            }
            if(!isExceedStock){
                this.setState({inProcessing:true})
                const itemData = {
                    itemData:{
                        customer_id: serverInfo.customerid,
                        item_id:this.state.itemDetail?.zoho_id,
                        sku:this.state.itemDetail?.sku,
                        quantity:this.state.orderedQuantity,
                        item_condition:this.state.condition
                    },
                    is_replaced:true
                }
                axios.post(`${serverInfo.baseURL}/user/cart/add`,itemData,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                        if(res.data.scode === 200){
                            // console.log('cartItems',res.data.cartItems)
                            PubSub.publish('cartItems',[...res.data.cartItems])
                            const cartItems = [...res.data.cartItems]
                            const cartItemsNumber = res.data.cartItems?.reduce((total,current)=>total+current.quantity,0)
                            const cartItemsSubtotal = res.data.cartItems?.reduce((total,current)=>{
                                if(current.item_condition === 'ScratchDent' && current.personal_scratch_dent_price && current.personal_scratch_dent_pric !== 0){
                                    return total+(current.personal_scratch_dent_price*current.quantity)
                                }else{
                                    if(current.is_sale && current.personal_sale_price && current.personal_sale_price!==0){
                                        return total+(current.personal_sale_price*current.quantity)
                                    }else{
                                        return total+(current.personal_price*current.quantity)
                                    }
                                }
                            },0)
                            setTimeout(() => {
                                this.setState({inProcessing:false})
                                this.props.navigate(`/acer/add-to-cart`,{state:{from:this.props.location.pathname,product:this.state.itemDetail,imageUrl:this.state.imageUrl,quantity:this.state.orderedQuantity,cartItemsNumber,cartItemsSubtotal,cartItems}})
                            }, 1000);
                            // this.props.navigate(`/acer/add-to-cart/${this.state.product?.zoho_id}`,{state:{from:this.props.location.pathname,product:this.state.product,imageUrl:this.state.imageUrl,quantity:1,cartItemsNumber,cartItemsSubtotal,cartItems}})
                        }else{
                            console.log(res.data.scode,res.data.smessage)
                            alert(res.data.smessage)
                        }
                    },
                    rej=>console.log(rej)
                )
            }else{
                alert(`Ordered item quantity exceed the current stock!`)
            }
        }else{
            this.props.navigate(`/acer/signin`,{state:{from:this.props.location.pathname}})
        }
        
    }
    showWishListDropdown = (e) =>{
        if(this.cookie.get('login')){
            if(this.state.wishLists.length<=0){
                alert('Please create your personal wish list first.')
            }else{
                if(this.state.showWishList){
                    this.setState({
                        showWishList:false,
                        wishListDropdownRight: window.innerWidth > 1200 ? (window.innerWidth - 1140)/2 : ((window.innerWidth > 800)? 30 : 10)
                    })
                }else{
                    this.setState({
                        showWishList:true,
                        wishListDropdownRight: window.innerWidth > 1200 ? (window.innerWidth - 1140)/2 : ((window.innerWidth > 800)? 30 : 10)
                    })
                }
            }
        }else{
            alert('Please log in first.')
        }
    }
    // click outside to hide the 
    handleClickOutside(event) {
        if (this.wishListDropdown && !this.wishListDropdown.current.contains(event.target)) {
            this.setState({
                showWishList:false
            })
        }
    }
    changeInputValue = (e)=>{
        const newValue = e.target.value;
        // 使用正则表达式来确保只有数字被输入
        const numericRegex = /^[0-9]*$/;
        const newValueIsNumeric = numericRegex.test(newValue);
        const stock = this.state.condition === 'ScratchDent' ? this.state.itemDetail.scratch_dent_stock : this.state.itemDetail.stock
        if (newValueIsNumeric) {
            const newNumber = Number.parseInt(newValue)
            if(Number.isNaN(newNumber)){
                this.setState({orderedQuantity:newValue})
            }else if(newNumber>0 && newNumber<= stock){
                this.setState({orderedQuantity:newNumber})
            }else if(newNumber > stock){
                this.setState({orderedQuantity:stock})
            }
        }
        // console.log(e.target.value);
    }
    saveOrRemoveFromWishList = (listId)=>{
        const wishListContians = {...this.state.wishListContians}
        let list_name = null
        for(const list of this.state.wishLists){
            if(list.list_id === listId){
                list_name = list.list_name
                break
            }
        }
        if(wishListContians[listId]){
            axios.delete(`${serverInfo.baseURL}/user/wishList/${listId}/item/${this.state.itemDetail?.zoho_id}/${this.state.condition}/remove`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.scode === 200 || res.data.scode === 201){
                        let wishListsItems = [...userInfo.wishListsItems]
                        let index = 0
                        for(index = 0; index<wishListsItems.length; index++){
                            if(wishListsItems[index].list_id === listId && wishListsItems[index].item_id === this.state.itemDetail.zoho_id && wishListsItems[index].item_condition === this.state.condition){
                                wishListsItems.splice(index,1)
                                break
                            }
                        } 
                        // set up for list single like mark
                        wishListContians[listId] = false
                        // to set up for total like mark (see if item contains in any wishlist)
                        let isInWishLists = false
                        for(const listId in wishListContians){
                            isInWishLists = wishListContians[listId]? true:isInWishLists
                        }
                        this.setState({wishListContians:{...wishListContians},wishListsItems:[...wishListsItems],isInWishLists})
                        userInfo.updateInfo('wishListsItems',wishListsItems)
                        alert(`${this.state.itemDetail.item_name} has removed from your wish list ${list_name}`)
                    }else{
                        alert(`Remove Item from Wish List FAILED, please try again.`)
                    }
                },
                rej=>console.log(rej)
            )
        }else{
            axios.post(`${serverInfo.baseURL}/user/wishList/item/add`,{listId:listId,itemId:this.state.itemDetail?.zoho_id,sku:this.state.itemDetail?.sku,item_condition:this.state.condition},{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.scode === 200){
                        wishListContians[listId] = true
                        this.setState({
                            wishListContians:{...wishListContians},
                            wishListsItems:[...res.data.wishListsItems],
                            isInWishLists:true
                        })
                        userInfo.updateInfo('wishListsItems',res.data.wishListsItems)
                        userInfo.updateInfo('wishLists',res.data.wishLists)
                        alert(`${this.state.itemDetail.item_name} has been added into your wish list ${list_name}`)
                    }else{
                        alert(res.data.smessage)
                    }
                },
                rej=>console.log(rej)
            )
        }

    }
    infoNavClick = (position)=>{
        //window.scrollTo need time to change, which will make state updated, use settimeout to make sure the status is changed after scrolled
        if(position === 'description'){
            window.scrollTo({top:this.state.desY - this.state.infoNavHeight,behavior:"auto"})
            setTimeout(() => {
                this.setState({offset:this.state.desY - this.state.infoNavHeight,isDes:true,isDetail:false,isWarranty:false,isReview:false})
            }, 20);
            
        }else if(position === 'details'){
            window.scrollTo({top:this.state.detailY - this.state.infoNavHeight,behavior:"auto"}) 
            setTimeout(() => {
                this.setState({offset:this.state.detailY - this.state.infoNavHeight,isDes:false,isDetail:true,isWarranty:false,isReview:false})   
            }, 20);

        }else if(position === 'warranty'){
            window.scrollTo({top:this.state.warrantyY - this.state.infoNavHeight,behavior:"auto"})
            setTimeout(() => {
                this.setState({offset:this.state.warrantyY - this.state.infoNavHeight,isDes:false,isDetail:false,isWarranty:true,isReview:false})
            }, 20);
        }else if(position === 'reviews'){
            window.scrollTo({top:this.state.reviewY - this.state.infoNavHeight,behavior:"auto"}) 
            setTimeout(() => {
                this.setState({offset:this.state.reviewY - this.state.infoNavHeight,isDes:false,isDetail:false,isWarranty:false,isReview:true})
            }, 20);
        }
    }
    
    componentDidUpdate(){
        if(this.state.infoNavHeight!==this.infoNavRef.current.clientHeight || 
            this.state.desY!==this.descriptionRef.current.offsetTop || 
            this.state.detailY !== this.detailRef.current.offsetTop ||
            this.state.warrantyY!==this.warrantyRef.current.offsetTop||
            this.state.reviewY!==this.reviewRef.current.offsetTop){
                this.setState({
                    infoNavHeight:this.infoNavRef.current.clientHeight,
                    desY:this.descriptionRef.current.offsetTop ,
                    detailY:this.detailRef.current.offsetTop ,
                    warrantyY:this.warrantyRef.current.offsetTop ,
                    reviewY:this.reviewRef.current.offsetTop})
        }
        if(this.state.urlCondition !== this.props.params.condition){
            const wishListContians = {}
            let isInWishLists = false
            const condition = this.props.params.condition === "ScratchDent" ? this.props.params.condition : (this.state.itemDetail.is_brand_new ? "BrandNew" : (this.state.itemDetail.is_recertified ? "Recertified" : "Warranty"))
            for(const item of userInfo.wishListsItems){
                if(this.state.itemDetail.zoho_id === item.item_id && condition === item.item_condition){
                    isInWishLists = true
                    wishListContians[item.list_id] = true
                }
            }
            this.setState({
                urlCondition:this.props.params.condition,
                stock:this.props.params.condition === "ScratchDent" ? this.state.itemDetail?.scratch_dent_stock : this.state.itemDetail?.stock,
                // is_brand_new and is_recertified wont be true at the same time. null is for warranty which has no condition
                condition,

            })
        }
    }
    componentDidMount() {
        const condition = this.props.params.condition === "ScratchDent" ? this.props.params.condition : (this.props.params.condition === "BrandNew" ? "BrandNew" : (this.props.params.condition === "Recertified" ? "Recertified" : (this.props.params.condition === "Warranty" ? "Warranty" : "Depends")))
        this.infoNavOffest = this.infoNavRef.current.offsetTop
        this.infoNavHeight = `${this.infoNavRef.current.clientHeight}px`
        this.setState({
            infoNavHeight:this.infoNavRef.current.clientHeight,
            desY:this.descriptionRef.current.offsetTop ,
            detailY:this.detailRef.current.offsetTop ,
            warrantyY:this.warrantyRef.current.offsetTop ,
            reviewY:this.reviewRef.current.offsetTop,
            wishLists: [...userInfo.wishLists],
            wishListsItems:[...userInfo.wishListsItems],
            urlCondition: this.props.params.condition,
            condition
        })
        window.addEventListener('scroll', this.handleScrollHandler); // 添加事件监听器
        window.addEventListener("mousedown", this.handleClickOutsideHandler);
        this.wishListsSub = PubSub.subscribe('wishLists',(_,data)=>this.setState({wishLists:[...data]}))
        this.wishListsItemsSub = PubSub.subscribe('wishListsItems',(_,data)=>{
            let isInWishLists = false, wishListContians = {}
            for(const item of data){
                if(this.state.itemDetail.item_id === item.item_id && item.item_condition === this.state.condition){
                    isInWishLists = true
                    wishListContians[item.list_id] = true
                }
            }
            this.setState({wishListsItems:[...data],isInWishLists,wishListContians})
        })
        this.isLoginSub = PubSub.subscribe('isLogin',(_,data)=>{
            this.setState({isLogin:data})
        })
        this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{
            this.setState({percentage:data})
        })
        axios.get(`${serverInfo.baseURL}/product/${this.props.params.itemid}/${condition}`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
            res=>{
                if(res.data.scode === 200){
                    const itemDetail = {...res.data.itemInfo.itemDetail[0]}
                    const item_id = res.data.itemInfo.itemDetail[0].zoho_id
                    const detailArray = itemDetail.details.split('\n')
                    const details = []
                    const routes = []
                    let isInWishLists = false, wishListContians = {}
                    const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
                    const googleImageId = imageRegex.exec(res.data.itemInfo.itemDetail[0].main_image)?.[1]
                    let mainImageUrl = null
                    if(googleImageId){
                        mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
                    }
                    for(const item of userInfo.wishListsItems){
                        if(item_id === item.item_id && condition === item.item_condition){
                            isInWishLists = true
                            wishListContians[item.list_id] = true
                        }
                    }
                    // get item detail spec name  and spec value groups for ITEM DETAILS
                    for(const spec of detailArray){
                        const specArray = spec.split(':')
                        const specName = specArray[0]
                        // incase spec value contains ':'
                        specArray.shift()
                        // in case the array is empty
                        if(specArray.length>0){
                            const specContent = specArray.reduce((acc,current)=>`${acc}:${current}`)
                            details.push({specName,specContent})
                        }
                    }
                    this.setState({
                        itemDetail:{...res.data.itemInfo.itemDetail[0]},
                        itemImages:[...res.data.itemInfo.itemImages],
                        itemReviews:[...res.data.itemInfo.itemReviews],
                        details:[...details],
                        product:{...res.data.itemInfo.product[0]},
                        imageUrl:mainImageUrl,
                        stock:this.props.params.condition === "ScratchDent" ? res.data.itemInfo.itemDetail[0].scratch_dent_stock : res.data.itemInfo.itemDetail[0].stock,
                        discountPrice : this.props.params.condition === "ScratchDent" ? (res.data.itemInfo.itemDetail[0].personal_scratch_dent_price !== 0 ?res.data.itemInfo.itemDetail[0].personal_scratch_dent_price : null) : (res.data.itemInfo.itemDetail[0].is_sale && res.data.itemInfo.itemDetail[0].personal_sale_price !== 0 ? res.data.itemInfo.itemDetail[0].personal_sale_price : null),
                        isInWishLists,
                        wishListContians,
                    })
                    PubSub.publish(`itemImages`,{main_image:res.data.itemInfo.itemDetail[0].main_image, images:[...res.data.itemInfo.itemImages]})
                    //set up routes for page routes
                    routes.push({pathname:`/category/${res.data.itemInfo.itemDetail[0].category}`,name:res.data.itemInfo.itemDetail[0].category,isCurrent:false})
                    if(res.data.itemInfo.itemDetail[0].sub_category && res.data.itemInfo.itemDetail[0].sub_category!==""){
                        routes.push({pathname:`/category/${res.data.itemInfo.itemDetail[0].category}/${res.data.itemInfo.itemDetail[0].sub_category}`,name:res.data.itemInfo.itemDetail[0].sub_category,isCurrent:false})
                    }
                    routes.push({pathname:`/acer/product/${res.data.itemInfo.itemDetail[0].zoho_id}`,name:res.data.itemInfo.itemDetail[0].item_name,isCurrent:true})
                    PubSub.publish('routes',routes)
                }else{
                    alert('Item does not exsist, or Item has been removed.')
                    this.props.navigate('/acer',{state:{from:this.props.location.pathname}})
                    console.log(res.data.scode,res.data.smessage)
                }
            },
            rej=>console.log(rej)
        )
        if(this.props.location?.state?.windowYOffset){
            window.scrollTo({top:this.props.location?.state?.windowYOffset,behavior:"auto"})
        }else{
            window.scrollTo({top:0,behavior:"auto"})
        }
        
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollHandler); // 移除事件监听器
        window.removeEventListener("mousedown", this.handleClickOutsideHandler)
        PubSub.unsubscribe(this.wishListsSub)
        PubSub.unsubscribe(this.wishListsItemsSub)
        PubSub.unsubscribe(this.isLoginSub)
        PubSub.unsubscribe(this.percentageSub)
    }
  render() {
    // console.log(userInfo.wishLists,userInfo.wishListsItems)
    return (
      <div className='detailpagefield'>
        <PageRoute/>
            
        <div className='detailpage'> 
            {      
                this.state.itemDetail ? 
                <div className='detailpage-main'>
                    <ImageGallery itemImages={this.state.itemImages} mainImageUrl={this.state?.itemDetail?.main_image}/>
                    <div className='detailpage-main-info'>
                        <div className='detailpage-main-info-header'>
                            <p style={{display:this.state?.itemDetail?.is_sale && this.state.discountPrice?'flex':'none'}}>Sale</p>
                            <p style={{backgroundColor:this.state.stock>0?"rgb(129,194,68)":"RED"}}>{this.state.stock>0?`In Stock`:`Out of Stock`}</p>
                            <div className='detailpage-wishlist'>
                                <div className='header-navigate-category'>
                                    <button className='wishlist-heart'ref={this.heartButton} onClick={this.showWishListDropdown}>{this.state.isInWishLists?<FaHeart color='rgb(129,194,68)'/>:<FaRegHeart/>}</button>
                                </div>
                                <div id={'wishListDropdown'} tabIndex={0} ref = {this.wishListDropdown} onBlur={()=>{this.setState({showWishList:false})}} style={{right:`${this.state.wishListDropdownRight}px`}} className={this.state.showWishList?"detailpage-wishlist-dropdown active":"detailpage-wishlist-dropdown"} >
                                    <ul className=''>
                                        {
                                        this.state.wishLists?.map(wishList=>(
                                            <li key={`${wishList.list_id}-li`}>
                                                <button key={`${wishList.list_id}-button`} onClick={()=>this.saveOrRemoveFromWishList(wishList.list_id)}>
                                                {this.state.wishListContians[wishList.list_id]?<FaHeart color='rgb(129,194,68)' style={{marginRight:'5px'}}/>:<FaRegHeart style={{marginRight:'5px'}}/>}{wishList.list_name}
                                                </button>
                                            </li>
                                        ))}
                                        {/* <li><button>CREATE NEW WISH LIST</button></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h1>{`${this.state?.itemDetail?.item_name}`}</h1>
                        
                        {/* <div className='detailpage-main-info-reviews' style={{display:"none"}}>
                            <p>
                                <FaStar style={{color:`${this.likeRate>0?'rgb(143,192,87)':'rgb(220,221,222)'}`}}/>&nbsp;
                                <FaStar style={{color:`${this.likeRate>=0.2?'rgb(143,192,87)':'rgb(220,221,222)'}`}}/>&nbsp;
                                <FaStar style={{color:`${this.likeRate>=0.4?'rgb(143,192,87)':'rgb(220,221,222)'}`}}/>&nbsp;
                                <FaStar style={{color:`${this.likeRate>=0.6?'rgb(143,192,87)':'rgb(220,221,222)'}`}}/>&nbsp;
                                <FaStar style={{color:`${this.likeRate>=0.8?'rgb(143,192,87)':'rgb(220,221,222)'}`}}/>
                            </p>
                            <p>{`${this.length}`} {`${this.length<=1?'review':'reviews'}`}</p>
                            <p>|</p>
                            <a href="#">Write a Review</a>
                        </div> */}
                        <div className='detailpage-main-info-price' style={{display:this.state.isLogin?"flex":"none"}}>
                            <p>PRICE:</p>
                            <p className={this.state?.itemDetail?.is_sale && this.state.discountPrice?'origin-line-through':'origin-price'}>{`$${this.state?.itemDetail?.personal_price.toFixed(2)}`}</p>
                            <p className='sale-price' style={{display:this.state?.itemDetail?.is_sale && this.state.condition !== 'ScratchDent' && this.state.discountPrice ? "flex":"none"}}>{`$${this.state?.itemDetail?.personal_sale_price?.toFixed(2)}`}</p>
                            <p className='saved-price' style={{display:this.state?.itemDetail?.is_sale && this.state.condition !== 'ScratchDent' && this.state.discountPrice ?"flex":"none"}}>{`You save $${(this.state?.itemDetail?.personal_price-this.state?.itemDetail?.personal_sale_price)?.toFixed(2)}`}</p>
                            <p className='sale-price' style={{display:this.state.condition === 'ScratchDent' && this.state.discountPrice?"flex":"none"}}>{`$${this.state?.itemDetail?.personal_scratch_dent_price?.toFixed(2)}`}</p>
                            <p className='saved-price' style={{display:this.state.condition === 'ScratchDent' && this.state.discountPrice?"flex":"none"}}>{`You save $${(this.state?.itemDetail?.personal_price-this.state?.itemDetail?.personal_scratch_dent_price)?.toFixed(2)}`}</p>
                        </div>
                        <p>{`Stock: ${this.state.stock}`}</p>
                        <p>{`SKU: ${this.state?.itemDetail?.sku}`}</p>
                        <p style={{display:this.state.condition !== "Warranty" ?"flex":"none"}}>{`Condition: ${this.state.condition === "ScratchDent"?"Scratch & Dent":(this.state.condition === "BrandNew"?"Brand New":"Recertified")}`}</p>
                        <div className='detailpage-main-info-order'>
                            <div className='detailpage-main-info-order-quantity' style={{display:this.state.stock>0?"flex":"none"}}>
                                <button onClick={()=>this.setState({orderedQuantity:this.state.orderedQuantity-1>0?this.state.orderedQuantity-1:this.state.orderedQuantity})}>-</button>
                                <input type='number' ref={this.quantityRef} value={this.state.orderedQuantity} min={1} max={this.state.stock ? this.state.stock : 1} onChange={this.changeInputValue}></input>
                                <button onClick={()=>this.setState({orderedQuantity:this.state.orderedQuantity+1<=this.state.stock?this.state.orderedQuantity+1:this.state.orderedQuantity})}>+</button>
                            </div>
                            {/* <button className='detailpage-main-info-order-add'>ADD TO CART</button> */}
                            <button onClick={()=>this.addToCart()} disabled={this.state.stock > 0 && this.state.isLogin ? false:true} className={this.state.stock > 0 && !this.state.inProcessing && this.state.isLogin ? "detailpage-main-info-order-add":"detailpage-main-info-order-add disabled"}>
                                {this.state.isLogin ? (this.state.stock >0 ? "ADD TO CART":"OUT OF STOCK") : "Please Login"}
                            </button>
                        </div>
                    </div>
                </div>
                :
                <div className='loading-image'><img src={loadingGif} alt="loading..."/></div>
            }

            <div className='detailpage-information'>
                <div className={`detailpage-information-nevigate ${this.state.offset>=this.infoNavOffest?'detailpage-information-nevigate-fixed':''}`} ref={this.infoNavRef}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={this.state.isDes?'active':''}>
                                    <button className={this.state.isDes?'active':''} onClick={()=>  this.infoNavClick('description')}>DESCRIPTION</button>
                                </td>
                                <td className={this.state.isDetail?'active':''}>
                                    <button className={this.state.isDetail?'active':''} onClick={()=>  this.infoNavClick('details')}>DETAILS</button>
                                </td>
                                <td className={this.state.isWarranty?'active':''}>
                                    <button className={this.state.isWarranty?'active':''} onClick={()=>  this.infoNavClick('warranty')}>WARRANTY</button>
                                </td>
                                <td className={this.state.isReview?'active':''} style={{display:"none"}}>
                                    <button className={this.state.isReview?'active':''} onClick={()=>  this.infoNavClick('reviews')}>CUSTOMER REVIEWS</button>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='detailpage-information-main' style={{marginTop:`${this.state.offset>=this.infoNavOffest?this.infoNavHeight:'0px'}`}}>
                    <div className='detailpage-information-description' ref={this.descriptionRef} id='detailpage-information-description'>
                        <h1>Description</h1>
                        <p>{this.state?.itemDetail?.description}</p>
                    </div>
                    <div className='detailpage-information-details' ref={this.detailRef} id='detailpage-information-details'>
                        <h1>Details</h1>
                        <table>
                            <tbody>
                                {
                                    this.state.details?this.state.details.map(element=>(
                                        <tr key={`${this.state.itemDetail.sku}-${element.specName}-tr`}>
                                            <td key={`${this.state.itemDetail.sku}-${element.specName}-specName`}>{`${element.specName}`}</td>
                                            <td key={`${this.state.itemDetail.sku}-${element.specName}-specValue`}>{`${element.specContent}`}</td>
                                        </tr>
                                    )):(<tr><td></td><td></td></tr>)
                                }
                                {/* <tr>
                                    <td>Ram:</td>
                                    <td>16 GB</td>
                                </tr>
                                <tr>
                                    <td>Solid State Drive:	</td>
                                    <td>512 GB</td>
                                </tr>
                                <tr>
                                    <td>Processor Type:	</td>
                                    <td>Intel Core i5</td>
                                </tr>
                                <tr>
                                    <td>Processor Model:</td>
                                    <td>i5-11400H </td>
                                </tr>
                                <tr>
                                    <td>Processor Speed:</td>
                                    <td>2.51 - 3.00 GHz</td>
                                </tr>
                                <tr>
                                    <td>Processor Manufacturer:	</td>
                                    <td>Intel</td>
                                </tr>
                                <tr>
                                    <td>Processor Core:	</td>
                                    <td>Hexa-core {`(6 Core)`}</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    <div className='detailpage-information-warranty' ref={this.warrantyRef} id='detailpage-information-warranty'>
                        <h1>Warranty</h1>
                        <p>Please contact your sales representative for more details.</p>
                    </div>
                    <div className='detailpage-information-reviews' ref={this.reviewRef} id='detailpage-information-reviews' style={{display:"none"}}>
                        <h1>Customer Reviews</h1>
                        <button>WRITE A REVIEW</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default withRouter(DetailPage)