import React, { Component } from 'react'
import './index.css'
import ProductBreif from '../../ProductBrief'
import PubSub from 'pubsub-js'

export default class ProductListing extends Component {
    constructor(props){
        super(props)
        this.state={
            listingBoxMargin:0,
            calculatedBoxMargin:0,
            isMobile:window.innerWidth <= 800 ? true : false,
            productWidth:window.innerWidth>1200 ? (1200-180) / 4 : (window.innerWidth<=800 ? (window.innerWidth-90) / 2 : (window.innerWidth - 180) / 4),
            productGap: window.innerWidth <= 800 ? 10 : 20,
            productNumber:8,
            slideNumber:window.innerWidth > 800 ? 4 : 2,
            products:[],
            title:null,
            productIndex: 0,
            currentInterval: null,
        }
        this.windowResize = () => {
            // const currentWidth = window.innerWidth
                this.setState({
                    listingBoxMargin:window.innerWidth<1200 ? 0 : this.state.listingBoxMargin,
                    isMobile:window.innerWidth <= 800 ? true : false,
                    productWidth:window.innerWidth>1200 ? (1200-180) / 4 : (window.innerWidth<=800 ? (window.innerWidth-90) / 2 : (window.innerWidth - 180) / 4),
                    productGap:window.innerWidth <= 800 ? 10 : 20,
                    slideNumber:window.innerWidth > 800 ? 4 : 2,
                })
            // console.log(`product listing:${this.state.productWidth}`)
        }
        this.windowResizeHandler = this.windowResize.bind(this)
    }
    listingSlide(index,direction){
        // console.log('button clicked',index)
        const singleMargin = this.state.productGap + this.state.productWidth
        let currentMargin = (index * -1 * singleMargin)
        let isCurrent = false
        if(!direction){
            if(index < this.state.products.length - this.state.slideNumber){
                this.setState({productIndex:index + 1})
                const finalMargin = currentMargin - singleMargin
                let interval = setInterval(() => {
                    if(!this.state.currentInterval){
                        this.setState({currentInterval:interval})
                        isCurrent = true
                    }else if(this.state.currentInterval && !isCurrent){
                        clearInterval(this.state.currentInterval)
                        this.setState({
                            currentInterval:interval,
                            wrapperBoxMargin:currentMargin
                        })
                        isCurrent = true
                    }
                    currentMargin = currentMargin - (singleMargin/100)
                    this.setState({listingBoxMargin:currentMargin})
                    if(currentMargin <= finalMargin){
                        clearInterval(interval)
                        this.setState({
                            listingBoxMargin:finalMargin,
                            currentInterval:null
                        })
                    }
                }, 1);
            }
        }else{
            if(index > 0){
                this.setState({productIndex:index - 1})
                const finalMargin = currentMargin + singleMargin
                let interval = setInterval(() => {
                    if(!this.state.currentInterval){
                        this.setState({currentInterval:interval})
                        isCurrent = true
                    }else if(this.state.currentInterval && !isCurrent){
                        clearInterval(this.state.currentInterval)
                        this.setState({
                            currentInterval:interval,
                            listingBoxMargin:currentMargin
                        })
                        isCurrent = true
                    }
                    currentMargin = currentMargin + (singleMargin/100)
                    this.setState({listingBoxMargin:currentMargin})
                    if(currentMargin >= finalMargin){
                        clearInterval(interval)
                        this.setState({
                            listingBoxMargin:finalMargin,
                            currentInterval:null
                        })
                    }
                }, 1);
            }
        }
    }
    // listingSlide(currentMargin,direction){
    //     console.log('button clicked',currentMargin)
    //     if(!direction){
    //         if(currentMargin>((-this.state.productGap - this.state.productWidth)*(this.state.productNumber-4))){
    //             const finalMargin = currentMargin - this.state.productGap - this.state.productWidth
    //             const decrease = (this.state.productGap+this.state.productWidth) / 100
    //             this.setState({
    //                 calculatedBoxMargin:finalMargin
    //             })
    //             let interval = setInterval(() => {
    //                 currentMargin = currentMargin - decrease
    //                 this.setState({listingBoxMargin:currentMargin})
    //                 if(currentMargin >= finalMargin){
    //                     this.setState({listingBoxMargin:finalMargin})
    //                     clearInterval(interval)
    //                 }
    //             }, 1);
    //         }
    //     }else{
    //         if(currentMargin<0){
    //             const finalMargin = currentMargin + this.state.productGap + this.state.productWidth
    //             const increase = (this.state.productGap+this.state.productWidth) / 100
    //             this.setState({
    //                 calculatedBoxMargin:finalMargin
    //             })
    //             let interval = setInterval(() => {
    //                 currentMargin = currentMargin + increase
    //                 this.setState({listingBoxMargin:currentMargin})
    //                 if(currentMargin >= finalMargin){
    //                     this.setState({listingBoxMargin:finalMargin})
    //                     clearInterval(interval)
    //                 }
    //             }, 1);
    //         }
    //     }
    // }
    // windowResize(){
    //     const currentWidth = window.innerWidth
    //         this.setState({
    //             listingBoxMargin:window.innerWidth<1200 ? 0 : this.state.listingBoxMargin,
    //             isMobile:window.innerWidth <= 800 ? true : false,
    //             productWidth:window.innerWidth>1200 ? (1200-180) / 4 : (window.innerWidth<=800 ? (window.innerWidth-90) / 2 : (window.innerWidth - 180) / 4),
    //             productGap:window.innerWidth <= 800 ? 10 : 20,
    //         })
    //     console.log(`product listing:${this.state.productWidth}`)
    // }
    componentDidUpdate(){
        // if(!this.state.title && this.props.title){
        //     this.setState({
        //         title:this.props.title,
        //         products:[...this.props.products],
        //         productNumber:this.props.products.length
        //     })
        // }
        if(this.state.productNumber !== this.state.products.length ){
            this.setState({productNumber:this.state.products.length})
        }
    }
    componentDidMount(){
        window.addEventListener('resize', this.windowResizeHandler)
        if(!this.state.title && this.props.title){
            this.setState({
                title:this.props.title,
                products:[...this.props.products],
                productNumber:this.props.products.length
            })
        }
        this.products = PubSub.subscribe('mainPageProducts',(_,data)=>{
            if(this.state.title){
                this.setState({products:[...data[this.state.title]]})
            }
        })
        
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.windowResizeHandler)
        PubSub.unsubscribe(this.products)
    }
  render() {
    console.log(this.state.productIndex,this.state.productNumber,this.state.slideNumber)
    return (
      <div className='productlisting'>
        <div className='productlisting-main'>
            <h1>{this.state.title}</h1>
            <div className='productlisting-listing'>
                <button className='productlisting-listing-button' style={{color:this.state.productIndex > 0 ? "black" : "rgb(190,190,190)" }} onClick={()=>this.listingSlide(this.state.productIndex,true)}>{`<`}</button>
                <div className='productlisting-listing-main' >
                    <div className='productlisting-listing-box'style={{marginLeft:`${this.state.listingBoxMargin}px`}}>
                        {this.state.products?.map(product=>(<ProductBreif width={`${this.state.productWidth}px`} key={`${this.state.title}-${product.sku}`} product={product}/>))}
                        {/* <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/>
                        <ProductBreif width={`${this.state.productWidth}px`}/> */}
                    </div>
                </div>
                <button className='productlisting-listing-button' style={{color:this.state.productIndex < (this.state.productNumber - this.state.slideNumber) ? "black" : "rgb(190,190,190)" }} onClick={()=>this.listingSlide(this.state.productIndex,false)}>{`>`}</button>
            </div>
        </div>
      </div>
    )
  }
}
