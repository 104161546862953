import React, { Component } from 'react'
import './index.css'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import { Link } from 'react-router-dom'
import PubSub from 'pubsub-js'
class FAQPage extends Component {
    componentDidMount(){
        PubSub.publish('routes',[{pathname:'/acer/faq',name:'FAQ',isCurrent:true}])
    }
  render() {
    return (
      <div className='faqpage'>
        <PageRoute/>
        <div className='faqpage-content'>
            <h1>FAQ</h1>
            <div className='faqpage-para'>
                <h2>What if my order was damaged during shipment?</h2>
                <p>Please <Link to={{pathname:"/contact-us",state:{from:this.props.location.pathname}}}>Contact Us</Link> via email for any shipment issues within 7 days of your order receipt.</p>
            </div>
            <div className='faqpage-para'>
                <h2>How do I cancel an order?</h2>
                <p>{`Orders are sent almost immediately after payment is processed(with the exception of weekends and non-business days), so it can sometimes be difficult to cancel.`} Please <Link to={{pathname:"/contact-us",state:{from:this.props.location.pathname}}}>Contact Us</Link> and we will try our best to help.</p>
            </div>
            <div className='faqpage-para'>
                <h2>Where do manufacturer refurbished products come from?</h2>
                <p>Manufacturer refurbished items are often returned by a customer or previously opened products that have been cleaned, and restored to a like new functional condition.</p>
            </div>
            <div className='faqpage-para'>
                <h2>Where do manufacturer refurbished products come from?</h2>
                <div className='faqpage-para-sub'>
                    <h3>Scratch and Dent:</h3>
                    <p>{`Purchasing Scratch and Dent products is how you can get the best value. But all Scratch and Dents are not created equal, which is why we put all products through a rigorous refurbishment and testing process. Our Scratch and Dent products are all fully functioning and come with a full 90 Day Warranty, Tech Support and Customer Service. These products may show some cosmetic damage, like scratches on the side panels or top covers. All product displays (example: Laptops, Monitors and All in Ones) are fully functioning, however they could have slight scratches and some possible dead pixels. Our goal is to offer you the best value on all our products, if you are not 100% satisfied with your purchase, we offer a 14 day refund policy.`}</p>
                </div>
                <div className='faqpage-para-sub'>
                    <h3>New Open Box:</h3>
                    <p>New Open Box items are a great way to save. Our New Open Box items are Brand New and offer a 1 Year Acer Factory warranty. On occasion, these items could have packaging that can show some wear and tear. Lets be honest, you are not buying the product because of the box. If you are not 100% satisfied with your purchase we offer a 14 day refund policy.</p>
                </div>
                <div className='faqpage-para-sub'>
                    <h3>Factory Recertified:</h3>
                    <p>Factory Recertified products are another great way to save. These items are usually customer returns that have been fully refurbished directly by Acer to their factory standards. We stand behind each and every product we sell, providing our customers with a full 90 Day Warranty, Tech Support and Customer Service. Our Factory Recertified Products have been fully tested, reconditioned and are ready for you. If you are not 100% satisfied with your purchase we offer a 14 day refund policy.</p>
                </div>
            </div>
            <div className='faqpage-para'>
                <h2>What is the return policy?</h2>
                <p>{`All items offer a 30-day return policy beginning on the item delivery date. All returns require prior authorization (RMA) and will be in accordance with Acer's Return Policy. Depending on the reason for return, the customer may be liable for return shipping charges. Please Contact Us to initiate a return or notify us of any other issues with your order.`}</p>
            </div>
            <div className='faqpage-para'>
                <h2>What is included with a Recertified Desktop?</h2>
                <p>Keyboard, mouse, and power cord.</p>
            </div>
            <div className='faqpage-para'>
                <h2>What is included with a Recertified Laptop?</h2>
                <p>Power adapter and battery pack.</p>
            </div>
            <div className='faqpage-para'>
                <h2>{`Will the unit ship with recovery software (Windows OS disc)?`}</h2>
                <p>{`No. The OS on all devices comes pre-installed. Acer Windows machines ship with eRecovery Manager, which facilitates the creation of recovery media (CDs or DVDs) so the system may be restored in the event of unrecoverable issues. For more information about this process, please visit the links below:`}</p>
                <p><a href='http://panam.acer.com/acerpanam/desktop/0000/Acer/AspireE360/AspireE360faq67.shtml'>http://panam.acer.com/acerpanam/desktop/0000/Acer/AspireE360/AspireE360faq67.shtml</a></p>
                <p><a href='http://us.acer.com/ac/en/US/content/support'>http://us.acer.com/ac/en/US/content/support</a></p>
            </div>
            <div className='faqpage-para'>
                <h2>Where can I get more information regarding the technical aspects of this system?</h2>
                <p>Please review the listing for the product you're interested in. We make every effort to provide complete product details in each listing. If you still have a question about a listing, you may <Link to={{pathname:"/contact-us",state:{from:this.props.location.pathname}}}>Contact Us</Link>.</p>
            </div>
            <div className='faqpage-para'>
                <h2>What about shipping and handling?</h2>
                <p>Our products are shipped daily and the shipping time frame is 5-7 days.  Shipment of products is limited to physical addresses in the Continental United States. We do not offer shipping to PO Boxes, APOs, or FPOs. All orders are shipped via FedEx Shipping. No other shipping options or destinations are available at this time.</p>
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(FAQPage)