import Cookies from 'universal-cookie'
const serverInfo={
    // baseURL:"http://localhost:8080",
    baseURL:"https://alcmicro-b2b-server.uc.r.appspot.com",
    token:"2b79347bf4b97cc5537a26fc94473d8f36aa3b9fb3e33d05b092d09558ee079b",
    customerid: new Cookies().get('customerid') ? new Cookies().get('customerid',{doNotParse:1}) : "visitors",
    contactPersonId: new Cookies().get('contactPersonId') ? new Cookies().get('contactPersonId',{doNotParse:1}) : null,
    contactName: new Cookies().get('contactName') ? new Cookies().get('contactName',{doNotParse:1}) : null,
    percentage: new Cookies().get('percentage') ? new Cookies().get('percentage') : 0,
    imageUrlPrefix:"https://drive.google.com/thumbnail?id=",
    imageUrlSurfix:"&sz=w1000",
    // imageUrlPrefix:"https://lh3.googleusercontent.com/d/",
    // imageUrlSurfix:"",
    imageRegex:'/d/([^/]*)/',
    updateCustomerId,
    resetConfig,
    updateInfo
}
function updateCustomerId(id){
    serverInfo["customerid"] = id
}
function updateInfo(field,data){
    serverInfo[field] = data
}
//call when customer is logged out
function resetConfig(){
    serverInfo["customerid"] = "visitors"
    serverInfo["contactPersonId"] = null
    serverInfo["contactName"] = null
    serverInfo["percentage"] = 0
}
export default serverInfo