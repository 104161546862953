import React, { Component } from 'react'
import './index.css'
import PageRoute from '../PageRoute'
import Multiselect from './Multiselect'
import defualtBanner from '../../media/image/website_photo/main page 2.PNG'
import aioBanner from '../../media/image/website_photo/AIO_category.png'
import chromebookBanner from '../../media/image/website_photo/chromebook_category.png'
import desktopBanner from '../../media/image/website_photo/DT_category.png'
import gamingBanner from '../../media/image/website_photo/gaming_category.png'
import gamingDesktopBanner from '../../media/image/website_photo/gaming_DT.png'
import gamingMonitorBanner from '../../media/image/website_photo/gaming_monitor.png'
import gamingNotebookBanner from '../../media/image/website_photo/gaming_nb.png'
import monitorBanner from '../../media/image/website_photo/monitor_category.png'
import notebookBanner from '../../media/image/website_photo/notebook_category.png'
import tabletBanner from '../../media/image/website_photo/tablet_category.png'
import loadingGif from '../../media/image/Spinner.gif'
import { CgLayoutGrid,CgLayoutList} from "react-icons/cg";
import ProductBrief from '../ProductBrief'
import ProductDetail from '../ProductDetail'
import PubSub from 'pubsub-js'
import withRouter from '../../withRouter';
import serverInfo from '../../config'
import Cookies from 'universal-cookie'
import axios from 'axios'
class CategoryPage extends Component {
  constructor(props){
    super(props)
    this.cookies = new Cookies()
    this.categoryBanners = {
      Sale:{banner:defualtBanner},
      BrandNew:{banner:defualtBanner},
      Notebook:{banner:notebookBanner},
      Desktop:{banner:desktopBanner},
      AIO:{banner:aioBanner},
      Chromebook:{banner:chromebookBanner},
      Monitor:{banner:monitorBanner},
      Tablet:{banner:tabletBanner},
      Accessories:{banner:defualtBanner},
      Gaming:{
        banner:gamingBanner,
        Desktop:{banner:gamingDesktopBanner},
        Monitor:{banner:gamingMonitorBanner},
        Notebook:{banner:gamingNotebookBanner},
      },
      ScratchDent:{banner:defualtBanner},
      Warranty:{banner:defualtBanner},
      Search:{banner:defualtBanner},

      //Subcategory
      Chromebox:"Chromebox",
      Tower:"Tower",
      WorkHome:"Work & Home",
      BackpackCase:"Bakcpack & Case",
      Charger:"Charger",
      DockingStation:"Docking Station",
      GraphicsCard:"Graphics Card",
      Headset:"Headset",
      Mice:"Mice",
      Smartphone:"Smartphone"
    }
    this.cateogryShowName = {
      //Category
      Sale:"Sale",
      BrandNew:"Brand New",
      Notebook:"Notebook",
      Desktop:"Desktop",
      AIO:"All-In-One",
      Chromebook:"Chromebook",
      Monitor:"Monitor",
      Tablet:"Tablet",
      Accessories:"Accessories",
      Gaming:"Gaming",
      ScratchDent:"Scratch & Dent",
      Warranty:"Warranty",
      Search:"Search",

      //Subcategory
      Chromebox:"Chromebox",
      Tower:"Tower",
      WorkHome:"Work & Home",
      BackpackCase:"Bakcpack & Case",
      Charger:"Charger",
      DockingStation:"Docking Station",
      GraphicsCard:"Graphics Card",
      Headset:"Headset",
      Mice:"Mice",
      Smartphone:"Smartphone"
    }
    this.filterNameList= [
      "stock",
      "product_category",
      "product_series",
      // "product_model",
      "processor_type",
      "processor_speed",
      "standard_memory",
      "screen_size",
      "screen_resolution",
      "graphics_card",
      "solid_state_drive",
      "touchscreen",
      "operating_system",
      "color",
      "refresh_rate"
    ]
    this.sortRef = React.createRef()
    this.multiselectRef = React.createRef()
    this.state = {
      isMobile:window.innerWidth<=800?true:false,
      hideFilters:window.innerWidth<=800?true:false,
      filterTop:window.pageYOffset,
      isDetial:window.innerWidth<=800?true:false,
      itemPerPage:16,
      productList:[],
      pageList:[1],
      currentPageList:[], //only have maximum 5 pages in currentPageList
      currentCategory:"",
      currentSubCategory:"",
      currentPageItems:null, // items List on current pages
      filterSelectedItems:[], // items filtered by filters but not prices
      finalSelectedItems:[], // items filtered by both price range and filters
      currentPage:1,
      searchString:"",
      filters:null,
      filterValues:null,
      priceRangeMax:0,
      maxPrice:0,
      minPrice:0,
      percentage: this.cookies.get('percentage') ? this.cookies.get('percentage') : 0,
      bannerURL:chromebookBanner
    }
    this.freezeWindow = function(){
      window.scrollTo({top:this.state.filterTop,behavior:"auto"})
    }
    this.freezeHandler = this.freezeWindow.bind(this)
    this.handleClickOutsideHandler = this.handleClickOutside.bind(this);
  }
  windowResize(){
    if(window.innerWidth>800 && this.state.hideFilters){
        this.setState({hideFilters:false})
    }
    if(window.innerWidth<=800 && !this.state.hideFilters && !this.state.isMobile){
      this.setState({hideFilters:true})
    }
    if(window.innerWidth>800 && this.state.isMobile){
      this.setState({isMobile:false})
      PubSub.publish("hideFilters",false)
    }
    if(window.innerWidth<=800 && !this.state.isMobile){
      this.setState({isMobile:true})
    } 
  }
  changePage = (pageNum)=>{
    let currentPageList = [...this.state.currentPageList]
    if(this.state.pageList.length>5){
      const totalPage = this.state.pageList.length
      if(pageNum < this.state.currentPageList[2]){
        if(pageNum - 3 >= 0){
          currentPageList = this.state.pageList.slice(pageNum - 3, pageNum + 2)
        }else{
          currentPageList = this.state.pageList.slice(0,5)
        }
      }else if(pageNum > this.state.currentPageList[2]){
        if(pageNum + 2 <= this.state.pageList.length){
          currentPageList = this.state.pageList.slice(pageNum - 3, pageNum + 2)
        }else{
          currentPageList = this.state.pageList.slice(this.state.pageList.length-5, this.state.pageList.length)
        }
      }
    }
    this.setState({
      currentPageItems:this.state.finalSelectedItems.slice((pageNum-1)*this.state.itemPerPage,pageNum*this.state.itemPerPage),
      currentPageList,
      currentPage:pageNum
    })
  }
  handleClickOutside(event) {
    if (this.multiselectRef && !this.multiselectRef.current.contains(event.target) && window.innerWidth<=800) {
        this.setState({
          hideFilters:true
        })
    }
  }
  showfilter(){
    this.setState({hideFilters:false,filterTop:window.pageYOffset})
    window.addEventListener('scroll',this.freezeHandler)
    // PubSub.publish("hideFilters",false)
  }
  sortItems= () => {
    const productList = [...this.state.productList]
    const filterSelectedItems = [...this.state.filterSelectedItems]
    const finalSelectedItems=[...this.state.finalSelectedItems]
    if(this.sortRef.current.value === 'A to Z'){
      productList.sort((a,b)=>a.item_name > b.item_name ? 1 : (a.item_name < b.item_name ? -1 : 0))
      filterSelectedItems.sort((a,b)=>a.item_name > b.item_name ? 1 : (a.item_name < b.item_name ? -1 : 0))
      finalSelectedItems.sort((a,b)=>a.item_name > b.item_name ? 1 : (a.item_name < b.item_name ? -1 : 0))


    }else if(this.sortRef.current.value === 'Z to A'){
      productList.sort((a,b)=>a.item_name > b.item_name ? -1 : (a.item_name < b.item_name ? 1 : 0))
      filterSelectedItems.sort((a,b)=>a.item_name > b.item_name ? -1 : (a.item_name < b.item_name ? 1 : 0))
      finalSelectedItems.sort((a,b)=>a.item_name > b.item_name ? -1 : (a.item_name < b.item_name ? 1 : 0))


    }else if(this.sortRef.current.value === 'Price: Ascending'){
      productList.sort((a,b)=>{
        const priceA = a.is_sale ? parseFloat(a.sale_price) : parseFloat(a.price)
        const priceB = b.is_sale ? parseFloat(b.sale_price) : parseFloat(b.price)
        return priceA > priceB ? 1 : (priceA < priceB ? -1 : 0)
      })
      filterSelectedItems.sort((a,b)=>{
        const priceA = a.is_sale ? parseFloat(a.sale_price) : parseFloat(a.price)
        const priceB = b.is_sale ? parseFloat(b.sale_price) : parseFloat(b.price)
        return priceA > priceB ? 1 : (priceA < priceB ? -1 : 0)
      })
      finalSelectedItems.sort((a,b)=>{
        const priceA = a.is_sale ? parseFloat(a.sale_price) : parseFloat(a.price)
        const priceB = b.is_sale ? parseFloat(b.sale_price) : parseFloat(b.price)
        return priceA > priceB ? 1 : (priceA < priceB ? -1 : 0)
      })


    }else if(this.sortRef.current.value === 'Price: Descending'){
      productList.sort((a,b)=>{
        const priceA = a.is_sale ? parseFloat(a.sale_price) : parseFloat(a.price)
        const priceB = b.is_sale ? parseFloat(b.sale_price) : parseFloat(b.price)
        return priceA > priceB ? -1 : (priceA < priceB ? 1 : 0)
      })
      filterSelectedItems.sort((a,b)=>{
        const priceA = a.is_sale ? parseFloat(a.sale_price) : parseFloat(a.price)
        const priceB = b.is_sale ? parseFloat(b.sale_price) : parseFloat(b.price)
        return priceA > priceB ? -1 : (priceA < priceB ? 1 : 0)
      })
      finalSelectedItems.sort((a,b)=>{
        const priceA = a.is_sale ? parseFloat(a.sale_price) : parseFloat(a.price)
        const priceB = b.is_sale ? parseFloat(b.sale_price) : parseFloat(b.price)
        return priceA > priceB ? -1 : (priceA < priceB ? 1 : 0)
      })
    }
    const currentPageItems = finalSelectedItems.slice(0,this.state.itemPerPage)
    const currentPageList = this.state.pageList.slice(0,5)
    this.setState({
      productList,
      filterSelectedItems,
      finalSelectedItems,
      currentPageItems,
      currentPageList,
      currentPage:1
    })
  }
  componentDidUpdate(){
    const subcategory = this.props.params.subcategory?this.props.params.subcategory:(this.props.location.search?this.props.location.search:"")
    let bannerURL = defualtBanner
    if(this.props.params.category && (this.props.params.category !== this.state.currentCategory || subcategory !== this.state.currentSubCategory )){
      let serverUrl = null, routes = []
      if(this.props.params.category !== 'Search'){
        serverUrl = `${serverInfo.baseURL}/category/${this.props.params.category === 'Monitor' ? 'Display' : this.props.params.category}`
        routes.push({pathname:`/acer/category/${this.props.params.category}`,name:`${this.props.params.category}`,isCurrent:this.props.params.subcategory?false:true})
        bannerURL = this.categoryBanners[this.props.params.category].banner
        if(this.props.params.subcategory){
          serverUrl = `${serverUrl}/${this.props.params.subcategory=== 'Monitor' ? 'Display' : this.props.params.subcategory}`
          routes.push({pathname:`/acer/category/${this.props.params.category}/${this.props.params.subcategory}`,name:`${this.props.params.subcategory}`,isCurrent:true})
          bannerURL = this.categoryBanners[this.props.params.category]?.[this.props.params.subcategory]?.banner ? this.categoryBanners[this.props.params.category][this.props.params.subcategory].banner : this.categoryBanners[this.props.params.category].banner
        }
      }else{
        serverUrl = `${serverInfo.baseURL}/search${this.props.location.search}`
        routes.push({pathname:`/acer/category/${this.props.params.category}`,name:`${this.props.params.category}`,isCurrent:true})
        bannerURL = this.categoryBanners[this.props.params.category].banner
      }
      axios.get(serverUrl,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
        res=>{
          if(res.data.scode === 200){
            const pageList = []
            const maxPrice = res.data.categoryItems.priceRange?.[0]?.max_price
            const minPrice = 0
            const itemsBrief = []
            if(this.props.params.category.includes('BrandNew')){
              for(const itembrief of res.data.categoryItems.itemsBrief){
                const item = {...itembrief}
                item["item_condition"] = "BrandNew"
                itemsBrief.push(item)
              }
            }else if(this.props.params.category.includes('ScratchDent')){
              for(const itembrief of res.data.categoryItems.itemsBrief){
                const item = {...itembrief}
                item["item_condition"] = "ScratchDent"
                itemsBrief.push(item)
              }
            }else{
              for(const itembrief of res.data.categoryItems.itemsBrief){
                if(itembrief.is_brand_new){
                  const item = {...itembrief}
                  item["item_condition"] = "BrandNew"
                  itemsBrief.push(item)
                }
                if(itembrief.is_recertified){
                  const item = {...itembrief}
                  item["item_condition"] = "Recertified"
                  itemsBrief.push(item)
                }
                if(itembrief.is_scratch_dent){
                  const item = {...itembrief}
                  item["item_condition"] = "ScratchDent"
                  itemsBrief.push(item)
                }
                if(!itembrief.is_scratch_dent && !itembrief.is_recertified && !itembrief.is_brand_new && itembrief.category === 'WARRANTY'){
                  const item = {...itembrief}
                  item["item_condition"] = "Warranty"
                  itemsBrief.push(item)
                }
              }
            }
            const totalPage = Math.ceil(itemsBrief.length/this.state.itemPerPage)
            for(let i = 0; i < totalPage;i ++){
              pageList.push(i+1)
            }
            itemsBrief.sort((a,b)=>a.item_name > b.item_name ? 1 : (a.item_name < b.item_name ? -1 : 0))
            const newFilters = {'stock':[{stock:"In Stock",count:0},{stock:"Out of Stock",count:0}],...res.data.categoryItems.filters}
            // add stock status into filters
            // newFilters['stock'] = [{stock:"In Stock",count:0},{stock:"Out of Stock",count:0}]
            for(const filterName in newFilters){
              for(const valueObject of newFilters[filterName]){
                valueObject['count'] = 0
              }
            }
            for(const item of itemsBrief){
              // for countint stock and out of stock items 
              if(item.item_condition === "ScratchDent"){
                if(item.scratch_dent_stock > 0){
                  newFilters['stock'][0]['count'] +=1
                }else{
                  newFilters['stock'][1]['count'] +=1
                }
              }else if(item.item_condition === "Warranty"){
                newFilters['stock'][0]['count'] +=1
              }else{
                if(item.stock > 0){
                  newFilters['stock'][0]['count'] +=1
                }else{
                  newFilters['stock'][1]['count'] +=1
                }
              }
              for(const filterName of this.filterNameList){
                if(item[filterName] || filterName === 'touchscreen'){
                  const itemFilter = {}
                  itemFilter[filterName] = filterName === 'touchscreen' ? (item[filterName] ? 'Yes' : 'No') : item[filterName]
                  itemFilter['count'] = 1
                  if(newFilters[filterName]){
                    let sameValue = false
                    for(let i = 0; i < newFilters[filterName].length;i++){
                      if(newFilters[filterName][i][filterName] === itemFilter[filterName]){
                        sameValue = true
                        newFilters[filterName][i]['count'] += 1
                        break
                      }
                    }
                    if(!sameValue && filterName !== "stock"){
                      newFilters[filterName].push(itemFilter)
                    }
                  }else{
                    newFilters[filterName] = []
                    newFilters[filterName].push(itemFilter)
                  }
                }
              }
            }
            this.setState({
              productList:[...itemsBrief],
              filters:{...newFilters},
              filterValues:{...res.data.categoryItems.filterValues},
              pageList:pageList,
              currentPageList:pageList.slice(0,5),
              filterSelectedItems:[...itemsBrief],
              currentPageItems:itemsBrief.slice(0,this.state.itemPerPage),
              finalSelectedItems:[...itemsBrief],
              currentCategory:this.props.params.category,
              currentSubCategory:subcategory,
              currentPage:1,
              priceRangeMax:maxPrice,
              maxPrice,
              minPrice,
              bannerURL:bannerURL
            })
            PubSub.publish("originalFilters",{selection:{...newFilters},priceRange:{maxPrice,minPrice}})
          }else{
            console.log(res.data.scode,res.data.smessage)
          }
        },
        rej=>console.log(rej)
      )
      PubSub.publish('routes',routes)
    }
  }
  componentDidMount(){
    let bannerURL = defualtBanner
    window.addEventListener('resize', this.windowResize.bind(this))
    // console.log('categoryRender', this.props.location.state)
    if(this.props.location?.state?.windowYOffset){
      window.scrollTo({top:this.props.location?.state?.windowYOffset,behavior:"auto"})
    }else{
      window.scrollTo({top:0,behavior:"auto"})
    }
    // initially get the category items information from server
    if(this.props.params.category){
      const subcategory = this.props.params.subcategory?this.props.params.subcategory:(this.props.location.search?this.props.location.search:"")
      let serverUrl = null, routes = []
      if(this.props.params.category !== 'Search'){
        serverUrl = `${serverInfo.baseURL}/category/${this.props.params.category === 'Monitor' ? 'Display' : this.props.params.category}`
        routes.push({pathname:`/acer/category/${this.props.params.category}`,name:`${this.props.params.category}`,isCurrent:this.props.params.subcategory?false:true})
        bannerURL = this.categoryBanners[this.props.params.category].banner 
        if(this.props.params.subcategory){
          serverUrl = `${serverUrl}/${this.props.params.subcategory=== 'Monitor' ? 'Display' : this.props.params.subcategory}`
          routes.push({pathname:`/acer/category/${this.props.params.category}/${this.props.params.subcategory}`,name:`${this.props.params.subcategory}`,isCurrent:true})
          bannerURL = this.categoryBanners[this.props.params.category]?.[this.props.params.subcategory]?.banner ? this.categoryBanners[this.props.params.category][this.props.params.subcategory].banner : this.categoryBanners[this.props.params.category].banner
        }
      }else{
        serverUrl = `${serverInfo.baseURL}/search${this.props.location.search}`
        routes.push({pathname:`/acer/category/${this.props.params.category}`,name:`${this.props.params.category}`,isCurrent:true})
        bannerURL = this.categoryBanners[this.props.params.category].banner
      }
      axios.get(serverUrl,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
        res=>{
          if(res.data.scode === 200){
            const pageList = []
            const maxPrice = res.data.categoryItems.priceRange?.[0]?.max_price
            const minPrice = 0
            const itemsBrief = []
            if(this.props.params.category.includes('BrandNew')){
              for(const itembrief of res.data.categoryItems.itemsBrief){
                const item = {...itembrief}
                item["item_condition"] = "BrandNew"
                itemsBrief.push(item)
              }
            }else if(this.props.params.category.includes('ScratchDent')){
              for(const itembrief of res.data.categoryItems.itemsBrief){
                const item = {...itembrief}
                item["item_condition"] = "ScratchDent"
                itemsBrief.push(item)
              }
            }else{
              for(const itembrief of res.data.categoryItems.itemsBrief){
                if(itembrief.is_brand_new){
                  const item = {...itembrief}
                  item["item_condition"] = "BrandNew"
                  itemsBrief.push(item)
                }
                if(itembrief.is_recertified){
                  const item = {...itembrief}
                  item["item_condition"] = "Recertified"
                  itemsBrief.push(item)
                }
                if(itembrief.is_scratch_dent){
                  const item = {...itembrief}
                  item["item_condition"] = "ScratchDent"
                  itemsBrief.push(item)
                }
                if(!itembrief.is_scratch_dent && !itembrief.is_recertified && !itembrief.is_brand_new && itembrief.category === 'WARRANTY'){
                  const item = {...itembrief}
                  item["item_condition"] = "Warranty"
                  itemsBrief.push(item)
                }
              }
            }
            const totalPage = Math.ceil(itemsBrief.length/this.state.itemPerPage)
            for(let i = 0; i < totalPage;i ++){
              pageList.push(i+1)
            }
            itemsBrief.sort((a,b)=>a.item_name > b.item_name ? 1 : (a.item_name < b.item_name ? -1 : 0))
            const newFilters = {'stock':[{stock:"In Stock",count:0},{stock:"Out of Stock",count:0}],...res.data.categoryItems.filters}
            // add stock status into filters
            // newFilters['stock'] = [{stock:"In Stock",count:0},{stock:"Out of Stock",count:0}]
            for(const filterName in newFilters){
              for(const valueObject of newFilters[filterName]){
                valueObject['count'] = 0
              }
            }
            for(const item of itemsBrief){
              // for countint stock and out of stock items 
              if(item.item_condition === "ScratchDent"){
                if(item.scratch_dent_stock > 0){
                  newFilters['stock'][0]['count'] +=1
                }else{
                  newFilters['stock'][1]['count'] +=1
                }
              }else if(item.item_condition === "Warranty"){
                newFilters['stock'][0]['count'] +=1
              }else{
                if(item.stock > 0){
                  newFilters['stock'][0]['count'] +=1
                }else{
                  newFilters['stock'][1]['count'] +=1
                }
              }
              for(const filterName of this.filterNameList){
                if(item[filterName] || filterName === 'touchscreen'){
                  const itemFilter = {}
                  itemFilter[filterName] = filterName === 'touchscreen' ? (item[filterName] ? 'Yes' : 'No') : item[filterName]
                  itemFilter['count'] = 1
                  if(newFilters[filterName]){
                    let sameValue = false
                    for(let i = 0; i < newFilters[filterName].length;i++){
                      if(newFilters[filterName][i][filterName] === itemFilter[filterName]){
                        sameValue = true
                        newFilters[filterName][i]['count'] += 1
                        break
                      }
                    }
                    if(!sameValue && filterName !== "stock"){
                      newFilters[filterName].push(itemFilter)
                    }
                  }else{
                    newFilters[filterName] = []
                    newFilters[filterName].push(itemFilter)
                  }
                }
              }
            }
            this.setState({
              productList:[...itemsBrief],
              filters:{...newFilters},
              filterValues:{...res.data.categoryItems.filterValues},
              filterSelectedItems:[...itemsBrief],
              finalSelectedItems:[...itemsBrief],
              currentPageItems:itemsBrief.slice(0,this.state.itemPerPage),
              pageList:pageList,
              currentPageList:pageList.slice(0,5),
              currentCategory:this.props.params.category,
              currentSubCategory:subcategory,
              currentPage:1,
              priceRangeMax:maxPrice,
              maxPrice,
              minPrice,
              bannerURL:bannerURL
            })
            PubSub.publish("originalFilters",{selection:{...newFilters},priceRange:{maxPrice,minPrice}})
            // console.log(res.data)
          }else{
            console.log(res.data.scode,res.data.smessage)
          }
        },
        rej=>console.log(rej)
      )
      PubSub.publish('routes',routes)
    }
    // for multiselect change 
    this.selected = PubSub.subscribe('selected',(_,data)=>{
      // let maxPrice = 0, minPrice = 0
      const selectedFilter = {}, filterSelectedItems = [], newFilters = {...this.state.filters}, selectedValue = [], finalSelectedItems = []
      for(const filterName in newFilters){
        for(const valueObject of newFilters[filterName]){
          valueObject['count'] = 0
        }
      }
      // console.log('newFilters',newFilters)
      /* 
      create selected filter as {
        fitlerName: [selectedValue1, selectedValue2 .....], (item only needs to meet one requiremnt in each filterName)
        ....
      }
      */
      for(const filterName in data.selected){
        for(const value in data.selected[filterName]){
          if(data.selected[filterName]?.[value]){
            if(!selectedFilter[filterName]){
              selectedFilter[filterName] = []
            }
            selectedFilter[filterName].push(value)
            selectedValue.push(value)
          }
        }
      }
      console.log('selected',selectedFilter)
      /*
      check whether item is meet requirements, 
      isSelected define whether the item is meet the total requiremnts,
      isInFitler define whether current item attribute equals one of values in current seleceted filterName
      */
      for(const item of this.state.productList){
        // initially set item is selected
        let isSelected = true, matchStock = true
        const itemPrice = item.is_sale ? parseFloat(item.sale_price) : parseFloat(item.price)
        // match search string first
        if(data.searchString && data.searchString !== ''){
          const capitalString = data.searchString.toUpperCase()
          isSelected = item.sku.toUpperCase().includes(capitalString) || item.item_name.toUpperCase().includes(capitalString) ? true : false
        }
        //if the search string is matched
        if(isSelected){
          // console.log(item)
          for(const filterName in selectedFilter){
            // initially item is not matched in each filter category
            let isInFitler = false
            // stock is not counted here
            if(filterName === 'stock'){
              continue
            }
            // check whether the item filter cagteogry value is selected
            for(const value of selectedFilter[filterName]){
              const isTouchscreen = value === 'Yes' ? 1 : 0 
              if(item[filterName] === value || (filterName === 'touchscreen' && item[filterName] === isTouchscreen)){
                // console.log(item)
                isInFitler = true
                break
              }
            }
            if(!isInFitler){
              isSelected = false
              break
            }
          }
        }
        if(isSelected){
          // for getting currently filter selected items price range
          // const itemPrice = item.is_sale ? item.sale_price:item.price
          // maxPrice = maxPrice === 0 || itemPrice > maxPrice ? itemPrice : maxPrice
          // minPrice = minPrice === 0 || itemPrice < minPrice ? itemPrice : minPrice 
          if(selectedFilter['stock']?.length === 1){
            if(selectedFilter['stock'][0] === 'In Stock'){
              isSelected = item.item_condition === 'ScratchDent' ? (item.scratch_dent_stock > 0 ? true : false) 
                : (item.item_condition === 'Warranty' ? true : (item.stock > 0 ? true : false));
              newFilters['stock'][0]['count'] = isSelected ? newFilters['stock'][0]['count'] + 1 : newFilters['stock'][0]['count'] 
            }else{
              isSelected = item.item_condition === 'ScratchDent' ? (item.scratch_dent_stock > 0 ? false : true) 
                : (item.item_condition === 'Warranty' ? false : (item.stock > 0 ? false : true));
              newFilters['stock'][1]['count'] = isSelected ? newFilters['stock'][1]['count'] + 1 : newFilters['stock'][1]['count'] 
            }
          }
          if(isSelected){
            filterSelectedItems.push(item)
          }
        }
        if(itemPrice< this.state.minPrice || itemPrice > this.state.maxPrice){
          isSelected = false
        }
        //put item attribute in new filters
        if(isSelected){
          finalSelectedItems.push(item)
          if(selectedFilter['stock']?.length !== 1){
            if(item.item_condition === "ScratchDent"){
              if(item.scratch_dent_stock > 0){
                newFilters['stock'][0]['count'] +=1
              }else{
                newFilters['stock'][1]['count'] +=1
              }
            }else if(item.item_condition === "Warranty"){
              newFilters['stock'][0]['count'] +=1
            }else{
              if(item.stock > 0){
                newFilters['stock'][0]['count'] +=1
              }else{
                newFilters['stock'][1]['count'] +=1
              }
            }
          }
          for(const filterName of this.filterNameList){
            if(item[filterName] || filterName === 'touchscreen'){
              const itemFilter = {}
              itemFilter[filterName] = filterName === 'touchscreen' ? (item[filterName] ? 'Yes' : 'No') : item[filterName]
              itemFilter['count'] = 1
              if(newFilters[filterName]){
                let sameValue = false
                for(let i = 0; i < newFilters[filterName].length;i++){
                  if(newFilters[filterName][i][filterName] === itemFilter[filterName]){
                    sameValue = true
                    newFilters[filterName][i]['count'] += 1
                    break
                  }
                }
                if(!sameValue && filterName !== "stock"){
                  newFilters[filterName].push(itemFilter)
                }
              }else{
                newFilters[filterName] = []
                newFilters[filterName].push(itemFilter)
              }
            }
          }
        }
      }
      PubSub.publish('currentFilters',{selection:newFilters})
      const pageList = []
      const totalPage = Math.ceil(finalSelectedItems.length/this.state.itemPerPage)
      for(let i = 0; i < totalPage;i ++){
        pageList.push(i+1)
      }
      this.setState({
        filterSelectedItems,
        finalSelectedItems,
        searchString:data.searchString,
        currentPageItems:finalSelectedItems.slice(0,this.state.itemPerPage),
        currentPage:1,
        pageList,
        currentPageList:pageList.slice(0,5),
      })
    })

    // update when price range is updated
    this.updatePriceRage = PubSub.subscribe('priceFilter',(_,data)=>{
      const finalSelectedItems = [], newFilters = {...this.state.filters}
      for(const filterName in newFilters){
        for(const valueObject of newFilters[filterName]){
          valueObject['count'] = 0
        }
      }
      for(const item of this.state.filterSelectedItems){
        const itemPrice = item.is_sale ? parseFloat(item.sale_price) : parseFloat(item.price)
        if(itemPrice>= data.minPrice && itemPrice<= data.maxPrice){
          finalSelectedItems.push(item)
          for(const filterName of this.filterNameList){
            if(item[filterName] || filterName === 'touchscreen'){
              const itemFilter = {}
              itemFilter[filterName] = filterName === 'touchscreen' ? (item[filterName] ? 'Yes' : 'No') : item[filterName]
              itemFilter['count'] = 1
              if(newFilters[filterName]){
                let sameValue = false
                for(let i = 0; i < newFilters[filterName].length;i++){
                  if(newFilters[filterName][i][filterName] === itemFilter[filterName]){
                    sameValue = true
                    newFilters[filterName][i]['count'] += 1
                    break
                  }
                }
                if(!sameValue && filterName !== "stock"){
                  newFilters[filterName].push(itemFilter)
                }
              }else{
                newFilters[filterName] = []
                newFilters[filterName].push(itemFilter)
              }
            }
          }
        }
      }
      PubSub.publish('currentFilters',{selection:newFilters,priceRange:{maxPrice:this.state.maxPrice,minPrice:this.state.minPrice}})
      const pageList = []
      const totalPage = Math.ceil(finalSelectedItems.length/this.state.itemPerPage)
      for(let i = 0; i < totalPage;i ++){
        pageList.push(i+1)
      }
      this.setState({
        finalSelectedItems,
        currentPageItems:finalSelectedItems.slice(0,this.state.itemPerPage),
        currentPage:1,
        pageList,
        currentPageList:pageList.slice(0,5),
        maxPrice:data.maxPrice,
        minPrice:data.minPrice
      })
    })
    //for mobile version
    this.closeFilter = PubSub.subscribe('hideFilters',(_,data)=>{
      this.setState({hideFilters:data})
      window.removeEventListener('scroll',this.freezeHandler)
    })

    this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{
      this.setState({percentage:data})
    })
    window.addEventListener("mousedown", this.handleClickOutsideHandler)
  }
  
  componentWillUnmount(){
    window.removeEventListener('resize', this.windowResize.bind(this))
    window.removeEventListener("mousedown", this.handleClickOutsideHandler)
    PubSub.unsubscribe(this.closeFilter)
    PubSub.unsubscribe(this.selected)
    PubSub.unsubscribe(this.percentageSub)
    window.removeEventListener('scroll',this.freezeHandler)
  }
  render() {
    console.log(this.state.filters)
    return (
      <div className='categorypage'>
        <PageRoute/>
        <h1>{this.state.currentCategory!=='Search' && this.state.currentSubCategory!=="" ? (this.cateogryShowName[this.state.currentSubCategory]?this.cateogryShowName[this.state.currentSubCategory]:this.state.currentSubCategory) : (this.cateogryShowName[this.state.currentCategory]?this.cateogryShowName[this.state.currentCategory]:this.state.currentCategory)}</h1>
        <div className='categorypage-main'>
            <div className='categorypage-main-multi' ref={this.multiselectRef}>
              <Multiselect hideFilters={this.state.hideFilters} filterTop={this.state.filterTop} selection={this.state.filters} category={this.state.currentCategory} priceRange={{maxPrice:this.state.priceRangeMax,minPrice:0}}/>
            </div>
            <div className='categorypage-content'>
                <img src={this.state.bannerURL} alt='err'/>
                <div className='categorypage-layout'>
                    <button className={this.state.isDetial?'':'active'} onClick={()=>this.setState({isDetial:false})}><CgLayoutGrid fontSize={'40px'} fontWeight={'bold'}/></button>
                    <button className={this.state.isDetial?'active':''}onClick={()=>this.setState({isDetial:true})}><CgLayoutList fontSize={'40px'}/></button>
                    <div className='categorypage-select'>
                        <p style={{minWidth:"fit-content"}}>SORT BY: </p>
                        <select ref={this.sortRef} onChange={()=>this.sortItems()} style={{marginRight:"10px"}}>
                            {/* <option>Featured Items</option>
                            <option>Newest Items</option>
                            <option>Best Selling</option> */}
                            <option>A to Z</option>
                            <option>Z to A</option>
                            {/* <option>By Review</option> */}
                            <option>Price: Ascending</option>
                            <option>Price: Descending</option>
                        </select>
                    </div>
                    <button className='categorypage-mobile-filter' onClick={()=>{this.showfilter()}}>FILTERS</button>
                </div>
                <div className='categorypage-listing'>
                  {
                    this.state.currentPageItems?(
                      this.state.currentPageItems.length === 0 ? <h2 style={{width:"100%",textAlign:"center"}}>No Item Available</h2> :
                    this.state.currentPageItems.map(product=>{
                      if(!this.state.isDetial && !this.state.isMobile){
                        return (<ProductBrief width='calc((100% - 15px) / 4)' key={`${product.sku}-${product.item_condition}`} product={product}/>)
                      }else{
                        return (<ProductDetail key={`${product.sku}-${product.item_condition}`} product={product}/>)
                      }
                    })
                    )
                    :
                    <div className='loading-image'><img src={loadingGif} alt="loading..."/></div>
                  }
                    {/* <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductBrief width='calc((100% - 15px) / 4)'/>
                    <ProductDetail/>
                    <ProductDetail/>
                    <ProductDetail/>
                    <ProductDetail/>
                    <ProductDetail/> */}
                </div>
                <div className='categorypage-page'>
                    <button style={{display:this.state.currentPage === 1?"none":""}} onClick={()=>this.changePage(this.state.currentPage-1)}><b>{`<`}</b> Previous</button>
                    {this.state.currentPageList?.map(pageNum=>(
                      <button key={pageNum} className={this.state.currentPage === pageNum? "active":""} onClick={()=>this.changePage(pageNum)}>{pageNum}</button>
                    ))}
                    <button style={{display:this.state.currentPage >= this.state.pageList.length?"none":""}} onClick={()=>this.changePage(this.state.currentPage+1)}>Next <b>{`>`}</b></button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
export default withRouter(CategoryPage)