import React, { Component } from 'react'
import './index.css'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import blogImageUrl from '../../media/image/blogimage.png'
import { TfiTimer } from "react-icons/tfi";
import { GoPerson } from "react-icons/go";
import { Link } from 'react-router-dom'
import PubSub from 'pubsub-js'
class BlogListPage extends Component {

    componentDidMount(){
        window.scrollTo({top:"0",behavior:"auto"})
        PubSub.publish('routes',[{pathname:'/acer/blog',name:"Blog",isCurrent:true}])
    }
  render() {
    return (
      <div className='bloglistpage'>
        <PageRoute/>
        <div className='bloglistpage-content'>
            <h1>Blog</h1>
            <div className='bloglistpage-list'>
                <div className="bloglistpage-list-blog">
                    <Link to={{pathname:"/acer/blog/test-blog",state:{from:this.props.location.pathname}}}><img src={blogImageUrl} alt='err'/></Link>
                    <Link to={{pathname:"/acer/blog/test-blog",state:{from:this.props.location.pathname}}} className="bloglistpage-blog-title"><h2>Debunked: Top 5 Refurbished Myths About Acer Recertified Devices</h2></Link>
                    <p>{`Welcome, tech enthusiasts and savvy shoppers! You’re looking for a new device on a budget and so you’re thinking about going the refurbished route, but you have questions. You probably want to know exactly what “refurbished” does (and doesn’t) mean.Here are the top 5 myths and misconceptions of refurbished devices. Myth #1: Refurbished Means Inferior QualityReality: This myth couldn't be further from the truth! Refurbished devices are thoroughly tested, repaired, and certified to meet or surpass the same quality standards as new products.`}</p>
                    <div className='bloglistpage-list-blog-footer'>
                        <Link to={{pathname:"/acer/blog/test-blog",state:{from:this.props.location.pathname}}}>LEARN MORE</Link>
                        <p><TfiTimer/> Jan 31st 2024 <GoPerson/> Admin</p>
                    </div>
                </div>
                <div className="bloglistpage-list-blog">
                    <Link to={{pathname:"/acer/blog/test-blog",state:{from:this.props.location.pathname}}}><img src={blogImageUrl} alt='err'/></Link>
                    <Link to={{pathname:"/acer/blog/test-blog",state:{from:this.props.location.pathname}}} className="bloglistpage-blog-title"><h2>Debunked: Top 5 Refurbished Myths About Acer Recertified Devices</h2></Link>
                    <p>{`Welcome, tech enthusiasts and savvy shoppers! You’re looking for a new device on a budget and so you’re thinking about going the refurbished route, but you have questions. You probably want to know exactly what “refurbished” does (and doesn’t) mean.Here are the top 5 myths and misconceptions of refurbished devices. Myth #1: Refurbished Means Inferior QualityReality: This myth couldn't be further from the truth! Refurbished devices are thoroughly tested, repaired, and certified to meet or surpass the same quality standards as new products.`}</p>
                    <div className='bloglistpage-list-blog-footer'>
                        <Link to={{pathname:"/acer/blog/test-blog",state:{from:this.props.location.pathname}}}>LEARN MORE</Link>
                        <p><TfiTimer/> Jan 31st 2024 <GoPerson/> Admin</p>
                    </div>
                </div>
            </div>
            <div className='bloglistpage-paging'>
                <button><b>{`<`}</b> Previous</button>
                <button>1</button>
                <button>2</button>
                <button>3</button>
                <button>4</button>
                <button>Next <b>{`>`}</b></button>
            </div>
        </div>
      </div>
    )
  }
}
export default withRouter(BlogListPage)
