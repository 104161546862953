import React, { Component } from 'react'
import './index.css'
import imageNotFound from '../../../media/image/image-not-found.jpeg'
// import productURL from '../../../media/image/productbrief.png'
import { IoLogoFacebook,IoMdMail,IoMdPrint,IoLogoTwitter,IoLogoPinterest } from "react-icons/io";
import serverInfo from "../../../config.js"
// import PubSub from 'pubsub-js';
export default class ImageGallery extends Component {
  constructor(props){
    super(props)
    this.firstUpdate = true
    this.state={
        listingBoxMargin:0,
        isMobile:window.innerWidth <= 800 ? true : false,
        productWidth:window.innerWidth>1200 ? (565 - 5*5 - 70) / 6: (window.innerWidth<=800 ? (window.innerWidth-20-70-25) / 6 : ((window.innerWidth-70)/2 -25 - 70) / 6),
        productGap: 5,
        productNumber:10,
        currentListNumber:0,
        mainImageUrl:this.props.mainImageUrl?this.props.mainImageUrl:null,
        images:this.props.itemImages?this.props.itemImages:[]
    }
  }
  // listingSlide(currentMargin,direction){
  //     console.log('button clicked',currentMargin)
  //     if(!direction){
  //         if(currentMargin>((-this.state.productGap - this.state.productWidth)*(this.state.productNumber - 6))){
  //             const finalMargin = currentMargin - this.state.productGap - this.state.productWidth
  //             const decrease = (this.state.productGap+this.state.productWidth) / 100
  //             let interval = setInterval(() => {
  //                 currentMargin = currentMargin - decrease
  //                 this.setState({listingBoxMargin:currentMargin})
  //                 if(currentMargin <= finalMargin){
  //                     this.setState({listingBoxMargin:finalMargin})
  //                     clearInterval(interval)
  //                 }
  //             }, 1);
  //         }
  //     }else{
  //         if(currentMargin<-(this.state.productGap + this.state.productWidth)){
  //             const finalMargin = currentMargin + this.state.productGap + this.state.productWidth
  //             console.log(currentMargin)
  //             const increase = (this.state.productGap+this.state.productWidth) / 100
  //             let interval = setInterval(() => {
  //                 currentMargin = currentMargin + increase
  //                 this.setState({listingBoxMargin:currentMargin})
  //                 if(currentMargin >= finalMargin){
  //                     this.setState({listingBoxMargin:finalMargin})
  //                     if(currentMargin>-(this.state.productGap + this.state.productWidth)){
  //                       this.setState({listingBoxMargin:0})
  //                     }
  //                     clearInterval(interval)
  //                 }
  //             }, 1);
  //         }
  //     }
  // }
  imageClick(targetListNumber,currentListNumber){
    this.setState({
      currentListNumber:targetListNumber
    })
    // the box moved when the product is at 3 and freeze at 3. the whole box size has 6 product 
    let currentMargin = currentListNumber < 2 ? 0 : ( currentListNumber >= this.state.productNumber - 4 ? (this.state.productNumber - 6) * (-this.state.productGap - this.state.productWidth) : (currentListNumber - 2) * (-this.state.productGap - this.state.productWidth))
    let finalMargin = targetListNumber < 2 ? 0 : ( targetListNumber >= this.state.productNumber - 4 ? (this.state.productNumber - 6) * (-this.state.productGap - this.state.productWidth) : (targetListNumber - 2) * (-this.state.productGap - this.state.productWidth))
    
    console.log(currentMargin,finalMargin)
    const gap = (finalMargin - currentMargin) / 100
    let interval = setInterval(() => {
        currentMargin = currentMargin + gap
        this.setState({listingBoxMargin:currentMargin})
        if(gap < 0){
          if(currentMargin <= finalMargin){
            this.setState({
              listingBoxMargin:finalMargin
            })
            clearInterval(interval)
          }
        }else{
          if(currentMargin >= finalMargin){
            this.setState({listingBoxMargin:finalMargin})
            if(currentMargin>-(this.state.productGap + this.state.productWidth)){
              this.setState({listingBoxMargin:0})
            }
            clearInterval(interval)
          }
        }
    }, 1);

  }
  listingSlide(currentListNumber,direction){
    if(!direction){
      if(currentListNumber<this.state.productNumber - 1){
        this.setState({currentListNumber: this.state.currentListNumber + 1})
      }
      
      if(currentListNumber > 2 && currentListNumber < this.state.productNumber - 4){
        let currentMargin = (currentListNumber - 2) * (- this.state.productGap - this.state.productWidth)
        const finalMargin = (currentListNumber - 2 + 1) * (- this.state.productGap - this.state.productWidth)
        const decrease = (this.state.productGap+this.state.productWidth) / 100
        console.log(currentMargin,finalMargin)
        let interval = setInterval(() => {
            currentMargin = currentMargin - decrease
            this.setState({listingBoxMargin:currentMargin})
            if(currentMargin <= finalMargin){
                this.setState({
                  listingBoxMargin:finalMargin
                })
                clearInterval(interval)
            }
        }, 1);
      }
    }else{
      if(currentListNumber>0){
        this.setState({currentListNumber: this.state.currentListNumber - 1})
      }
      if(currentListNumber > 2 && currentListNumber < this.state.productNumber - 4){
        let currentMargin = (currentListNumber - 2) * (-this.state.productGap - this.state.productWidth)
        const finalMargin = (currentListNumber - 2 - 1) * (-this.state.productGap - this.state.productWidth)
        const increase = (this.state.productGap+this.state.productWidth) / 100
        let interval = setInterval(() => {
            currentMargin = currentMargin + increase
            this.setState({listingBoxMargin:currentMargin})
            if(currentMargin >= finalMargin){
                this.setState({listingBoxMargin:finalMargin})
                if(currentMargin>-(this.state.productGap + this.state.productWidth)){
                  this.setState({listingBoxMargin:0})
                }
                clearInterval(interval)
            }
        }, 1);
      }
    }
  }
  windowResize(){
    // const currentWidth = window.innerWidth
    this.setState({
        listingBoxMargin:window.innerWidth<1200 ? 0 : this.state.listingBoxMargin,
        isMobile:window.innerWidth <= 800 ? true : false,
        productWidth:window.innerWidth>1200 ? (565 - 5*5 - 70) / 6: (window.innerWidth<=800 ? (window.innerWidth-20-70-25) / 6 : ((window.innerWidth-70)/2 -25 - 70) / 6),
    })
    // console.log(`product listing:${this.state.productWidth}`)
  }
  componentDidUpdate(){
    if(!this.firstUpdate){
      let images = [], mainImageUrl = null, image_number = 0
      if(this.props.mainImageUrl){
        const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
        const googleImageId = imageRegex.exec(this.props.mainImageUrl)?.[1]
        if(googleImageId){
          mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
        }
        images.push({image_id:googleImageId,image_url:mainImageUrl,image_number:image_number++})
      }
      if(this.props?.itemImages?.length &&  this.props?.itemImages?.length > 0){
        for(const image of this.props.itemImages){
          const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
          const googleImageId = imageRegex.exec(image.image_url)?.[1]
          let imageUrl = null
          if(googleImageId){
            imageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
          }
          images.push({image_id:image.image_id,image_url:imageUrl,image_number:image_number++})
        }
      }
      if(!this.state.mainImageUrl && mainImageUrl){
        console.log("updated:",images,mainImageUrl)
        this.setState({
          images,
          mainImageUrl,
          productNumber: images.length
        })
      }
    }
    // console.log(serverInfo.imageRegex.exec(this.props.mainImageUrl))
    // console.log(this.props.itemImages, this.props.mainImageUrl)
  }
  componentDidMount(){
      window.addEventListener('resize', this.windowResize.bind(this))
      if(this.firstUpdate){
        this.firstUpdate = false
        let images = [], mainImageUrl = null, image_number = 0
        if(this.props.mainImageUrl){
          const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
          const googleImageId = imageRegex.exec(this.props.mainImageUrl)?.[1]
          if(googleImageId){
            mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
          }
          images.push({image_id:googleImageId,image_url:mainImageUrl,image_number:image_number++})
        }
        if(this.props?.itemImages?.length &&  this.props?.itemImages?.length > 0){
          for(const image of this.props.itemImages){
            const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
            const googleImageId = imageRegex.exec(image.image_url)?.[1]
            let imageUrl = null
            if(googleImageId){
              imageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
            }
            images.push({image_id:image.image_id,image_url:imageUrl,image_number:image_number++})
          }
        }
        this.setState({
          images,
          mainImageUrl,
          productNumber: images.length,
        })
      }
      // console.log(serverInfo.imageRegex)
      // console.log(this.props.itemImages, this.props.mainImageUrl)
      
  }
  componentWillUnmount(){
      window.removeEventListener('resize', this.windowResize.bind(this))
  }
  // this.props.mainImageUrl?`${serverInfo.imageUrlPrefix}${this.imageRex.exec(this.props.mainImageUrl)[1]}${serverInfo.imageUrlSurfix}`:
  render() {
    return (
      <div className='imagegallery'>
        <button className='imagegallery-main'><img src={this.state.images?.[this.state.currentListNumber]?.image_url?this.state.images?.[this.state.currentListNumber]?.image_url:imageNotFound} alt='err'/></button>
        <div className='imagegallery-list'>
            <button className={this.state.currentListNumber>0 ? 'imagegallery-list-navi active':'imagegallery-list-navi'} onClick={()=>this.listingSlide(this.state.currentListNumber,true)}>{'<'}</button>
            <div className='imagegallery-imglist-main'>
              <div className='imagegallery-imglist' style={{marginLeft:`${this.state.listingBoxMargin}px`}}>
                  {
                    this.state.images.length > 0 ? this.state.images.map(image=>{
                      // console.log(image.image_url)
                      return(
                      <button key={`${image.image_id}-button`} className={image.image_number === this.state.currentListNumber?'imagegallery-list-img active':'imagegallery-list-img'} style={{width:`${this.state.productWidth}px`}} onClick={()=>this.imageClick(image.image_number,this.state.currentListNumber)}><img key={`${image.image_id}-img`} src={image.image_url} alt='err'/></button>
                      )}):""
                  }
              </div>
            </div>
            <button className={this.state.currentListNumber<this.state.productNumber - 1? 'imagegallery-list-navi active':'imagegallery-list-navi'} onClick={()=>this.listingSlide(this.state.currentListNumber,false)}>{'>'}</button>
        </div>
        {/* <div className='immagegallery-links'>
          <a href="#"><IoLogoFacebook/></a>
          <a href="#"><IoMdMail/></a>
          <a href="#"><IoMdPrint/></a>
          <a href="#"><IoLogoTwitter/></a>
          <a href="#"><IoLogoPinterest/></a>
        </div> */}
      </div>
    )
  }
}
