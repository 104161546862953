import React, { Component } from 'react'
import './index.css'
import logoURL from '../../../media/image/Logo.png'
import { Link } from 'react-router-dom'
export default class Logo extends Component {
  constructor(props){
    super(props)
  }
    render() {
        return (
            <div className="logo" style={{width:this.props.width? this.props.width:'calc((100% - 100px) / 4)'}}>
              <Link to={'/acer'}>
                <button>
                  <img  src={logoURL} alt="error"/>
                </button>
              </Link>
            </div>
        )
    }
}
