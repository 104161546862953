import React, { Component } from 'react'
import './index.css'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import PubSub from 'pubsub-js'

class WanrrantyPage extends Component {
  componentDidMount(){
    PubSub.publish('routes',[{pathname:'/acer/warranty',name:"Warranty",isCurrent:true}])
  }
  render() {
    return (
      <div className='warrantypage'>
        <PageRoute/>
        <div className='warrantypage-content'>
            <h1>Warranty</h1>
            <p>The product you have purchased from AcerRecertified.com or an Authorized Reseller in the United States is to be free from major defects in workmanship under normal use during the <b>Limited Warranty period of 90 days</b> with the exception of batteries, which have a warranty of one year. The Warranty period begins on the date of delivery. The Purchasers original purchase invoice {`(sales receipt)`}, showing the date of purchase of the product is proof of the purchase. The warranty extends only to the Purchaser of the product and is not transferable to anyone who subsequently obtains the Product from the Purchaser. <b>The Warranty does not extend to accessories or otherwise expendable parts.</b> During the Warranty period all necessary repairs to the product are made with factory refurbished parts. In the event that the Product exhibits a defect in workmanship within the warranty period, AcerRecertified.com will facilitate the Warranty services <b>for repair only</b>. All exchanged parts and products replaced under this Warranty will become property of AcerRecertified.com. Defective Products must be returned by the Purchaser to the Designated Authorized Facility for repair. This Limited Product Warranty does not provide repair services at any location other than the company’s authorized repair facility. Authorization must be obtained from a representative before the unit is shipped. The warranty will be voided if the unit was opened and tampered with in any way prior to being received by the repair facility.</p>
            <div className='warrantypaage-para'>
                <h4>New Open Box:</h4>
                <p>New Open Box items are a great way to save. Our New Open Box items are Brand New and offer a 1 Year Acer Factory warranty. On occasion, these items could have packaging that can show some wear and tear. Lets be honest, you are not buying the product because of the box. If you are not 100% satisfied with your purchase we offer a 14 day refund policy.</p>
            </div>
            <div className='warrantypaage-para'>
                <h4>Factory Recertified:</h4>
                <p>Factory Recertified products are another great way to save. These items are usually customer returns that have been fully refurbished directly by Acer to their factory standards. We stand behind each and every product we sell, providing our customers with a full 90 Day Warranty, Tech Support and Customer Service. Our Factory Recertified Products have been fully tested, reconditioned and are ready for you. If you are not 100% satisfied with your purchase we offer a 14 day refund policy.</p>
            </div>
            <div className='warrantypaage-para'>
                <h4>Scratch and Dent:</h4>
                <p>Purchasing Scratch and Dent products is how you can get the best value. But all Scratch and Dents are not created equal, which is why we put all products through a rigorous refurbishment and testing process. Our Scratch and Dent products are all fully functioning and come with a full 90 Day Warranty, Tech Support and Customer Service. These products may show some cosmetic damage, like scratches on the side panels or top covers. All product displays{`(example: Laptops, Monitors and All in Ones)`} are fully functioning, however they could have slight scratches and some possible dead pixels. Our goal is to offer you the best value on all our products, if you are not 100% satisfied with your purchase we offer a 14 day refund policy.</p>
            </div>
            <h2>Obtaining Warranty Repair Service</h2>
            <p>If the Purchaser is located in and also purchased Product that is covered by Warranty service in the United States {`(fifty (50) states and District of Columbia)`}, the Purchaser is entitled to Mail-In service during the Warranty period subject to the following terms and conditions:</p>
            <ul className='warrantypage-repair'>
                <li>{`Before calling Technical Support, please run the hardware diagnostics using the troubleshooting guide. This will help us provide you with better quality support. To access the guide:Control Panel>All Control Panel Items>Troubleshooting`}</li>
                <li>If you are unable to resolve your issue, you may contact us via the contact information at the bottom of this page.</li>
                <li>{`If a repair is necessary our Support team will advise you to complete a Return Merchandise Authorization (RMA request) and provide instruction on where to send your product for service. You must follow all RMA policies and procedures. You must prepay shipping charges, export taxes, customs duties, and taxes or any other charges associated with transportation of the Product. In addition, you are responsible for insuring any Product shipped or returned. You assume the risk of loss during shipment`}</li>
                <li>Use the original shipping and packing materials and include a description of the Product symptom. The RMA number must be noted on the exterior of the shipping container.</li>
                <li>You must provide valid proof of purchase. Service options are subject to change without notice.</li>
            </ul>
            <h2>Initial Setup</h2>
            <p>{`Before turning on your computer, plug the unit into a power outlet and make sure to connect all accessories. For laptops, install the battery (make sure the battery is properly aligned with the guides to avoid damage to the pins) and for desktops, connect the mouse, keyboard and monitor. Only press the power button once after all accessories are connected. Let the computer completely load to the Windows setup screen before turning off.`}</p>
            <p><b>USER MANUALS</b>: Manuals are located on the hard drive and can be accessed using the steps below:</p>
            <ul className='warrantypage-setup'>
                <li>{`Acer: Start>All Programs>Acer System>User Guide`}</li>
                <li>{`eMachine: Start>All Programs>eMachines>eMachine Documentation`}</li>
                <li>{`Gateway: Start>All Programs>Gateway Documentation`}</li>
            </ul>
            <p><b>RECOVERY DISC OR DRIVER/APPLICATION DISC</b>: To create a Recovery Disc or Driver and Application Disk, follow the steps below:</p>
            <ul className='warrantypage-setup'>
                <li>{'Acer: Start>All Programs>Acer>Acer Recovery Management'}</li>
                <li>{`eMachine: Start>All Programs>eMachines>eMachines Recovery Management`}</li>
                <li>{`Gateway: Start>All Programs>Gateway>Gateway Recovery Management`}</li>
            </ul>
            <p><b>TECH SUPPORT SITES</b>: Acer Product: <a href='http://support.acer.com/'>http://support.acer.com/</a> Gateway: <a href='http://support.gateway.com/support/default.aspx'>http://support.gateway.com/support/default.aspx</a></p>
            <p>For Additional Support: Email <a hefre="mailto: Service@AcerRecertified.com">Service@AcerRecertified.com</a></p>
            <p>{`Phone Support Toll Free: (844) 433-8476`}</p>
        </div>
      </div>
    )
  }
}
export default withRouter(WanrrantyPage)