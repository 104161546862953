import React, { Component } from 'react'
import './index.css'
import PageRoute from '../PageRoute'
import CartItem from './CartItem'
import { BsFillLightningChargeFill } from "react-icons/bs";
import withRouter from '../../withRouter';
import PubSub from 'pubsub-js';
import { FaAnglesLeft } from 'react-icons/fa6';
import userInfo from '../../userInfo';

class CartPage extends Component {
    constructor(props){
        super(props);
        this.state={
            currentTop:window.pageYOffset,
            cartItems:[],
            cartItemsNumber:0,
            cartItemsSubtotal:0,
            showShipping: false,
            showCoupon: false,
            isMobile:window.innerWidth<=800?true:false,
            isItemChanged:false, //boolean to define whether need to update the cart details on database
            inProcessing:false, // triggered when cart item is being changed
            cartItemsError:{}
        }
        this.freezeWindow = function(){
            window.scrollTo({top:this.state.menuTop,behavior:"auto"})
            console.log(`scroll freeze`)
        }
        this.freezeHandler = this.freezeWindow.bind(this)
        this.windowResize = function(){
            if(window.innerWidth<=800 && !this.state.isMobile){
                this.setState({isMobile:true})
            }
            if(window.innerWidth>800 && this.state.isMobile){
                this.setState({isMobile:false})
            }
        }
        this.windowResizeHandler = this.windowResize.bind(this)
    }
    showAndHideInfo(field,currentStatus){
        if(field==='shipping'){
            this.setState({showShipping:!currentStatus})
            console.log(field,this.state.showShipping)
        }
        if(field==='coupon'){
            this.setState({showCoupon:currentStatus?false:true})
        }
    }
    checkOut = () => {
        let isError = false
        let errorMessage = ''
        for(const itemId in this.state.cartItemsError){
            for(const condition in this.state.cartItemsError[itemId]){
                if(this.state.cartItemsError[itemId][condition]){
                    isError = true
                    errorMessage = `${errorMessage}${this.state.cartItemsError[itemId][condition]}\n`
                }
            }
        }
        if(isError){
            alert(errorMessage)
        }else{
            this.props.navigate('/acer/checkout',{state:{from:this.props.location.pathname,cartItemsNumber:this.state.cartItemsNumber,cartItemsSubtotal:this.state.cartItemsSubtotal}})
        }
    }
    componentDidMount(){
        window.addEventListener('resize',this.windowResizeHandler)
        window.addEventListener('resize',this.windowResizeHandler)
        this.cartItemsSub = PubSub.subscribe('cartItems',(_,data)=>{
            const cartItemsNumber = data?.reduce((total,current)=>total+current.quantity,0)
            const cartItemsSubtotal = data?.reduce((total,current)=>{
                if(current.item_condition === 'ScratchDent' && current.personal_scratch_dent_price && current.personal_scratch_dent_price !== 0){
                    return total+(current.personal_scratch_dent_price*current.quantity)
                }else{
                    if(current.is_sale && current.personal_sale_price && current.personal_sale_price !== 0){
                        return total+(current.personal_sale_price*current.quantity)
                    }else{
                        return total+(current.personal_price*current.quantity)
                    }
                }
            },0)
            this.setState({cartItems:[...data],cartItemsNumber,cartItemsSubtotal})
        })
        this.cartItemsErrorSub = PubSub.subscribe('cartItemsError',(_,data)=>this.setState({cartItemsError:{...data}}))

        if(userInfo?.cartItems && userInfo?.cartItems?.length>0){
            // console.log('didmount', userInfo.cartItems)
            const cartItemsError = {}
            for(const cartItem of userInfo?.cartItems){
                // console.log(cartItem.stock, cartItem.quantity)
                if(cartItem.stock < cartItem.quantity){
                    console.log(`${cartItem.item_id}: ordered number ${cartItem.quantity} exceeds current stock ${cartItem.stock}!`)
                    if(!cartItemsError[cartItem.item_id]){
                        cartItemsError[cartItem.item_id] = {}
                    }
                    cartItemsError[cartItem.item_id][cartItem.item_condition] = `${cartItem.item_name} ordered quantity (${cartItem.quantity}) exceeds current available stock (${cartItem.stock}).`
                }
            }
            const cartItemsNumber = userInfo?.cartItems?.reduce((total,current)=>total+current.quantity,0)
            const cartItemsSubtotal = userInfo?.cartItems?.reduce((total,current)=>{
                if(current.item_condition === 'ScratchDent' && current.personal_scratch_dent_price && current.personal_scratch_dent_price !== 0){
                    return total+(current.personal_scratch_dent_price*current.quantity)
                }else{
                    if(current.is_sale && current.personal_sale_price && current.personal_sale_price !== 0){
                        return total+(current.personal_sale_price*current.quantity)
                    }else{
                        return total+(current.personal_price*current.quantity)
                    }
                }
            },0)
            this.setState({cartItems:[...userInfo?.cartItems],cartItemsNumber,cartItemsSubtotal,cartItemsError})
        }
        this.inProcessingSub = PubSub.subscribe('inProcessing',(_,data)=>{this.setState({inProcessing:data});console.log('inprocessing',data)})
        PubSub.publish('routes',[{pathname:'/acer/cart',name:"Your Cart",isCurrent:true}])
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.windowResizeHandler)
        window.removeEventListener('scroll',this.freezeHandler)
        PubSub.unsubscribe(this.cartItemsSub)
        PubSub.unsubscribe(this.cartItemsErrorSub)
        PubSub.unsubscribe(this.inProcessingSub)
    }
  render() {
    // console.log(this.state.cartItems)
    return (
      <div className='cartpage'>
        <PageRoute/>
        <div className='cartpage-content'>
            <h1>Your Cart {this.state.cartItemsNumber > 1 ?`(${this.state.cartItemsNumber} items)`:`(${this.state.cartItemsNumber} item)`}</h1>
            <p className="cartpage-empty" style={{display:this.state.cartItemsNumber === 0 ?"flex":"none"}}> your cart is empty</p>
            <div className='cartpage-main' style={{display:this.state.cartItemsNumber === 0?"none":"flex"} }>
                <div className='cartpage-list'>
                    {this.state.cartItems.map(item=>(<CartItem key={`${item.item_id}-${item.item_condition}`} item={item} cartItems={this.state.cartItems} cartItemsError={this.state.cartItemsError}/>))}
                </div>
                <div className='cartpage-checkout'>
                    <button className={this.state.inProcessing?'cartpage-checkout-button disabled':'cartpage-checkout-button'} disabled={this.state.inProcessing} style={{display:this.state.isMobile?"none":"flex",marginBottom:"20px"}} onClick={()=>this.checkOut()}>
                        <BsFillLightningChargeFill/> CHECKOUT
                    </button>
                    <div className='cartpage-pricelist'>
                        <div className='cartpage-pricelist-subtotal'>
                            <p>Subtotal:</p>
                            <p>${this.state.cartItemsSubtotal?.toFixed(2)}</p>
                        </div>
                        <div className='cartpage-pricelist-shipping' style={{display:"none"}}>
                            <div>
                                <p>Estimate Shipping</p>
                                <button onClick={()=>this.showAndHideInfo('shipping',this.state.showShipping)}>{this.state.showShipping?'Cancel':'Add Info'}</button>
                            </div>
                            <div className='cartpage-pricelist-shipping-address' style={{display:this.state.showShipping?'flex':'none'}}>
                                <div>
                                    <p>Country</p>
                                    <select>
                                        <option>Country</option>
                                        <option>Canada</option>
                                    </select>
                                </div>
                                <div>
                                    <p>State/province</p>
                                    <select>
                                        <option>State/province</option>
                                        <option>Ontario</option>
                                        <option>Quebec</option>
                                        <option>Nova Scotia</option>
                                        <option>New Brunswick</option>
                                        <option>Manitoba</option>
                                        <option>British Columbia</option>
                                        <option>Prince Edward Island</option>
                                        <option>Saskatchewan</option>
                                        <option>Alberta</option>
                                        <option>Newfoundland</option>
                                        <option>Northwest Territories</option>
                                        <option>Yukon</option>
                                        <option>Nunavut</option>
                                    </select>
                                </div>
                                <div>
                                    <p>Suburb/city</p>
                                    <input type='text' placeholder='Suburb/city'/>
                                </div>
                                <div>
                                    <p>Zip/postcode</p>
                                    <input type='text' placeholder='Zip/postcode'/>
                                </div>
                                <button>ESTIMATE SHIPPING</button>
                                <p></p>
                            </div>
                            
                        </div>
                        <div className='cartpage-pricelist-coupon' style={{display:"none"}}>
                                <div>
                                    <p>Have a coupon code?</p>
                                    <button onClick={()=>this.showAndHideInfo('coupon',this.state.showCoupon)}>{this.state.showCoupon?'Cancel':'Use it'}</button>
                                </div>
                                <div style={{display:this.state.showCoupon?'flex':'none'}}>
                                    <input type='text' placeholder='Enter your coupon code'/>
                                    <button>APPLY</button>
                                </div>
                        </div>
                        <div className='cartpage-pricelist-total'>
                            <h2>Grand Total:</h2>
                            <h2>${this.state.cartItemsSubtotal?.toFixed(2)}</h2>
                        </div>
                    </div>
                    <button className={this.state.inProcessing?'cartpage-checkout-button disabled':'cartpage-checkout-button'} disabled={this.state.inProcessing} style={{display:this.state.isMobile?"flex":"none",marginTop:"20px"}} onClick={()=>this.checkOut()}><BsFillLightningChargeFill/> CHECKOUT</button>
                </div>
            </div>
        </div>
        {/* <div className='cartItem-deletion'>
            <div>
                <p></p>
                <p></p>
                <div>
                    <button></button>
                    <button></button>
                </div>
            </div>
        </div> */}
      </div>
    )
  }
}

export default withRouter(CartPage)