import React, { Component } from 'react'
import './index.css'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import makeItEasyUrl from '../../media/image/we-make-it-easy.webp'
import stepOneUrl from '../../media/image/step1-v3.webp'
import stepTwoUrl from '../../media/image/step2-v3.webp'
import stepThreeUrl from '../../media/image/step3-v3.webp'
import stepFourUrl from '../../media/image/step4-v3.webp'
import tabletUrl from '../../media/image/tablet-serial.jpg'
import monitorUrl from '../../media/image/monitor-serial.jpg'
import phoneOneUrl from '../../media/image/smartphone-serial.webp'
import phoneTwoUrl from '../../media/image/smartphone-serial-2.png'
import phoneThreeUrl from '../../media/image/smartphone-serial-3.webp'
import projectorUrl from '../../media/image/projector-serial.webp'
import { Link } from 'react-router-dom'
import PubSub from 'pubsub-js'
class ExWarrantyPage extends Component {
    constructor(props){
        super(props)
        this.state={
            session:this.props.params.session?this.props.params.session:"main"
        }
    }
    componentDidUpdate(){
        if(this.state.session !== this.props.params.session){
            this.setState({session:this.props.params.session})
        }
        window.scrollTo({top:"0",behavior:"auto"})
    }
    componentDidMount(){
        window.scrollTo({top:"0",behavior:"auto"})
        PubSub.publish('routes',[{pathname:'/acer/extended-warranty',name:'Extended Warranty',isCurrent:true}])
    }
  render() {
    return (
      <div className='exwarranty'>
        <PageRoute/>
        <div className='exwarranty-content'>
            <div className='exwarranty-main' style={{display:this.state.session==="main"?"flex":"none"}}>
                <h1>Extended Warranty</h1>
                <h4>Provided by Protect Options</h4>
                <p>{`At Protect Options, we know that accidents happen during normal usage and that regular use can cause wear and tear on your devices. Our customers are important to us, and we want to provide the best support possible to their purchased products. That’s why we created a set of Terms and Conditions that goes above and beyond the industry standard. The Protect Options Warranty allows you to worry about living your life, while we worry about everyday accidents and wear and tear. No fees, no gimmicks. Just simple Terms and Conditions.`}</p>
                <p>Sound too good to be true? The Protect Options Warranty even protects against accidental drops, spills, and electrical surges, types of accidental damage that standard manufacturers warranties don’t cover. With your Protect Options Warranty, you’re covered against normal everyday use such as mechanical failures, hard drive crash, loose charging ports, RAM failures and more.  <b>The Protect Options Warranty must be purchased within 30 days of the original order date</b>.</p>
                <ul className='exwarranty-main-links'>
                    <li><a href='#frequently-asked-questions'>Frequently Asked Questions</a></li>
                    <li><Link to={{pathname:'/acer/extended-warranty/what-covered',state:{from:this.props.location.pathname}}}>What's Covered</Link></li>
                    <li><Link to={{pathname:'/acer/extended-warranty/how-to-claim',state:{from:this.props.location.pathname}}}>How to File a Claim</Link></li>
                    <li><Link to={{pathname:'/acer/extended-warranty/locate-serial-number',state:{from:this.props.location.pathname}}}>Locating Your Product Serial Number</Link></li>
                    <li><Link to={{pathname:'/acer/extended-warranty/submit-warranty-claim',state:{from:this.props.location.pathname}}}>Submit a Warranty Claim</Link></li>
                    <li><Link to={{pathname:'/acer/extended-warranty/terms-and-conditions',state:{from:this.props.location.pathname}}}>Terms and Conditions</Link></li>
                </ul>
                <img src={makeItEasyUrl} alt='err'/>
                <h2 id='frequently-asked-questions'>Frequently Asked Questions</h2>
                <p>What is covered under my warranty plan?</p>
                <p>Typically, accidental drops, spills, bumps, and structural failures incurred under normal operating conditions or handling, including electrical surges and damage to the integrated LCD screen.</p>
                <ul className='exwarranty-main-frequent'>
                    <li><p>Everyday Product Coverage</p><p>Everyday Product Coverage Details</p></li>
                    <li><p>All Mechanical or Electrical Failures</p><p>Product won’t start, or simply “won’t work?” We have you covered.</p></li>
                    <li><p>Accidental Damage</p><p>Drops and Spills? Covered.</p></li>
                    <li><p>Display Failure</p><p>Display failure or flaws? Covered!</p></li>
                    <li><p>Antenna / Wi-Fi Failure</p><p>Product won’t connect to Wi-Fi? We have you covered.</p></li>
                    <li><p>Broken Dock Connector Port</p><p>Jiggling your power cord or holding it just to get a little charge? Covered!</p></li>
                    <li><p>Touchscreen Failure</p><p>Touchscreen not functioning or responding to your touch? Covered!</p></li>
                    <li><p>{`Won’t Power On`}</p><p>Product won’t start. We have you covered!</p></li>
                    <li><p>Hard Drive Crash</p><p>Hard drive crash or will not "boot" up? No worries, it’s covered!</p></li>
                    <li><p>Wi-Fi Board Failure</p><p>Your device won’t connect to Wi-Fi? We’ve got you covered.</p></li>
                    <li><p>Loose Charging Port</p><p>Jiggling your power cord or holding it just to get a little charge? Covered.</p></li>
                    <li><p>RAM Failures or Errors</p><p>RAM Failure or errors causing blue/black screens to appear? Covered.</p></li>
                    <li><p>LCD Burn Out</p><p>Is the computer screen fading? Covered.</p></li>
                    <li><p>Speaker / Sound Failures</p><p>Speaker not functioning? Low audio or no audio? Covered.</p></li>
                    <li><p>DVD/DVDR/CDR Failures</p><p>DVD/CDR not functioning? Covered.</p></li>
                    <li><p>Keyboard/Mouse or Glide pad/TrackPoint Failure</p><p>Input device like keyboard or mouse not functional? Covered!</p></li>
                    <li><p>{`External ports (Audio Out, USB, etc.)`}</p><p>Other ports not working? Yep, covered.</p></li>
                </ul>
                <p>How do I register my coverage?</p>
                <p>If you purchase your warranty with your product, you are already registered! No extra work or hoops to jump through. Please look out for your confirmation email, and please contact us if you have not received it within 48 hours of purchasing your unit.</p>
                <p>When does my coverage begin?</p>
                <p>Your warranty coverage begins from the moment your product is delivered.</p>
                <p>What happens if my Protect Options product breaks and is covered by the warranty?</p>
                <p>Depending on your product and the failure circumstances, we will either repair your product or provide you with a replacement product of equal or better features and functionality.</p>
                <p>My product broke, what do I do now?</p>
                <p>You can fill out a <Link>Claims Form</Link> online and a Customer Service Representative will contact you, or contact a Customer Service Representative and they will help you submit a claim and get the product shipped.</p>
                <p>Who pays for shipping?</p>
                <p>The customer is responsible for shipping the product to the repair facility. Should the product be repaired, Protect Options will cover any costs of shipping the product back.</p>
                <p>How can I find my serial number?</p>
                <p>Visit the <Link>Locating Your Product Serial Number</Link> page for instructions on locating the serial number of your product.</p>
                <h2>Need to file a warranty claim?</h2>
                <h4>Follow these 4 easy steps!</h4>
                <img src={stepOneUrl} alt='err'/>
                <p>To obtain warranty service, please contact our Customer Service Representatives {`(“CSR”)`} by filling out a <Link>Claim Form</Link>, by email at <Link>service@acerrecertified.com</Link>, or by phone at <Link>1-844-433-8476</Link>, Monday to Friday, 8:00 am to 5:00 pm EST, excluding holidays. Our CSRs are very knowledgeable and often can resolve many hardware problems remotely by email or phone, without the need to ship your product to us!</p>
                <p>Please be sure to provide (or have available) the following information when contacting our CSRs:</p>
                <ul className='exwarranty-main-csr'>
                    <li>Name</li>
                    <li>Daytime Phone Number</li>
                    <li>Email Address</li>
                    <li>Product Serial Number</li>
                    <li>{`Mailing Address (so we can return your product)`}</li>
                </ul>
                <img src={stepTwoUrl} alt='err'/>
                <p>Please do not return any product without first obtaining a RMA, as we may not be able to associate it with you and it could be lost or misplaced. If for any reason a product is returned without a valid RMA number, Protect Options will do its best to provide you with service, but cannot be responsible for your product if it is lost or misplaced.</p>
                <img src={stepThreeUrl} alt='err'/>
                <p>Our CSRs will contact you with shipping instructions. You will be responsible for safe packaging and shipment, and you assume all risk of damage to your product during shipping. Please make sure you insure your product appropriately.</p>
                <p>PLEASE BE SURE THE RMA NUMBER IS CLEARLY PRINTED ON THE BOX!</p>
                <img src={stepFourUrl} alt='err'/>
                <p>{`Once your warranty request has been received and processed, you will receive a confirmation email from us and a Protect Options technician will evaluate your product. Please note that we generally provide confirmation within 2-3 business days. However, during periods of peak demand it may take up to 10 business days for us to provide you with a response. Once we have processed your product, repair “turnaround” time averages 5 business days but may take longer depending on your issue. We only provide our customers with the highest quality customer service – sometimes that takes time!`}</p>
            </div>
            <div className='exwarranty-covered' style={{display:this.state.session==="what-covered"?"flex":"none"}}>
                <h1>What's Covered</h1>
                <p>Typically, accidental drops, spills, bumps, and structural failures incurred under normal operating conditions or handling, including electrical surges and damage to the integrated LCD screen.</p>
                <ul className='exwarranty-main-frequent'>
                    <li><p>Everyday Product Coverage</p><p>Everyday Product Coverage Details</p></li>
                    <li><p>All Mechanical or Electrical Failures</p><p>Product won’t start, or simply “won’t work?” We have you covered.</p></li>
                    <li><p>Accidental Damage</p><p>Drops and Spills? Covered.</p></li>
                    <li><p>Display Failure</p><p>Display failure or flaws? Covered!</p></li>
                    <li><p>Antenna / Wi-Fi Failure</p><p>Product won’t connect to Wi-Fi? We have you covered.</p></li>
                    <li><p>Broken Dock Connector Port</p><p>Jiggling your power cord or holding it just to get a little charge? Covered!</p></li>
                    <li><p>Touchscreen Failure</p><p>Touchscreen not functioning or responding to your touch? Covered!</p></li>
                    <li><p>{`Won’t Power On`}</p><p>Product won’t start. We have you covered!</p></li>
                    <li><p>Hard Drive Crash</p><p>Hard drive crash or will not "boot" up? No worries, it’s covered!</p></li>
                    <li><p>Wi-Fi Board Failure</p><p>Your device won’t connect to Wi-Fi? We’ve got you covered.</p></li>
                    <li><p>Loose Charging Port</p><p>Jiggling your power cord or holding it just to get a little charge? Covered.</p></li>
                    <li><p>RAM Failures or Errors</p><p>RAM Failure or errors causing blue/black screens to appear? Covered.</p></li>
                    <li><p>LCD Burn Out</p><p>Is the computer screen fading? Covered.</p></li>
                    <li><p>Speaker / Sound Failures</p><p>Speaker not functioning? Low audio or no audio? Covered.</p></li>
                    <li><p>DVD/DVDR/CDR Failures</p><p>DVD/CDR not functioning? Covered.</p></li>
                    <li><p>Keyboard/Mouse or Glide pad/TrackPoint Failure</p><p>Input device like keyboard or mouse not functional? Covered!</p></li>
                    <li><p>{`External ports (Audio Out, USB, etc.)`}</p><p>Other ports not working? Yep, covered.</p></li>
                </ul>
            </div>
            <div className='exwarranty-fill-claim' style={{display:this.state.session==="how-to-claim"?"flex":"none"}}>
                <h1>How to File a Claim</h1>
                <h2>Need to file a warranty claim? Follow these 4 easy steps!</h2>
                <img src={stepOneUrl} alt='err'/>
                <p>To obtain warranty service, please contact our Customer Service Representatives {`(“CSR”)`} by filling out a <Link>Claim Form</Link>, by email at <Link>service@acerrecertified.com</Link>, or by phone at <Link>1-844-433-8476</Link>, Monday to Friday, 8:00 am to 5:00 pm EST, excluding holidays. Our CSRs are very knowledgeable and often can resolve many hardware problems remotely by email or phone, without the need to ship your product to us!</p>
                <p>Please be sure to provide (or have available) the following information when contacting our CSRs:</p>
                <ul className='exwarranty-main-csr'>
                    <li>Name</li>
                    <li>Daytime Phone Number</li>
                    <li>Email Address</li>
                    <li>Product Serial Number</li>
                    <li>{`Mailing Address (so we can return your product)`}</li>
                </ul>
                <img src={stepTwoUrl} alt='err'/>
                <p>Please do not return any product without first obtaining a RMA, as we may not be able to associate it with you and it could be lost or misplaced. If for any reason a product is returned without a valid RMA number, Protect Options will do its best to provide you with service, but cannot be responsible for your product if it is lost or misplaced.</p>
                <img src={stepThreeUrl} alt='err'/>
                <p>Our CSRs will contact you with shipping instructions. You will be responsible for safe packaging and shipment, and you assume all risk of damage to your product during shipping. Please make sure you insure your product appropriately.</p>
                <p>PLEASE BE SURE THE RMA NUMBER IS CLEARLY PRINTED ON THE BOX!</p>
                <img src={stepFourUrl} alt='err'/>
                <p>{`Once your warranty request has been received and processed, you will receive a confirmation email from us and a Protect Options technician will evaluate your product. Please note that we generally provide confirmation within 2-3 business days. However, during periods of peak demand it may take up to 10 business days for us to provide you with a response. Once we have processed your product, repair “turnaround” time averages 5 business days but may take longer depending on your issue. We only provide our customers with the highest quality customer service – sometimes that takes time!`}</p>
            </div>
            <div className='exwarranty-serial' style={{display:this.state.session==="locate-serial-number"?"flex":"none"}}>
                <h1>Locating Your Serial Number</h1>
                <iframe width={'440px'} height={'300px'} src="//www.youtube.com/embed/xbz_K59601A?fs=1" frameBorder={"0"} title='locate-serial-number' allowFullScreen></iframe>
                <p>Select your product type below for information on how to retrieve the serial number or SNID code of your system. If you have a Windows product that will power on, you can download the <a href="https://global-download.acer.com/SupportFiles/Files/SNID/APP/SerialNumberDetectionTool.exe" target="_blank" rel="noreferrer">Acer System Information Tool</a> to retrieve the serial number or SNID.</p>
                <h2>Laptops</h2>
                <p>{`Your SNID and serial number are located on a label on the bottom of your product. These numbers can be used online to help locate downloads and other resources for your product. They also help us correctly identify your product specifications and warranty, so please have them at hand when you contact us.`}</p>
                <p>Acer SNID numbers are 11 or 12 digits and do not contain letters. Acer serial numbers are 22 alpha-numeric characters, and will always use zero and never the letter O.</p>
                <p>Note: Acer 2-in-1 Switch products are labeled on the bottom edge of the tablet.</p>
                <iframe src="https://www.youtube.com/embed/xbz_K59601A" width="320" height="240" frameBorder="0" title='locate-laptops-serial-number'></iframe>
                <p>Your serial number is also available in your system BIOS, and accessible via the Acer software programs listed below:</p>
                <p>{`Serial Number Detection Tool (All Windows Versions):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Download and run the Serial Number Detection tool.</li>
                    <li>Both serial number and SNID will be displayed.</li>
                </ul>
                <p>{`Acer Care Center (Windows 8 and later):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Press the Windows key</li>
                    <li>Type care center and press Enter.</li>
                    <li>Both serial number and SNID will be displayed in the application.</li>
                </ul>
                <p>{`Acer Identity Card (Windows 7):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Double-click the Acer Identity Card icon available on the desktop.</li>
                    <li>Both Serial number and SNID code will be displayed.</li>
                </ul>
                <h2>Desktops</h2>
                <p>Your SNID and serial number are located on a label on the side of your product. These numbers can be used online to help locate downloads and other resources for your product. They also help us correctly identify your product specifications and warranty, so please have them at hand when you contact us.</p>
                <p>Acer SNID numbers are 11 or 12 digits and do not contain letters. Acer serial numbers are 22 alpha-numeric characters, and will always use zero and never the letter O.</p>
                <p>Note: Acer 2-in-1 Switch products are labeled on the bottom edge of the tablet.</p>
                <iframe src="https://www.youtube.com/embed/9NGOm9P8fCY" width="320" height="240" frameBorder="0" title='locate-desktops-serial-number'></iframe>
                <p>Your serial number is also available in your system BIOS, and accessible via the Acer software programs listed below:</p>
                <p>{`Serial Number Detection Tool (All Windows Versions):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Download and run the Serial Number Detection tool.</li>
                    <li>Both Serial number and SNID code will be displayed.</li>
                </ul>
                <p>{`Acer Care Center (Windows 8 and later):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Press the Windows key</li>
                    <li>Type care center and press Enter.</li>
                    <li>Both serial number and SNID will be displayed in the application.</li>
                </ul>
                <p>{`Acer Identity Card (Windows 7):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Double-click the Acer Identity Card icon available on the desktop.</li>
                    <li>Both Serial number and SNID code will be displayed.</li>
                </ul>
                <h2>Tablets</h2>
                <p>Most Acer tablets will include a white sticker on the back of the tablet that contains an SNID or Serial number. You can see an example in the image below.</p>
                <p>Note: Acer SNID and serial numbers will not use the letter "O", they will only use the number "0".</p>
                <img src={tabletUrl} alt='err'/>
                <p>{`Older tablets may have the SNID or serial number label on the underside of the MicroSD/SIM card cover. If you are unable to locate a physical serial number label, you can find this information in the tablet operating system. Use the following steps to to retrieve the SNID or serial number within the operating system:`}</p>
                <p>Windows:</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Use <a href="https://www.acer.com/ac/en/US/content/software-acer-care-center" target="_blank" rel="noreferrer">Acer Care Center</a> to locate your serial number.</li>
                </ul>
                <p>Android:</p>
                <ul className='exwarranty-serial-cat'>
                    <li>From the Home Screen, tap on Apps, and then Settings.</li>
                    <li>Tap on About Tablet, and then Device Information.</li>
                    <li>Both serial number and SNID will be displayed in the application.</li>
                </ul>
                <p>{`Acer Identity Card (Windows 7):`}</p>
                <ul className='exwarranty-serial-cat'>
                    <li>Double-click the Acer Identity Card icon available on the desktop.</li>
                    <li>Both Serial number and SNID code will be displayed.</li>
                </ul>
                <h2>Monitors</h2>
                <p>Your SNID and serial number are located on a label on the back of your monitor. These numbers can be used online to help locate downloads and other resources for your product. They also help us correctly identify your product specifications and warranty, so please have them at hand when you contact us.</p>
                <p>Acer SNID numbers are 11 or 12 digits and do not contain letters. Acer serial numbers are 22 alpha-numeric characters, and will always use zero and never the letter O.</p>
                <img src={monitorUrl} alt='err'/>
                <h2>Smartphones</h2>
                <p>The serial number and/or the SNID code is available on a printed label on the back of your smartphone or underneath the battery cover and battery.</p>
                <p>The serial number and SNID are also located on the product packaging if you have it available. See the images below for possible locations of the serial number on your product.</p>
                <div style={{display:"flex",width:"100%"}}>
                    <img src={phoneOneUrl} alt='err'/>
                    <img src={phoneTwoUrl} alt='err'/>
                </div>
                <img src={phoneThreeUrl} alt='err'/>
                <h2>Projectors</h2>
                <p>The SNID and serial number are located on a label on the bottom of your projector. These numbers can be used online to help locate downloads and other resources for your product. They also help us correctly identify your product specifications and warranty, so please have them at hand when you contact us.</p>
                <p>Acer SNID numbers are 11 or 12 digits and do not contain letters. Acer serial numbers are 22 alpha-numeric characters, and will always use zero and never the letter O.</p>
                <p>Note:On Acer Pico projectors, the serial number may be located beneath the battery lid.</p>
                <img src={projectorUrl} alt='err'/>
            </div>
            <div className='exwarranty-terms' style={{display:this.state.session==="terms-and-conditions"?"flex":"none"}}>
                <h1>Terms and Conditions</h1>
                <p>Protect Options will repair the product purchased under this warranty with new, used or rebuilt parts, free of charge in the U.S.A. for the period of time specified on the warranty purchased from the date of original purchase in the event of a defect in materials or workmanship. This warranty is extended solely to the original purchaser. A purchase receipt or other proof of date of original purchase will be required before warranty performance is rendered.</p>
                <p>This warranty is void if the product is:</p>
                <ul className='exwarranty-terms-ul'>
                    <li>Damaged through negligence, misuse, abuse, or accident.</li>
                    <li>Modified or repaired by anyone other than Protect Options authorized service center personnel.</li>
                    <li>Damaged due to connection to the equipment of other manufacturers or improper power sources.</li>
                </ul>
                <p>Specifically excluded from the warranty are:</p>
                <ul className='exwarranty-terms-ul'>
                    <li>{`Damage to the system due to parts of other manufacturers added to or connected to the product.`}</li>
                    <li>{`Damage or improper operation of the product caused by customer abuse, negligence, or failure to follow operating instructions provided with the product.`}</li>
                    <li>{`All consumable items; such as batteries, ac adapters, power cords, screen protection films, logo badges, labels, cleaning cloths, carry cases, manuals, cables, straps, belts, holsters, tethers, and harnesses and any other options and accessories not listed above or covered under a separate warranty.`}</li>
                    <li>{`Failures related to the product operating system, hard drive image, software setup, software program, virus, other program(s) or file(s) on the hard drive or in any computer memory location.`}</li>
                    <li>{`Failures due to BIOS settings or changes, as well as any cosmetic or physical damage to the unit.`}</li>
                    <li>{`Failures which result from alteration, accident, misuse, introduction of liquid or other foreign matter into the unit, abuse, neglect, installation, maladjustment of consumer controls, improper maintenance or modification, not in accordance with product use instructions.`}</li>
                    <li>{`Any unit or device with a missing or altered model number or serial number label.`}</li>
                    <li>{`Any missing doors or port covers.`}</li>
                    <li>{`Damage which occurs in shipment.`}</li>
                    <li>{`Any costs incurred in shipping the product to Protect Options.`}</li>
                    <li>{`Any cosmetic damage.`}</li>
                    <li>{`Third Party Hardware or software purchased at the time of original sale and shipped with the Notebook will retain the warranty of the original manufacturer and not be increased nor decreased in warranty period length by this document.`}</li>
                    <li>{`Damage that is attributable to acts of God or Nature.`}</li>
                </ul>
                <p>THIS WARRANTY APPLIES ONLY TO THE ORIGINAL PURCHASER AND IS NONTRANSFERRABLE.</p>
                <p>{`PROTECT OPTIONS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND (OTHER THAN THE EXPRESS WARRANTY STATED HEREIN). WHETHER EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.`}</p>
                <p>{`Under no circumstances shall Protect Options be liable for any loss, direct or indirect, incidental, special, or consequential damage arising out of or in connection with the use of this system. This warranty gives you specific legal rights. However, you may have other rights which may vary from state to state.`}</p>
                <h4>Protect Options Warranty Coverage - Overview</h4>
                <p>{`Your Protect Options Warranty will cover a mechanical or electrical failure of your Product during normal usage, as well as any other defects in materials and workmanship. Depending on the Product and failure circumstances, if your Product is covered by the Protect Options Warranty, we will either repair your Product or provide you with a replacement Product of equal or better features and functionality. The Protect Options Warranty does not cover repair or replacement of the Product for normal wear and tear, accidental or intentional damage (such as damage from drops and liquid damage or abuse), theft, loss, cosmetic damage and/or other damage that does not affect unit functionality. However, we will repair such damage for an additional fee, if we can. The Protect Options Warranty also does not cover software issues or accessories and parts that are meant to be replaced by the consumer, such as external batteries and ink cartridges, but does cover internal, non-removable batteries, such as iPod batteries. We will assist you to determine whether your issue is covered by the Protect Options Warranty. If we determine that your Product is in working condition or is not covered by the Protect Options Warranty, we will return the item to you or dispose of it at your request.`}</p>
                <h4>Protect Options Warranty Coverage</h4>
                <p>{`Your warranty coverage starts from the moment your product is delivered. Protect Options warrants to you that your Products will be free from defects in material and workmanship. Protect Options also warrants to you that it will convey good title to the Product sold hereunder. If your Product requires service under the Protect Options Warranty, depending on your product and the failure circumstances, Protect Options will either repair your Product or provide you with a replacement Product of equal or better features and functionality.`}</p>
                <p>{`EXCEPT FOR THE LIMITED WARRANTY PROVIDED HEREIN, TO THE EXTENT PERMITTED BY LAW, PROTECT OPTIONS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED.`}</p>
                <h4>Limitation of Liability</h4>
                <p>{`WE SHALL NOT BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL OR INDIRECT DAMAGES, INCLUDING BUT NOT LIMITED TO, PROPERTY DAMAGE, LOST TIME, LOST PROFITS OR LOST DATA RESULTING FROM THE FAILURE OF ANY PRODUCT OR EQUIPMENT OR FROM DELAYS IN SERVICE OR THE INABILITY TO RENDER SERVICE TO YOUR PRODUCT.`}</p>
            </div>
        </div>
      </div>
    )
  }
}
export default withRouter(ExWarrantyPage)

