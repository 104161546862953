import React, { Component } from 'react'
import './index.css'
import PubSub from 'pubsub-js';
// import { MdPublish } from 'react-icons/md';
export default class DoubleSlider extends Component {
  maxRef = React.createRef()
  minRef = React.createRef()
  state = {
      sliderWidth: 0,
      offsetSliderWidth: 0,
      min: 0,
      max: 0,
      minValueBetween: 10,
      
      currentMin: 0,
      inputMin: 0,
      
      currentMax: 0,
      inputMax: 0
    };
  
  componentDidMount() {
    // console.log(this.props)
    const currentMin  = 0
    const max = this.props.priceRange?.maxPrice ? Math.ceil(this.props.priceRange.maxPrice) : this.state.max
    const currentMax = max

    // this.minValue.style.width = (currentMin*100)/max + "%";
    // this.maxValue.style.width = (currentMax*100)/max + "%";
    this.minValue.style.width = (currentMin*100)/max + "%"
    this.maxValue.style.width = '100%'
    this.setState({
      currentMin,
      inputMin:currentMin,
      currentMax,
      inputMax:currentMax,
      max,
      sliderWidth: this.slider.offsetWidth,
      offsetSliderWidth: this.slider.offsetLeft,
    })

    this.priceRange = PubSub.subscribe('priceRange',(_,data)=>{
      // console.log('doubleslide',data)
      if(this.state.currentMin === this.state.min && this.state.currentMax === this.state.max){
        const currentMin = 0
        const currentMax = Math.ceil(data.maxPrice), max = Math.ceil(data.maxPrice)
        this.minValue.style.width = "0%"
        this.maxValue.style.width = '100%'
        this.setState({
          currentMin,
          inputMin:currentMin,
          currentMax,
          inputMax:currentMax,
          max,
          sliderWidth: this.slider.offsetWidth,
          offsetSliderWidth: this.slider.offsetLeft,
        })
      }else{
        if(Math.ceil(data.maxPrice) > this.state.currentMax){
          const max = Math.ceil(data.maxPrice)
          this.maxValue.style.width = (currentMax*100)/max + "%"
          this.setState({
            max
          })
        }
      }
    })
    
  }

  componentWillUnmount(){
    PubSub.unsubscribe(this.priceRange)
  }
  priceRangeUpdate = () => {
    // console.log(this.state.currentMin, this.state.currentMax)
    PubSub.publish('priceFilter',{minPrice:this.state.currentMin,maxPrice:this.state.currentMax})
    window.scrollTo({top:0,behavior:"auto"})
  }
  setMin = (e) => {
    const { min, max, currentMax, currentMin, minValueBetween } = this.state;
    const inputMin = e.target.value;
    
    this.setState({
        inputMin
    });
    
    if((inputMin >= min) && (inputMin <= (currentMax-minValueBetween))){
      this.setState({
        currentMin: Number.isNaN(parseInt(inputMin))? currentMin : parseInt(inputMin)
      }); 

      this.minValue.style.width = (inputMin*100)/max + "%";
    }
  }
  inputMinBlur = (e) =>{
    const { min, max, currentMax, currentMin, minValueBetween } = this.state
    let inputMin = Number.isNaN(parseInt(e.target.value)) ? currentMin : parseInt(e.target.value)
    if(inputMin < min){
      inputMin = min
    }else if(inputMin > currentMax-minValueBetween){
      inputMin = currentMax-minValueBetween
    }

    this.setState({
      inputMin,
      currentMin: inputMin
    }); 
    this.minValue.style.width = (inputMin*100)/max + "%";
  }
  changeMinValue = (e) => {
    // e.preventDefault();

    document.addEventListener('mousemove', this.onMouseMoveMin);
    document.addEventListener('mouseup', this.onMouseUpMin);

    // document.addEventListener('touchstart', (e)=>{e.preventDefault()});
    document.addEventListener('touchmove', this.onMouseMoveMin);
    document.addEventListener('touchend', this.onMouseUpMin);
  }

  onMouseMoveMin = (e) => {
    console.log(e.clientX)
    const { min, max, currentMax, minValueBetween, sliderWidth, offsetSliderWidth } = this.state;
    
    const dragedWidht = e.clientX - offsetSliderWidth;
    const dragedWidhtInPercent = (dragedWidht*100)/sliderWidth;
    const currentMin = parseInt((max * dragedWidhtInPercent)/100);
    
    // console.log(e.pageX, e.clientX, offsetSliderWidth);
    
    // console.log(currentMin , (currentMax-minValueBetween));
    
    // console.log((max * dragedWidhtInPercent)/100);
  
    if( (currentMin >= min) && (currentMin <= (currentMax-minValueBetween))){
      this.minValue.style.width = dragedWidhtInPercent + "%";
      this.minValue.dataset.content = currentMin;

      this.setState({
        currentMin,
        inputMin: currentMin
      })
    }
  }

  onMouseUpMin = () => {
    document.removeEventListener('mouseup', this.onMouseUpMin);
    document.removeEventListener('mousemove', this.onMouseMoveMin);
    
    document.removeEventListener('touchend', this.onMouseMoveMin);
    document.removeEventListener('touchmove', this.onMouseUpMin);
  }
  
  
  setMax = (e) => {
    const { min, max, currentMin, currentMax, minValueBetween } = this.state;

    const inputMax = e.target.value;
    
    this.setState({
        inputMax
    });

    if((inputMax >= currentMin + minValueBetween) && (inputMax <= max)){
      
      this.setState({
        currentMax: parseInt(inputMax)
      });
      this.maxValue.style.width = (inputMax*100)/max + "%";
    }
      
  }
  inputMaxBlur = (e) =>{
    const { min, max, currentMax, currentMin, minValueBetween } = this.state
    let inputMax = Number.isNaN(parseInt(e.target.value)) ? currentMax : parseInt(e.target.value)
    if(inputMax > max){
      inputMax = max
    }else if(inputMax < currentMin+minValueBetween){
      inputMax = currentMin+minValueBetween
    }

    this.setState({
      inputMax,
      currentMax: inputMax
    }); 
    this.maxValue.style.width = (inputMax*100)/max + "%";
  }
  changeMaxValue = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', this.onMouseMoveMax);
    document.addEventListener('mouseup', this.onMouseUpMax);
    
    document.addEventListener('touchmove', this.onMouseMoveMax);
    document.addEventListener('touchend', this.onMouseUpMax);
  }

  onMouseMoveMax = (e) => {
    const { max, currentMin, minValueBetween, sliderWidth, offsetSliderWidth} = this.state; 
    const maxWalueThumb = this.maxValue;
    const dragedWidht = e.clientX - offsetSliderWidth;
    const dragedWidhtInPercent = (dragedWidht*100)/sliderWidth;
    const currentMax = Math.abs(parseInt((max * dragedWidhtInPercent)/100));
    
    if( (currentMax >= (currentMin + minValueBetween)) && (currentMax <= max)){
      
      maxWalueThumb.style.width = dragedWidhtInPercent + "%";
      maxWalueThumb.dataset.content = currentMax;
      this.setState({
        currentMax,
        inputMax: currentMax
      })
    }
  }

  onMouseUpMax = () => {
    document.removeEventListener('mouseup', this.onMouseUpMax);
    document.removeEventListener('mousemove', this.onMouseMoveMax);
    
    document.removeEventListener('touchend', this.onMouseUpMax);
    document.removeEventListener('touchmove', this.onMouseMoveMax);
  }
  
  maxForMin = () => {
    const { currentMax, minValueBetween} = this.state; 
    return currentMax - minValueBetween;
  }
  
  minForMax = () => {
    const { currentMin, minValueBetween} = this.state; 
    return currentMin + minValueBetween;
  }
    
    
  render() {
    const { min, max, currentMin, inputMin, currentMax, inputMax, minValueBetween } = this.state;
    
    return (
      <div className="card"> 
        <button className='card-price-button'>Price</button>
        <div className="values">
          <div>{ min }</div>
          <div>{ max }</div>
        </div>
        <div ref={ref => this.slider = ref} id="slider">

          <div ref={ref => this.minValue = ref} id="min" data-content={currentMin}>
            <div ref={this.minRef} id="min-drag" onMouseDown ={this.changeMinValue} onTouchStart={(e)=>this.changeMinValue(e)}></div>
          </div>

          <div ref={ref => this.maxValue = ref} id="max" data-content={currentMax}>
            <div ref={this.maxRef} id="max-drag" onMouseDown={this.changeMaxValue} onTouchStart={this.changeMaxValue}></div>
          </div>

        </div>
        <div className="current-value">
          {/* <label htmlFor="min-input">Min: </label> */}
          <input 
            id="min-input" 
            type="number" 
            onChange={this.setMin} 
            onBlur={this.inputMinBlur}
            value={inputMin}
            min={min}
            max={this.state.currentMax - this.state.minValueBetween}/>
          
          {/* <br/> */}
          {/* <label htmlFor="max-input">Max: </label> */}
          <input 
            id="max-input" 
            type="number" 
            onChange={this.setMax} 
            onBlur={this.inputMaxBlur}
            value={inputMax}
            min={this.state.currentMin + this.state.minValueBetween}
            max={max}/>
            <button className='' onClick={()=>this.priceRangeUpdate()}>UPDATE</button>
        </div>
      </div>
    )
  }
}
