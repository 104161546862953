import React, { Component } from 'react'
import './index.css'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'
import axios from 'axios'
import serverInfo from "../../config.js"

 class ContactPage extends Component {
    constructor(props){
        super(props)
        this.fullNameRef = React.createRef()
        this.phoneRef = React.createRef()
        this.emailRef = React.createRef()
        this.orderNumberRef = React.createRef()
        this.companyRef = React.createRef()
        this.rmaRef = React.createRef()
        this.questionRef = React.createRef()
        this.state={
            fullNameCheck:true,
            phoneCheck:true,
            emailCheck:true,
            orderNumberCheck:true,
            companyChcek:true,
            rmaCheck:true,
            questionCheck:true,
            questionContent:null,
        }
    }
    submitForm = (e) =>{
        e.preventDefault()
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        const phoneRegex = /^\d{10}$/
        const fullNameCheck = this.dataFormCheck(this.fullNameRef.current.value,true,50)
        const phoneCheck= phoneRegex.exec(this.phoneRef.current.value) ? true : false
        const emailCheck= emailRegex.exec(this.emailRef.current.value) ? true : false
        const orderNumberCheck = this.dataFormCheck(this.orderNumberRef.current.value,true,50)
        const companyChcek = this.dataFormCheck(this.companyRef.current.value,false,50)
        const rmaCheck = this.dataFormCheck(this.rmaRef.current.value,false,50)
        const questionCheck = this.dataFormCheck(this.state.questionContent,true,5000)
        this.setState({fullNameCheck,phoneCheck,emailCheck,orderNumberCheck,companyChcek,rmaCheck,questionCheck})
        if(fullNameCheck&&phoneCheck&&emailCheck&&orderNumberCheck&&companyChcek&&rmaCheck&&questionCheck){
            const data = {
                fullName:this.fullNameRef.current.value,
                phoneNumber:this.phoneRef.current.value,
                email:this.emailRef.current.value,
                orderNumber:this.orderNumberRef.current.value,
                company:this.companyRef.current.value,
                rma:this.rmaRef.current.value,
                questions:this.state.questionContent
            }
            // console.log(data)
            axios.post(`${serverInfo.baseURL}/zoho/sendemail`,data,{headers:{'customerid':serverInfo.customerid,'authorization':serverInfo.token}}).then(
                res=>{
                    alert(res.data.smessage)
                },
                rej=>console.log(rej)
            )
        }
        
    }
    dataFormCheck = (value,isMandatory,maxLength) =>{
        if(isMandatory){
            return (!value || value === "" || value.length > maxLength) ? false : true
        }else{
            return (!value?.length || value?.length <= maxLength) ? true : false
        }
    }
  render() {
    return (
      <div className='contactpage'>
        <PageRoute/>
        <div className='contactpage-content'>
            <h1>Contact Us</h1>
            <p>We're happy to answer questions or help you with returns. </p>
            <p>Please fill out the form below if you need assistance.</p>
            <p style={{fontSize:"14px"}}>We are available <b>Monday</b> through <b>Friday</b> from <b>9AM</b> to <b>5PM</b> EST.  If your call is urgent, please contact us at <b>1-905-731-1928</b>.</p>
            <form action="">
                <div>
                    <p>
                        <label className='mandatory'>Full Name</label>
                        <input type='text' ref={this.fullNameRef} className='error' maxLength={50}/>
                        <label className={this.state.fullNameCheck?"":"error"} style={{display:this.state.fullNameCheck?"none":""}}>Full name cannot be empty, max length is 50.</label>
                    </p>
                    <p>
                        <label>Phone Number</label>
                        <input ref={this.phoneRef} type='text' maxLength={10}/>
                        <label className={this.state.phoneCheck?"":"error"} style={{display:this.state.phoneCheck?"none":""}}> Please enter 10 digits number without any other characters.</label>
                    </p>
                </div>
                <div>
                    <p>
                        <label className='mandatory'>Email Address</label>
                        <input ref={this.emailRef} type='text' maxLength={50}/>
                        <label className={this.state.emailCheck?"":"error"} style={{display:this.state.emailCheck?"none":""}}>Email address format is wrong, max length is 50.</label>
                    </p>
                    <p>
                        <label className='mandatory'>Order Number</label>
                        <input ref={this.orderNumberRef} type='text' maxLength={50}/>
                        <label className={this.state.orderNumberCheck?"":"error"} style={{display:this.state.orderNumberCheck?"none":""}}>Order number cannot be empty, max length 50 characters.</label>
                    </p>
                </div>
                <div>
                    <p>
                        <label>Company Name</label>
                        <input ref={this.companyRef} type='text' maxLength={50}/>
                        <label className={this.state.companyChcek?"":"error"} style={{display:this.state.companyChcek?"none":""}}>Company name cannot exceed max length 50.</label>
                    </p>
                    <p>
                        <label>RMA Name</label>
                        <input ref={this.rmaRef} type='text' maxLength={50}/>
                        <label className={this.state.rmaCheck?"":"error"} style={{display:this.state.rmaCheck?"none":""}}>RMA name cannot exceed max length 50.</label>
                    </p>
                </div>
                <p className='contactpage-question'>
                    <label className='mandatory'>Comments/Questions</label>
                    <label className={this.state.questionCheck?"":"error"} style={{display:this.state.questionCheck?"none":""}}>Quesitons cannot be empty, max length is 5000.</label>
                    <textarea onChange={(e)=>{this.setState({questionContent:e.target.value})}} maxLength={5000}></textarea>
                    
                </p>
                <div className='contactpage-submit'>
                    <button onClick={(e)=>this.submitForm(e)}>SUBMIT FORM</button>
                </div>
            </form>
        </div>
      </div>
    )
  }
}
export default withRouter(ContactPage)