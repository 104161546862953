import React, { Component } from 'react'
import './index.css'
import imageNotFound from '../../../media/image/image-not-found.jpeg'
import { FaTrashAlt } from "react-icons/fa";
import serverInfo from '../../../config';
import { Link } from 'react-router-dom';
import PubSub from 'pubsub-js';
import axios from 'axios';
import Cookies from 'universal-cookie';
export default class CartItem extends Component {
  constructor(props){
    super(props)
    this.cookies = new Cookies()
    this.condition = {
      "ScratchDent": "Scratch & Dent",
      "BrandNew": "Brand New",
      "Recertified": "Recertified",
      "Warranty": "Warranty"
    }
    this.state={
      item:null,
      itemQauntity:0,
      imageUrl:null,
      errorMessage:null,
      inProcessing: false,
      cartItems:[],
      cartItemsError:{},
      percentage:this.cookies.get('percentage') ? this.cookies.get('percentage') : 0
    }
  }
  removeItemFromCart= (e) => {
    this.setState({inProcessing:true})
    PubSub.publish("inProcessing",true)
    const cartItems = [...this.state.cartItems]
    let itemIndex = 0
    //get current item index in cartitems
    for(const cartItem of this.state.cartItems){
      if(this.state.item.item_id === cartItem.item_id){
        break
      }
      itemIndex++;
    }
    axios.delete(`${serverInfo.baseURL}/user/${serverInfo.customerid}/cart/${this.state.item.item_id}/${this.state.item.item_condition}/remove`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
      res=>{
        if(res.data.scode === 200){
          PubSub.publish('cartItems',res.data.cartItems)
        }else if(res.data.scode === 201){
          cartItems.splice(itemIndex,1)
          PubSub.publish('cartItems',cartItems)
        }
        alert(`${this.state.item.item_name} has been removed from your cart.`)
      },
      rej=>console.log(rej)
    )
    setTimeout(() => {
      this.setState({inProcessing:false})
      PubSub.publish('inProcessing',false)
    }, 1000);
  }
  changeInputValue = (e)=>{
    const newValue = e.target.value;
    // 使用正则表达式来确保只有数字被输入
    const numericRegex = /^[0-9]*$/;
    const newValueIsNumeric = numericRegex.test(newValue);
    const isError = this.state.errorMessage
    const stock = this.state.item.item_condition === 'ScratchDent' ? this.state.item.scratch_dent_stock : this.state.item.stock

    if (newValueIsNumeric) {
      const newNumber = Number.parseInt(newValue)
      if(Number.isNaN(newNumber)){
        this.setState({itemQauntity:newValue})
      }else if(newNumber>0 && newNumber<= stock){  

        this.setState({itemQauntity:newNumber,errorMessage:false})
      }else if(newNumber > stock){
        this.setState({itemQauntity:stock,errorMessage:false})
        alert(`This item currently only have ${stock} left.`)
      }
    }
    // if there is errorMessage then update it as null (once input is edited, system will automatically fixed the error)
    if(isError){
      const cartItemsError = {...this.state.cartItemsError}
      cartItemsError[this.state.item.item_id][this.state.item?.item_condition] = null
      PubSub.publish('cartItemsError',cartItemsError)
    }
  }
  // isAdd: true means add one, false mean minus one
  itemQuantityChange = (isAdd)=>{
    this.setState({inProcessing:true})
    PubSub.publish("inProcessing",true)
    const cartItems = [...this.state.cartItems]
    let isDeleted = true,itemIndex = 0
    //once button is clicked, system will automatically fixed the error
    if(this.state.errorMessage){
      const cartItemsError = {...this.state.cartItemsError}
      cartItemsError[this.state.item.item_id][this.state.item?.item_condition] = null
      PubSub.publish('cartItemsError',cartItemsError)
    }
    //get current item index in cartitems
    for(const cartItem of this.state.cartItems){
      if(this.state.item.item_id === cartItem.item_id){
        isDeleted = false
        break
      }
      itemIndex++;
    }
    //if item not found in currrent cartItems, mean this item has been deleted
    if(isDeleted){
      alert(`${this.state.item.item_name} has been removed from your cart, please refresh the page!`)
    }else{
      const stock = this.state.item.item_condition === 'ScratchDent' ? this.state.item.scratch_dent_stock : this.state.item.stock
      if(stock <= 0){
        axios.delete(`${serverInfo.baseURL}/user/${serverInfo.customerid}/cart/${this.state.item.item_id}/${this.state.item.item_condition}/remove`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
          res=>{
            if(res.data.scode === 200){
              PubSub.publish('cartItems',res.data.cartItems)
              alert(`${this.state.item.item_name} has been auto removed from your cart due to this item is out of stock.`)
            }else if(res.data.scode === 201){
              cartItems.splice(itemIndex,1)
              PubSub.publish('cartItems',cartItems)
              alert(`${this.state.item.item_name} has been auto removed from your cart due to this item is out of stock.`)
            }
          },
          rej=>console.log(rej)
        )
      }else{
        const data = {
          itemData:{
            customer_id:serverInfo.customerid,
            item_id:this.state.item.item_id,
            item_condition:this.state.item.item_condition,
            sku:this.state.item.sku
          },
          is_replaced:true
        }
        let currentItemQuantity = this.state.itemQauntity
        if(isAdd){
          if(this.state.itemQauntity + 1 <= this.state.item.stock){
            currentItemQuantity += 1
          }else{
            alert(`Cannot add quantity due to this item currently only have ${this.state.item.stock} left.`)
          }
        }else{
          if(this.state.itemQauntity - 1 >= 1 ){
            currentItemQuantity = currentItemQuantity - 1 <= this.state.item.stock ? currentItemQuantity - 1 : this.state.item.stock
          }else{
            alert(`item quantity cannot be smaller than 1, if you want to remove item from cart, please click remove button.`)
          }
        }
        if(currentItemQuantity !== this.state.itemQauntity){
          this.setState({itemQauntity:currentItemQuantity})
          data['itemData']['quantity'] = currentItemQuantity
          axios.post(`${serverInfo.baseURL}/user/cart/add`,data,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
            res=>{
              if(res.data.scode === 200){
                this.setState({errorMessage:false})
                PubSub.publish('cartItems',res.data.cartItems)
              }else if(res.data.scode === 201){
                cartItems[itemIndex]['quantity'] = currentItemQuantity
                this.setState({errorMessage:false})
                PubSub.publish('cartItems',cartItems)
                
              }else{
                alert(`${this.state.item.item_name} update failed, please refresh the page and try again.`)
              }
            },
            rej=>console.log(rej)
          )
        }
      }
      
    }
    setTimeout(() => {
      this.setState({inProcessing:false})
      PubSub.publish('inProcessing',false)
    }, 1000);
    
  }
  onBlurUpdate = (e)=>{
    // console.log("Blur",this.state.itemQauntity)
    this.setState({inProcessing:true})
    PubSub.publish("inProcessing",true)
    const data = {
      itemData:{
        customer_id:serverInfo.customerid,
        item_id:this.state.item.item_id,
        sku:this.state.item.sku,
        item_condition:this.state.item.item_condition
      },
      is_replaced:true
    }
    let itemIndex = 0 , isDeleted = true , itemQauntity = this.state.itemQauntity
    const cartItems = [...this.state.cartItems]
    const cartItemsError = {...this.state.cartItemsError}
    if(!cartItemsError[this.state.item.item_id]){
      cartItemsError[this.state.item.item_id]={}
    }
    cartItemsError[this.state.item.item_id][this.state.item.item_condition] = null
    // find item index in cartItems array, and if it cannot be found, the item has been deletetd from cart or a disabled item
    for(const cartItem of this.state.cartItems){
      if(this.state.item.item_id === cartItem.item_id){
        isDeleted = false
        break
      }
      itemIndex++;
    }
    // if item has been removed, show message to customer
    if(isDeleted){
      alert(`${this.state.item.item_name} has been removed from your cart, please refresh the page!`)
    }else{
      // if item is out of stock, removed from customer cart automatically
      const stock = this.state.item.item_condition === 'ScratchDent' ? this.state.item.scratch_dent_stock : this.state.item.stock
      if(stock <= 0){
        axios.delete(`${serverInfo.baseURL}/user/${serverInfo.customerid}/cart/${this.state.item.item_id}/${this.state.item.item_condition}/remove`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
          res=>{
            if(res.data.scode === 200){
              PubSub.publish('cartItems',res.data.cartItems)
              alert(`${this.state.item.item_name} (${this.state.item.item_condition}) has been auto removed from your cart due to this item is out of stock.`)
            }else if(res.data.scode === 201){
              cartItems.splice(itemIndex,1)
              PubSub.publish('cartItems',cartItems)
              alert(`${this.state.item.item_name} (${this.state.item.item_condition}) has been auto removed from your cart due to this item is out of stock.`)
            }
          },
          rej=>console.log(rej)
        )
      }else if(this.state.itemQauntity !== this.state.item.quantity){
        if(this.state.itemQauntity === ""){
          itemQauntity = this.state.item.quantity>this.state.item.stock ? 1 : this.state.item.quantity
          this.setState({itemQauntity:itemQauntity})
        }else if(this.state.itemQauntity > this.state.item.stock){
          itemQauntity = this.state.item.stock
          this.setState({itemQauntity:itemQauntity})
        }
        data.itemData['quantity'] = itemQauntity
        axios.post(`${serverInfo.baseURL}/user/cart/add`,data,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
          res=>{
            if(res.data.scode === 200){
              PubSub.publish('cartItems',res.data.cartItems)
            }else if(res.data.scode === 201){
              cartItems[itemIndex]['quantity'] = itemQauntity
              PubSub.publish('cartItems',cartItems)
            }else{
              alert(`${this.state.item.item_name} update failed, please refresh the page and try again.`)
            }
          },
          rej=>console.log(rej)
        )
      }
    }
    setTimeout(() => {
      this.setState({inProcessing:false})
      PubSub.publish('inProcessing',false)
    }, 1000);
  }
  componentDidMount(){
    if(this.props.item){
      const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
      const googleImageId = imageRegex.exec(this.props.item.main_image)?.[1]
      let imageUrl = null
      if(googleImageId){
        imageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
      }
      this.setState({item:{...this.props.item},cartItems:[...this.props.cartItems], cartItemsError:{...this.props.cartItemsError},itemQauntity:this.props.item.quantity,errorMessage:this.props.cartItemsError[this.props.item.item_id]?.[this.props.item.item_condition]?true:false,imageUrl})
    }
    this.cartItemsSub = PubSub.subscribe('cartItems',(_,data)=>{
      this.setState({cartItems:[...data]})
    })
    this.cartItemsErrorSub = PubSub.subscribe('cartItemsError',(_,data)=>{
      this.setState({cartItemsError:{...data}})
    })
    this.inProcessingSub = PubSub.subscribe('inProcessing',(_,data)=>{this.setState({inProcessing:data})})
    this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{this.setState({percentage:data})})
  }
  componentWillUnmount(){
    PubSub.unsubscribe(this.cartItemsSub)
    PubSub.unsubscribe(this.cartItemsErrorSub)
    PubSub.unsubscribe(this.inProcessingSub)
    PubSub.unsubscribe(this.percentageSub)
  }
  render() {
    return (
      <div className='cartitem'>
        <div className='cartitem-iteminfo'>
            <img src={this.state.imageUrl?this.state.imageUrl:imageNotFound} alt='err'/>
            {
              this.state.inProcessing?(<span>{this.state.item?.item_name}</span>):(<Link to={{pathname:`/acer/product/${this.state.item?.item_id}/${this.state.item?.item_condition}`,state:{from:'/acer/cart'}}}>{`(${this.state.item?.item_condition}) ${this.state.item?.item_name}`}</Link>)
            }
            
            {/* <a href='#'>Acer Chromebook Spin 311 - 11.6" MediaTek MT8183 2GHz 4GB Ram 32GB Flash Chrome OS | CP311-3H-K23X | Scratch & Dent | NX.HUVAA.005.HU</a> */}
        </div>
        <div className='cartitem-pricing'>
            <div className='cartitem-quantity'>
                <button className={this.state.inProcessing?"disabled":""} disabled={this.state.inProcessing} onClick={()=>this.itemQuantityChange(false)}>-</button>
                <input className={this.state.inProcessing?"disabled":""} type='text' disabled={this.state.inProcessing} value={this.state.itemQauntity} onChange={this.changeInputValue} onClick={()=>{}} onBlur={(e)=>this.onBlurUpdate(e)} style={{color:this.state.errorMessage ? "red" : "black"}}/>
                <button className={this.state.inProcessing?"disabled":""} disabled={this.state.inProcessing} onClick={()=>this.itemQuantityChange(true)}>+</button>
            </div>
            <p>${this.state.item?.item_condition === "ScratchDent" && this.state.item?.personal_scratch_dent_price && this.state.item?.personal_scratch_dent_price !== 0  ? this.state.item?.personal_scratch_dent_price?.toFixed(2) : (this.state.item?.is_sale && this.state.item?.personal_sale_price && this.state.item?.personal_sale_price !== 0 ?this.state.item?.personal_sale_price?.toFixed(2):this.state.item?.personal_price?.toFixed(2))}</p>
            <button className={this.state.inProcessing?"cartitem-remove disabled":"cartitem-remove"} disabled={this.state.inProcessing} onClick={()=>this.removeItemFromCart()}><FaTrashAlt fontSize={'16px'} color={this.state.inProcessing?"rgb(240,240,240)":"black"}/><br/>Remove</button>
        </div>
      </div>
    )
  }
}
