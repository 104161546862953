import React, { Component } from 'react'
import './index.css'
import {FaSearch} from "react-icons/fa"
import PubSub from 'pubsub-js'
import axios from 'axios'
import { debounce } from 'lodash'
import withRouter from '../../../withRouter'
class Search extends Component {
    state = {searchString:"",column:"all"}
    searchString = React.createRef()
    onKeyUp = (e)=>{
            // console.log(this.searchStore.current.value)
        if(e.keyCode !== 13){
            let strArray = this.searchString.current.value.split(" ")
            let searchStr = strArray.reduce((a,c)=> a+"+"+c)
            // console.log(searchStr)
            this.setState({searchString:searchStr})
        }else{
            this.props.navigate(`/acer/category/Search?column=${this.state.column}&string=${this.state.searchString}`,{state:{from:this.props.location.pathname}})
        }
        // console.log(e.keyCode)
        // console.log(this.state)
    }
    debounceSearch = debounce((column,searchStore)=>{
            // axios.get(`https://alcmicro-server.nn.r.appspot.com/inventory/search?column=${column}&string=${searchStore}`).then(
            // res=>{
            //     const results = []
            //     for(const item of res.data){
            //         if(item.stock+item.on_the_way-item.committed_stock>0){
            //             results.push(item)
            //         }
            //     }
            //     results.sort((a,b)=>{
            //         if(a['item_name'].toUpperCase() < b['item_name'].toUpperCase()){
            //           return -1;
            //         }else if(a['item_name'].toUpperCase() > b['item_name'].toUpperCase()){
            //           return 1;
            //         }else{
            //           return 0
            //         }
            //     })
            //     PubSub.publish('items',results)
            // },
            // rej=>console.log(rej))
        },500)
    debounceRefresh = debounce(()=>{
        // axios.get(`https://alcmicro-server.nn.r.appspot.com/inventory`).then(
        //     res=>{
        //     const results = [...res.data]
        //     results.sort((a,b)=>{
        //         if(a['item_name'].toUpperCase() < b['item_name'].toUpperCase()){
        //           return -1;
        //         }else if(a['item_name'].toUpperCase() > b['item_name'].toUpperCase()){
        //           return 1;
        //         }else{
        //           return 0
        //         }
        //       })
        //     PubSub.publish('items',results)
        //     // console.log(results)
        //     // const categoryCheck = {}
        //     // const cagetoryList = []
            
        //     // PubSub.publish('categoryList',cagetoryList)
        //     },
        //     rej=>console.log(rej))
    },500)
    handleSearch = () => {
        this.debounceSearch(this.state.column,this.state.searchStore)
        // axios.get(`https://alcmicro-server.nn.r.appspot.com/inventory/search?column=${this.state.column}&string=${this.state.searchStore}`).then(
        //     res=>{
        //         const results = [...res.data]
        //         PubSub.publish('items',results)
        //         console.log(results)
        //     },
        //     rej=>console.log(rej))
    }
    
    componentDidMount(){
        // this.debounceRefresh()
        // const cookies = document.cookie.split(";")
        // let username = null
        // for(const cookie of cookies){
        //     if(cookie.includes('username')){
        //         username = cookie.split('=')[1]
        //         // console.log(username)
        //     }
        // }
        // if(username){
        //     axios.get(`https://alcmicro-server.nn.r.appspot.com/validate?username=${username}`).then(
        //         res=>{
        //         PubSub.publish('pricePercentage',res.data?.[0].price_percentage)
        //         // console.log(results)
        //         // const categoryCheck = {}
        //         // const cagetoryList = []
                
        //         // PubSub.publish('categoryList',cagetoryList)
        //         },
        //         rej=>console.log(rej))
        //     axios.get(`https://alcmicro-server.nn.r.appspot.com/inventory`).then(
        //         res=>{
        //         const results = []
        //         for(const item of res.data){
        //             if(item.stock+item.on_the_way-item.committed_stock>0){
        //                 results.push(item)
        //             }
        //         }
        //         results.sort((a,b)=>{
        //             if(a['item_name'].toUpperCase() < b['item_name'].toUpperCase()){
        //               return -1;
        //             }else if(a['item_name'].toUpperCase() > b['item_name'].toUpperCase()){
        //               return 1;
        //             }else{
        //               return 0
        //             }
        //           })
        //         PubSub.publish('items',results)
        //         // console.log(results)
        //         // const categoryCheck = {}
        //         // const cagetoryList = []
                
        //         // PubSub.publish('categoryList',cagetoryList)
        //         },
        //         rej=>console.log(rej))
        // }
    }
        
    render() {
        return (
            <div className="Search">
                <select id="search-column" value={this.setState.column} onChange={(e) => this.setState({column:e.target.value})}>
                    <option value="all">All</option>
                    <option value="item_name">Item Name</option>
                    <option value="sku">SKU</option>
                    {/* <option value="description">Description</option> */}
                </select>
                <input ref={this.searchString} type="text" onKeyUp={this.onKeyUp} placeholder="Key Word"/>
                <button onClick={() => this.props.navigate(`/acer/category/Search?column=${this.state.column}&string=${this.state.searchString}`,{state:{from:this.props.location.pathname}})}><FaSearch color="#6C757D"/></button>
            </div>
        )
    }
}
export default withRouter(Search)