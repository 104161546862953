import React, { Component } from 'react'
import PubSub from 'pubsub-js'
import './index.css'
import DoubleSlider from '../../DoubleSlider'
import { RxCross2 } from "react-icons/rx";
export default class Multiselect extends Component {
    searchString = React.createRef()
    state = {
        selection: null,
        currentSelection: null,
        selected:{},
        currentCategory: null,
        hideFilters:{},
        searchString:"",
        priceRange:{maxPric:0,minPrice:0}
        // selected:{
        //     "Screen Size":{},
        //     Processor:{},
        //     Memory:{},
        //     "HD or SSD":{}
        // }
    }
    onKeyUp = (e)=>{
        // console.log(this.searchStore.current.value)
        if(e.keyCode !== 13){
            console.log(this.searchString.current.value)
            this.setState({searchString:this.searchString.current.value})
        }else{
            console.log(e.keyCode,this.state.searchString)
            PubSub.publish('selected',{selected:this.state.selected,searchString:this.state.searchString})
        }
        // console.log(e.keyCode)
        // console.log(this.state)
    }
    hideFilterHandler = (filterName)=>{
        const hideFilters = {...this.state.hideFilters}
        if(hideFilters[filterName]){
            hideFilters[filterName] = false
        }else{
            hideFilters[filterName] = true
        }
        // console.log(hideFilters)
        this.setState({hideFilters})
    }
    multiselectCreate = (selection)=>{
        const heads = [] // multi select cateogry field name
        // let index = 0
        // Create multi selection fields if there is currentCattegory and selection has this category fields
        if(selection){
            for(const head in selection){
                heads.push(head) // push category field name in heads
            }
        }
        if(heads.length>0){
            return (
                <div className='multiselect'>
                    {
                        heads.map(head=>{
                            const headArray = head.split('_'), modHeadArray = []
                            for(const word of headArray){
                                modHeadArray.push(word[0].toUpperCase() + word.slice(1))
                            }
                            const headString = modHeadArray.reduce((str,current)=>`${str} ${current}`)
                            return(
                                <div key={`${head}-main`} className='multiselect-content'>
                                    <button key={`${head}-h2`} onClick={()=>this.hideFilterHandler(head)} className={this.state.hideFilters[head]?"hide":"show"}>{headString}</button>
                                    <ul key={`${head}-ul`} style={{display:this.state.hideFilters[head]?"none":""}}className='multiselect-ul'>
                                        {
                                            selection[head].map(content => {
                                                return(
                                                    <li key={`${head}-${content[head]}-li`}>
                                                        <div key={`${head}-${content[head]}-div`} className='multiselect-div'>
                                                            <label key={`${head}-${content[head]}-label`}>
                                                                <input key={`${head}-${content[head]}-input`} className='multiselect-input' type="checkbox" name={head} value={content[head]} checked={this.state.selected?.[head]?.[content[head]]?true:false} onChange={()=>{this.handleOnChange([head,content[head]])}}/>
                                                                {`${content[head]} (${content['count']})`}
                                                                <span key={`${head}-${content[head]}-span`} className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }else{
            return(<div className='multiselect'></div>)
        }

    }
    handleOnChange=(content)=>{
        const selected = this.state.selected
        // content[0] is filter category name, content[1] is the value in that category (eg. touchscreen is content[0], 'YES' and 'NO' is content[1])
        if(selected?.[content[0]]?.[content[1]]){
            selected[content[0]][content[1]] = false
        }else{
            selected[content[0]][content[1]] = true
        }
        this.setState({selected:{...selected}})
        // console.log(this.state.selected)
        console.log(`multiselect selected`,selected)
        PubSub.publish('selected',{selected,searchString:this.state.searchString})
        // console.log(this.state.selected)
        // console.log("after:",this.state.selected)
        // console.log(`${value}: ${this.state.selected}`)
    }
    componentDidUpdate(){
        // console.log("update",this.state.selection)
        if(!this.state.selection && this.props.selection){
            const currentSelected = {}, filters ={}
            for(const head in this.props.selection){
                if(this.props.selection?.[head]?.length > 0 ){
                    filters[head] = this.props.selection[head]
                }
                currentSelected[head] = {}
            }
            PubSub.publish('priceRange',this.props.priceRange)
            this.setState({selection:{...filters},selected:{...currentSelected},currentCategory:this.props.category,priceRange:this.props.priceRange})
        }
    }
    componentDidMount(){
        if(this.props.selection){
            console.log('mount',this.props)
            const currentSelected = {}, filters ={}
            for(const head in this.props.selection){
                if(this.props.selection?.[head]?.length > 0 ){
                    filters[head] = this.props.selection[head]
                }
                currentSelected[head] = {}
            }
            PubSub.publish('priceRange',this.props.priceRange)
            this.setState({selection:{...filters},selected:{...currentSelected},currentCategory:this.props.category,priceRange:this.props.priceRange})
        }
        this.originalFilters = PubSub.subscribe('originalFilters',(_,data)=>{
            const currentSelected = {}, filters ={}
            for(const head in data.selection){
                if(data.selection[head].length > 0 ){
                    filters[head] = data.selection[head]
                }
                currentSelected[head] = {}
            }
            PubSub.publish('priceRange',data.priceRange)
            this.setState({selection:{...filters},selected:{...currentSelected},priceRange:{...data.priceRange}})
        })
        this.currentFilters = PubSub.subscribe('currentFilters',(_,data)=>{
            const filters ={}
            for(const head in data.selection){
                if(data.selection[head].length > 0 ){
                    filters[head] = data.selection[head]
                }
            }
            // console.log('currentFilter',filters)
            this.setState({selection:{...filters}})
        })
    }
    // priceSlider(){
    //     let to = 100 % (this.value - this.min) / (this.max - this.min);
    //         // 单值选择高亮起始位置一定是0
    //     this.style.setProperty('--from', 0);
    //     this.style.setProperty('--to', to);
    // }
    componentWillUnmount(){
        PubSub.unsubscribe(this.originalFilters)
    }
    render() {
    return (
        <div className='multiselect-main' style={{display:this.props.hideFilters?"none":"flex",top:window.innerWidth<=800?`${this.props.filterTop}px`:'0'}}>
            {/* <div className='multiselect'>
                <h2>Screen Size</h2>
                <ul className='multiselect-ul'>
                    <li>
                        <div className='multiselect-div'>
                            <input type="checkbox" name='Screen Size' value="12.5" checked={this.state.selected["12.5"]?true:false} onChange={()=>{this.handleOnChange("12.5")}}/>
                            <label>12.5</label>
                        </div>
                    </li>
                    <li>
                        <div className='multiselect-div'>
                            <input type="checkbox" name='Screen Size' value="13" checked={this.state.selected["13"]?true:false} onChange={()=>{this.handleOnChange("13")}}/>
                            <label>13</label>
                        </div>
                        
                    </li>
                </ul>
            </div> */}
            <div className='multiselect-mobile-title'>
                <h1>FILTER</h1>
                <button  onClick={()=>{PubSub.publish('hideFilters',true)}}><RxCross2/></button>
            </div>
            <input type='text' ref={this.searchString} placeholder={'Filter products by SKU,name'} onKeyUp={this.onKeyUp} className='multiselect-search'></input>
            {/* <div className='multiselect-shopby'>
                <h1>Shop by</h1>
                <div style={{display:'none'}}>

                </div>
                <p>No filters applied</p>
            </div> */}
            {
                this.multiselectCreate(this.state.selection)
            }
            <DoubleSlider priceRange={this.state.priceRange}/>
            <button className='multiselect-mobile-filter'>APPLY FILTERS</button>
        </div>
    )
    }
}
