import React, { Component, useId } from 'react'
import "./index.css"
import Logo from './Logo'
import Search from './Search'
import User from './User'
// import {IoIosArrowDown} from 'react-icons/io'
import axios from 'axios'
import Cookies from 'universal-cookie'
import serverInfo from "../../config.js"
import userInfo from '../../userInfo'
import { MdOutlineArrowDropDown } from "react-icons/md";
import { PiPhoneCall } from "react-icons/pi";
import { MdOutlineShoppingBag } from "react-icons/md";
import { IoMenu } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import PubSub from 'pubsub-js'
// import bannerURL from '../../media/image/Banner.jpg'
// import menuURL from '../../media/image/menu.svg'
import { Link } from 'react-router-dom'
import withRouter from '../../withRouter'
class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            isMobile:window.innerWidth<=800?true:false,
            cartItems:[],
            cartItemsNumber:0,
            addresses:[],
            isLogged:false,
            menuTop:window.pageYOffset,
            showAccount:false,
            showMenu:false,
            showHeader:false, 
            windowWidth:window.innerWidth,
            showSale:false,
            showDesktop:false,
            showGaming:false,
            showMonitor:false,
            showOnTheGo:false,
            showAccessories:false,
            showScratch:false,
            showWarranty:false
        }
        this.freezeWindow = function(){
            window.scrollTo({top:this.state.menuTop,behavior:"auto"})
            console.log(`scroll freeze`)
        }
        this.freezeHandler = this.freezeWindow.bind(this)

    }
    windowResize(){
        const currentWidth = window.innerWidth
        if(this.state.isMobile && currentWidth>800){
            this.setState({isMobile:false})
        }
        if(!this.state.isMobile && currentWidth<=800){
            this.setState({isMobile:true})
        }
        if(this.state.windowWidth > 800 && currentWidth <= 800){
            this.setState({windowWidth:currentWidth})
        }
        if(this.state.windowWidth <= 800 && currentWidth > 800){
            this.setState({
                menuTop:0,
                windowWidth:currentWidth,
                showAccount:false,
                showSale:false,
                showDesktop:false,
                showGaming:false,
                showMonitor:false,
                showOnTheGo:false,
                showAccessories:false,
                showScratch:false,
                showWarranty:false
            })
            window.removeEventListener('scroll',this.freezeHandler)
        }
    }
    showMenu(){
        this.setState({showMenu:true,menuTop:window.pageYOffset})
        window.addEventListener('scroll',this.freezeHandler)
    }
    hideMenu(){
        this.setState({showMenu:false,menuTop:0})
        window.removeEventListener('scroll',this.freezeHandler)
    }
    componentDidMount(){
        window.addEventListener('resize', this.windowResize.bind(this))
        
        const cookies = new Cookies()
        // cookies.set('login',true,{expires:new Date(Date.now()+10000)})
        // this.setCookie('login', true, 15)
        // cookies.remove('customerid')
        // console.log(cookies.get('login'))
        // console.log(document.cookie)
        // serverInfo.updateCustomerId('testid')
        // console.log(serverInfo.customerid)
        // const customerid = cookies.get('customerid')?cookies.get('customerid'):"visitors"
        if(cookies.get('login')){
            axios.get(`${serverInfo.baseURL}/header`,{headers:{'customerid':serverInfo.customerid,'percentage':serverInfo.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                if(res.data.scode === 200){
                    // console.log(res.data)
                    const cartItemsNumber = res.data.cartItems?.reduce((total,current)=>total+current.quantity,0)
                    const cartItemsSubtotal = res.data.cartItems?.reduce((total,current)=>{
                        if(current.item_condition === 'ScratchDent' && current.personal_scratch_dent_price && current.personal_scratch_dent_pric !== 0){
                            return total+(current.personal_scratch_dent_price*current.quantity)
                        }else{
                            if(current.is_sale && current.personal_sale_price && current.personal_sale_price!==0){
                                return total+(current.personal_sale_price*current.quantity)
                            }else{
                                return total+(current.personal_price*current.quantity)
                            }
                        }
                    },0)
                    cookies.set('percentage',res.data?.customer?.[0].percentage,{expires:new Date(Date.now() + 2592000000)})
                    serverInfo.updateInfo('percentage',res.data?.customer?.[0].percentage)
                    // console.log('Header',res.data?.customer?.[0].percentage, serverInfo.percentage)
                    userInfo.updateInfo('cartItems',res.data.cartItems)
                    userInfo.updateInfo('cartItemsNumber',cartItemsNumber)
                    userInfo.updateInfo('cartItemsSubtotal',cartItemsSubtotal)
                    userInfo.updateInfo('addresses',res.data.addresses)
                    userInfo.updateInfo('wishLists',res.data.wishLists)
                    userInfo.updateInfo('wishListsItems',res.data.wishListsItems)
                    PubSub.publish('percentage',res.data?.customer?.[0].percentage)
                    PubSub.publish('cartItems',res.data.cartItems)
                    PubSub.publish('cartItemsNumber',cartItemsNumber)
                    PubSub.publish('cartItemsSubtotal',cartItemsSubtotal)
                    PubSub.publish('addresses',res.data.addresses)
                    PubSub.publish('wishLists',res.data.wishLists)
                    PubSub.publish('wishListsItems',res.data.wishListsItems)
                    this.setState({cartItems:[...res.data.cartItems],addresses:[...res.data.addresses],isLogged:true,cartItemsNumber})
                }else{
                    console.log(res.data.scode,res.data.smessage)
                }
                },
                rej=>console.log(rej)
            )
        }else{
            this.setState({isLogged:false})
            cookies.remove('percentage')
        }
        this.cartItems = PubSub.subscribe('cartItems',(_,data)=>{
            const cartItemsNumber = data?.reduce((total,current)=>total+current.quantity,0)
            const cartItemsSubtotal = data?.reduce((total,current)=>{
                if(current.item_condition === 'ScratchDent'){
                    return total+(current.personal_scratch_dent_price*current.quantity)
                }else{
                    if(current.is_sale){
                        return total+(current.personal_sale_price*current.quantity)
                    }else{
                        return total+(current.personal_price*current.quantity)
                    }
                }
                
            },0)
            this.setState({cartItems:[...data],cartItemsNumber})
            PubSub.publish('cartItemsNumber',cartItemsNumber)
            PubSub.publish('cartItemsSubtotal',cartItemsSubtotal)
            userInfo.updateInfo('cartItems',data)
            userInfo.updateInfo('cartItemsNumber',cartItemsNumber)
            userInfo.updateInfo('cartItemsSubtotal',cartItemsSubtotal)
        })
        this.addresses = PubSub.subscribe('addresses',(_,data)=>{
            this.setState({addresses:[...data]})
        })
        PubSub.subscribe('isLogin',(_,data)=>{
            
            if(data){
                axios.get(`${serverInfo.baseURL}/header`,{headers:{'customerid':serverInfo.customerid,'percentage':serverInfo.percentage,'authorization':serverInfo.token}}).then(
                    res=>{
                    if(res.data.scode === 200){
                        // console.log(res.data)
                        const cartItemsNumber = res.data.cartItems?.reduce((total,current)=>total+current.quantity,0)
                        const cartItemsSubtotal = res.data.cartItems?.reduce((total,current)=>{
                            if(current.is_sale){
                                return total+(current.sale_price*current.quantity)
                            }else{
                                return total+(current.price*current.quantity)
                            }
                        },0)
                        serverInfo.updateInfo('percentage',res.data?.customer?.percentage)
                        userInfo.updateInfo('cartItems',res.data.cartItems)
                        userInfo.updateInfo('cartItemsNumber',cartItemsNumber)
                        userInfo.updateInfo('cartItemsSubtotal',cartItemsSubtotal)
                        userInfo.updateInfo('addresses',res.data.addresses)
                        userInfo.updateInfo('wishLists',res.data.wishLists)
                        userInfo.updateInfo('wishListsItems',res.data.wishListsItems)
                        PubSub.publish('cartItems',res.data.cartItems)
                        PubSub.publish('cartItemsNumber',cartItemsNumber)
                        PubSub.publish('cartItemsSubtotal',cartItemsSubtotal)
                        PubSub.publish('addresses',res.data.addresses)
                        PubSub.publish('wishLists',res.data.wishLists)
                        PubSub.publish('wishListsItems',res.data.wishListsItems)
                        this.setState({cartItems:[...res.data.cartItems],addresses:[...res.data.addresses],isLogged:true,cartItemsNumber})
                    }else{
                        console.log(res.data.scode,res.data.smessage)
                    }
                    },
                    rej=>console.log(rej)
                )
            }else{
                this.setState({isLogged:data})
            }
        })
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.windowResize.bind(this))
        window.removeEventListener('scroll',this.freezeHandler)
        PubSub.unsubscribe(this.cartItems)
        PubSub.unsubscribe(this.addresses)
    }
    render() {
        return (
            <div className="header header_new">
                <div className="header-top-bar">
                    <div className="header-media">
                        <div><a href="tel:905-731-1928"><PiPhoneCall style={{ fontSize:"2em", fontStyle:"bold", marginRight:"10px"}}/>Call us: 1-905-731-1928</a></div>
                    </div>
                    {/* <User/> */}
                </div>
                <div className="header-middle-bar">
                    <div className="header-middle-content">
                        <div className='header-middle-mobile-top'>
                            <Logo/>
                            <div className='header-middle-content-mobile'>
                                <button><PiPhoneCall/></button>
                                <button onClick={()=>{this.props.navigate('/acer/cart',{state:{from:this.props.location.pathname}})}}><MdOutlineShoppingBag/></button>
                            </div>
                        </div>
                        <div className='header-middle-mobile-bottom'>
                            <button className='header-nav-bar-toggle' onClick={(e)=>{this.showMenu()}}><IoMenu style={{fontSize:'40px'}}/><p>MENU</p></button>
                            <Search/>
                        </div>
                        <User cartItemsNumber={this.state.cartItemsNumber} cartItems={this.state.cartItems}/>
                    </div>
                </div>
                <div className="header-main-navigate" style={{display:this.state.windowWidth>800?"flex":(this.state.showMenu?"flex":"none"),top:this.state.menuTop}}>
                    <div className="header-main-navigate-content">
                        <div className='header-main-navigate-mobile-title'>
                            <h1>MENU</h1>
                            <button onClick={(e)=>{this.hideMenu()}}><RxCross2/></button>
                        </div>  
                        <div className='header-main-navigate-mobile-user' style={{display:!this.state.isLogged && this.state.isMobile? "flex":"none"}}>
                            <Link to={{pathname:'/acer/signin'}} state={{from:this.props.location.pathname}}><p style={{borderRight:"1px solid rgb(220,221,222)",paddingRight:'15px'}}>Sign in</p></Link>
                            <Link to={{pathname:'/acer/signin'}} state={{from:this.props.location.pathname}}><p style={{paddingLeft:'15px'}}>Register</p></Link>
                        </div>  
                        <div className={this.state.showHeader?"header-navigate":"header-navigate hide"}>
                            <div className={["header-navigate-link-main","header-navigate-link"].join(' ')}>
                                <div className="header-navi-subcategory" style={{display:this.state.isLogged && this.state.isMobile ? "flex":"none"}}>
                                    <div className='header-navigate-category'>
                                        <button>Account <MdOutlineArrowDropDown style={{fontSize:"2em",display:this.state.windowWidth<=800?"none":"flex"}}/></button>
                                        <button onClick={(e)=>{this.setState({showAccount:!this.state.showAccount})}} className='header-navigate-category-mobile'>more <b>+</b></button>
                                    </div>
                                    <div className={(this.state.showHeader || this.state.showAccount)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/account/orders'}} state={{from:this.props.location.pathname}}><li>Orders</li></Link>
                                            <Link to={{pathname:'/acer/account/messages'}} state={{from:this.props.location.pathname}}><li>Messages</li></Link>
                                            <Link to={{pathname:'/acer/account/addresses'}} state={{from:this.props.location.pathname}}><li>Addresses</li></Link>
                                            <Link to={{pathname:'/acer/account/wishLists'}} state={{from:this.props.location.pathname}}><li>Wish Lists</li></Link>
                                            <Link to={{pathname:'/acer/account/recentlyViewed'}} state={{from:this.props.location.pathname}}><li>Recently Viewed</li></Link>
                                            <Link to={{pathname:'/acer/account/accountSettings'}} state={{from:this.props.location.pathname}}><li>Account Settings</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className='header-navigate-link-button' style={{display:this.state.isLogged && this.state.isMobile ? "flex":"none"}}>
                                    <button onClick={(e)=>{}}>Sign Out</button>
                                </div>
                                <div className="header-navi-subcategory">
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Sale',{state:{from:this.props.location.pathname}})}}>Sale</button>
                                        {/* <button onClick={(e)=>{this.setState({showSale:!this.state.showSale})}} className='header-navigate-category-mobile'>more <b>+</b></button> */}
                                    </div>
                                    {/* <div className={(this.state.showHeader || this.state.showSale)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/Sale/Clearance'}} state={{from:this.props.location.pathname}}><li>Clearance</li></Link>
                                            <Link to={{pathname:'/acer/category/Sale/EpicDeals'}} state={{from:this.props.location.pathname}}><li>Epic Deals</li></Link>
                                            <Link to={{pathname:'/acer/category/Sale/TreasureChest'}} state={{from:this.props.location.pathname}}><li>Treasure Chest</li></Link>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='header-navigate-link-button'>
                                    <button onClick={(e)=>{this.props.navigate('/acer/category/BrandNew',{state:{from:this.props.location.pathname}})}}>Brand New</button>
                                </div>
                                <div className='header-navigate-link-button'>
                                    <button onClick={(e)=>{this.props.navigate('/acer/category/Notebook',{state:{from:this.props.location.pathname}})}}>Notebook</button>
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Desktop',{state:{from:this.props.location.pathname}})}}>Desktop <MdOutlineArrowDropDown style={{fontSize:"2em",display:this.state.windowWidth<=800?"none":"flex"}}/></button>
                                        <button onClick={(e)=>{this.setState({showDesktop:!this.state.showDesktop})}} className='header-navigate-category-mobile'>more <b>+</b></button>
                                    </div>
                                    <div className={(this.state.showHeader || this.state.showDesktop)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/Desktop/AIO'}} state={{from:this.props.location.pathname}}><li>All-In-One</li></Link>
                                            <Link to={{pathname:'/acer/category/Desktop/Chromebox'}} state={{from:this.props.location.pathname}}><li>Chromebox</li></Link>
                                            <Link to={{pathname:'/acer/category/Desktop/Gaming'}} state={{from:this.props.location.pathname}}> <li>Gaming</li></Link>
                                            <Link to={{pathname:'/acer/category/Desktop/Tower'}} state={{from:this.props.location.pathname}}><li>Tower</li></Link>
                                            <Link to={{pathname:'/acer/category/Desktop/WorkHome'}} state={{from:this.props.location.pathname}}> <li>Work & Home</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className='header-navigate-link-button'>
                                    <button onClick={(e)=>{this.props.navigate('/acer/category/AIO',{state:{from:this.props.location.pathname}})}}>All-In-One</button>
                                </div>
                                <div className='header-navigate-link-button'>
                                    <button onClick={(e)=>{this.props.navigate('/acer/category/Chromebook',{state:{from:this.props.location.pathname}})}}>Chromebook</button>
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Monitor',{state:{from:this.props.location.pathname}})}}>Monitor</button>
                                        {/* <button onClick={(e)=>{this.setState({showMonitor:!this.state.showMonitor})}}className='header-navigate-category-mobile'>more <b>+</b></button> */}
                                    </div>
                                    {/* <div className={(this.state.showHeader || this.state.showMonitor)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/Monitors/PortableMonitors'}} state={{from:this.props.location.pathname}}><li>Portable Monitors</li></Link>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Tablet',{state:{from:this.props.location.pathname}})}}>Tablet</button>
                                        {/* <button onClick={(e)=>{this.setState({showOnTheGo:!this.state.showOnTheGo})}}className='header-navigate-category-mobile'>more <b>+</b></button> */}
                                    </div>
                                    {/* <div className={(this.state.showHeader || this.state.showOnTheGo)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/On-The-Go/Tablets'}} state={{from:this.props.location.pathname}}><li>Tablets</li></Link>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Accessories',{state:{from:this.props.location.pathname}})}}>Accessories</button>
                                        {/* <button onClick={(e)=>{this.setState({showAccessories:!this.state.showAccessories})}} className='header-navigate-category-mobile'>more <b>+</b></button> */}
                                    </div>
                                    {/* <div className={(this.state.showHeader || this.state.showAccessories)?"header-navi-dropdown":"header-navi-dropdown hide"}>
                                        <ul>
                                            <Link to={{pathname:'/acer/category/Accessories/BackpackCase'}} state={{from:this.props.location.pathname}}><li>Backpack & Case</li></Link>
                                            <Link to={{pathname:'/acer/category/Accessories/Charger'}} state={{from:this.props.location.pathname}}><li>Charger</li></Link>
                                            <Link to={{pathname:'/acer/category/Accessories/DockingStation'}} state={{from:this.props.location.pathname}}><li>Docking Station</li></Link>
                                            <Link to={{pathname:'/acer/category/Accessories/GraphicsCard'}} state={{from:this.props.location.pathname}}><li>Graphics Card</li></Link>
                                            <Link to={{pathname:'/acer/category/Accessories/Headset'}} state={{from:this.props.location.pathname}}><li>Headset</li></Link>
                                            <Link to={{pathname:'/acer/category/Accessories/Keyboard'}} state={{from:this.props.location.pathname}}><li>Keyboard</li></Link>
                                            <Link to={{pathname:'/acer/category/Accessories/Mice'}} state={{from:this.props.location.pathname}}><li>Mice</li></Link>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Gaming',{state:{from:this.props.location.pathname}})}}>Gaming <MdOutlineArrowDropDown style={{fontSize:"2em",display:this.state.windowWidth<=800?"none":"flex"}}/></button>
                                        <button onClick={(e)=>{this.setState({showGaming:!this.state.showGaming})}} className='header-navigate-category-mobile'>more <b>+</b></button>
                                    </div>
                                    <div className={(this.state.showHeader || this.state.showGaming)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/Gaming/Desktop'}} state={{from:this.props.location.pathname}}><li>Desktop</li></Link>
                                            <Link to={{pathname:'/acer/category/Gaming/Notebook'}} state={{from:this.props.location.pathname}}><li>Notebook</li></Link>
                                            <Link to={{pathname:'/acer/category/Gaming/Monitor'}} state={{from:this.props.location.pathname}}><li>Monitor</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/ScratchDent',{state:{from:this.props.location.pathname}})}}>Scratch & Dent</button>
                                        <button onClick={(e)=>{this.setState({showScratch:!this.state.showScratch})}} className='header-navigate-category-mobile'>more <b>+</b></button>
                                    </div>
                                    {/* <div className={(this.state.showHeader || this.state.showScratch)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/ScratchDent/Notebook'}} state={{from:this.props.location.pathname}}><li>Notebook</li></Link>
                                            <Link to={{pathname:'/acer/category/ScratchDent/Desktop'}} state={{from:this.props.location.pathname}}><li>Desktop</li></Link>
                                            <Link to={{pathname:'/acer/category/ScratchDent/AIO'}} state={{from:this.props.location.pathname}}><li>All-In-One</li></Link>
                                            <Link to={{pathname:'/acer/category/ScratchDent/Chromebook'}} state={{from:this.props.location.pathname}}><li>Chromebook</li></Link>
                                            <Link to={{pathname:'/acer/category/ScratchDent/Monitor'}} state={{from:this.props.location.pathname}}><li>Monitor</li></Link>
                                            <Link to={{pathname:'/acer/category/ScratchDent/Tablet'}} state={{from:this.props.location.pathname}}><li>Tablet</li></Link>
                                            <Link to={{pathname:'/acer/category/ScratchDent/Accessories'}} state={{from:this.props.location.pathname}}><li>Accessories</li></Link>                
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='header-navi-subcategory'>
                                    <div className='header-navigate-category'>
                                        <button onClick={(e)=>{this.props.navigate('/acer/category/Warranty',{state:{from:this.props.location.pathname}})}}>Warranty <MdOutlineArrowDropDown style={{fontSize:"2em",display:this.state.windowWidth<=800?"none":"flex"}}/></button>
                                        <button onClick={(e)=>{this.setState({showWarranty:!this.state.showWarranty})}} className='header-navigate-category-mobile'>more <b>+</b></button>
                                    </div>
                                    <div className={(this.state.showHeader || this.state.showWarranty)?"header-navi-dropdown":"header-navi-dropdown hide"} >
                                        <ul>
                                            <Link to={{pathname:'/acer/category/Warranty/Desktop'}} state={{from:this.props.location.pathname}}><li>Desktop</li></Link>
                                            <Link to={{pathname:'/acer/category/Warranty/Laptop'}} state={{from:this.props.location.pathname}}><li>Laptop</li></Link>
                                            <Link to={{pathname:'/acer/category/Warranty/Monitor'}} state={{from:this.props.location.pathname}}><li>Monitor</li></Link>
                                            <Link to={{pathname:'/acer/category/Warranty/Smartphone'}} state={{from:this.props.location.pathname}}><li>Smartphone</li></Link>
                                            <Link to={{pathname:'/acer/category/Warranty/Tablet'}} state={{from:this.props.location.pathname}}><li>Tablet</li></Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header)