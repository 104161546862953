import React, { Component } from 'react'
import './index.css'
import subImageUrl from '../../media/image/subscribe.png'
import withRouter from '../../withRouter'
import PageRoute from '../PageRoute'

class SubscribePage extends Component {
  render() {
    return (
      <div className='subscribepage'>
        <PageRoute/>
        <div className='subscribepage-content'>
            <h1>Subscribe</h1>
            <img src={subImageUrl} alt='err'/>
            <h1 style={{textAlign:"center",marginTop:"40px"}}>Want to stay in the loop of our latest sales and contest? Sign up for our weekly newsletter and never miss a deal again!</h1>
        </div>
      </div>
    )
  }
}
export default withRouter(SubscribePage)
