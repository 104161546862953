import React, { Component } from 'react'
import './index.css'
import BlogImageURL from '../../media/image/blogimage.png'
import RencentPosts from '../RecentPosts'
import { TfiTimer } from "react-icons/tfi";
import { GoPerson } from "react-icons/go";
import PageRoute from '../PageRoute';
import PubSub from 'pubsub-js';
export default class BlogPage extends Component {
    componentDidMount(){
        window.scrollTo({top:0,behavior:"auto"})
        PubSub.publish('routes',[
            {pathname:'/acer/blog',name:"Blog",isCurrent:false},
            {pathname:'/acer/blog/game-on',name:"Game On: Finding the Perfect Gaming Gear for Awesome Adventures",isCurrent:true}
        ])
    }
  render() {
    return (
      <div className='blogpage'>
        <PageRoute/>
        <div className='blogpage-content'>
            <h1 className='blogpage-title'>Game On: Finding the Perfect Gaming Gear for Awesome Adventures!</h1>
            <a href='#' className='blogpage-image-main'><img src={BlogImageURL} alt='err'/></a>
            <p><img src={BlogImageURL} alt='err' className='blog-imag-para'/>Welcome, tech enthusiasts and savvy shoppers! You’re looking for a new device on a budget and so you’re thinking about going the refurbished route, but you have questions. You probably want to know exactly what “refurbished” does (and doesn’t) mean.
            <br/><br/> Here are the top 5 myths and misconceptions of refurbished devices. </p>
            <div className='blogpage-subcontent'>
                <h3>Myth #1: Refurbished Means Inferior Quality</h3>
                <p><img src={BlogImageURL} alt='err' className='blog-imag-para blog-imag-para-none'/><b>Reality:</b>This myth couldn't be further from the truth! Refurbished devices are thoroughly tested, repaired, and certified to meet or surpass the same quality standards as new products.</p>
            </div>
            <div className='blogpage-subcontent'>
                <h3>Myth #2: Refurbished Devices Lack Warranty</h3>
                <p><img src={BlogImageURL} alt='err' className='blog-imag-para blog-imag-para-none'/><b>Reality:</b>Another common misconception is that refurbished devices come without warranty or support. But Acer Recertified stands behind the quality of its recertified products by offering <a href='#'>warranty</a> coverage and <a href='#'>support</a> services.
                    <br/><br/>When you buy a refurbished Acer Recertified device like a Chromebook or laptop, Your purchase is covered with a Manufacturer's Warranty for 90 Days! Additional warranties are also available for purchase.</p>
            </div>
            <div className='blogpage-subcontent'>
                <h3>Myth #3: Refurbished Devices Have Limited Options</h3>
                <p><img src={BlogImageURL} alt='err' className='blog-imag-para'/><b>Reality:</b>Some people believe that refurbished devices offer limited options or outdated models. On the contrary, the Acer Recertified lineup features diverse options to suit various needs and preferences.
                    <br/><br/>Acer Recertified has many recertified products, like <a href='#'>Chromebooks</a>,  <a href='#'>tablets</a>, <a href='#'></a>laptops, <a href='#'>desktops</a>, <a href='#'>monitors</a>, and <a href='#'>gaming devices</a>. They have popular series like Nitro, Predator, Aspire, Swift, and Spin. With such variety, you're sure to find the perfect refurbished device to meet your requirements.
                </p>
            </div>
            <div className='blogpage-subcontent'>
                <h3>Myth #4: Refurbished Devices Are Prone to Breakdowns</h3>
                <p><img src={BlogImageURL} alt='err' className='blog-imag-para blog-imag-para-none'/><b>Reality:</b>This myth stems from the misconception that refurbished devices are unreliable or prone to breakdowns. However, Acer Recertified devices undergo a thorough testing and refurbishment processes to ensure they meet stringent quality standards.
                <br/><br/>From Chromebooks to gaming laptops, Acer Recertified devices are carefully inspected, repaired, and certified to deliver optimal performance and reliability. With proper care and maintenance, refurbished devices can provide years of reliable service.</p>
            </div>
            <div className='blogpage-subcontent'>
                <h3>Myth #5: Refurbished Devices Are Poor Value for Money</h3>
                <p><img src={BlogImageURL} alt='err' className='blog-imag-para'/><b>Reality:</b>Some people believe that refurbished devices aren’t worth buying compared to brand-new products. But that’s simply not the case. Refurbished devices, including Acer Recertified devices, offer exceptional value for money.
                <br/><br/>Refurbished devices are cheaper than new ones and offer good computing experiences on a budget. Plus, with warranty coverage and support services, you can confidently purchase a device knowing your investment is protected.</p>
            </div>
            <div className='blogpage-subcontent'>
                <h3>Wrapping It Up</h3>
                <p><img src={BlogImageURL} alt='err' className='blog-imag-para blog-imag-para-none'/>In conclusion, you can clearly see that refurbished and recertified devices are reliable and well worth the investment. They are perfect for users of every type from casual users to intense gamers – all at an attractive price point. </p>
            </div>
        </div>
        <div className='blogpage-content-footer'>
            <p><TfiTimer/> Jan 31st 2024</p>
            <p><GoPerson/> Admin</p>
        </div>
        <RencentPosts/>
      </div>
    )
  }
}
