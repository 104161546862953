import React, { Component } from 'react'
import './index.css'
import BlogBreif from '../MainPage/BlogBreif'
import withRouter from '../../withRouter'
class RecentPosts extends Component {
  render() {
    return (
      <div className='recentposts-blog'>
        <h1>RECENT POSTS</h1>
        <div className='recentposts-blog-listing'>
            <BlogBreif/>
            <BlogBreif/>
        </div>
    </div>
    )
  }
}
export default withRouter(RecentPosts)