import React, { Component, createRef } from 'react'
import banner1URL from '../../../media/image/website_photo/main page 1.PNG'
// import banner2URL from '../../../media/image/website_photo/main page 2.PNG'
// import banner3URL from '../../../media/image/banner3.png'
// import banner4URL from '../../../media/image/banner4.png'
import { IoIosArrowBack,IoIosArrowForward} from "react-icons/io";

import './index.css'
export default class Banner extends Component {
    constructor(props){
        super(props)
        this.bannerRef = createRef()
        this.state={
            wrapperBoxMargin:0,
            footerTop:window.innerWidth<=800?0:624 - ((1200 - (window.innerWidth>=1200?1200:window.innerWidth))/4),
            bannerTop:232,
            indexArray:[0],
            index:0,
            currentInterval:null
        }
        this.windowResize = function(){
            const bannerHeight = this.bannerRef.current.clientHeight
            const bannerTop = this.bannerRef.current.offsetTop
            const currentWidth = window.innerWidth
            
            if(currentWidth>800 && (bannerHeight<=440 || this.bannerRef.current.offsetTop !== this.state.bannerTop)){
                this.setState({footerTop:(624-440+bannerHeight-this.state.bannerTop+bannerTop)})
            }
            if(currentWidth<=800){
                this.setState({footerTop:0})
            }
            console.log(this.bannerRef.current.offsetTop)
        }
        this.windowResizeHandler = this.windowResize.bind(this)

    }
    //direction: boolean, (true for left, false for right)
    bannerSlide(index,direction){
        // console.log('button clicked',index)
        let currentMargin = (index * -100)
        let isCurrent = false
        if(!direction){
            if(index < this.state.indexArray.length -1){
                this.setState({index:index + 1})
                const finalMargin = currentMargin - 100
                let interval = setInterval(() => {
                    if(!this.state.currentInterval){
                        this.setState({currentInterval:interval})
                        isCurrent = true
                    }else if(this.state.currentInterval && !isCurrent){
                        clearInterval(this.state.currentInterval)
                        this.setState({
                            currentInterval:interval,
                            wrapperBoxMargin:index * -100
                        })
                        isCurrent = true
                    }
                    currentMargin = currentMargin - 1
                    this.setState({wrapperBoxMargin:currentMargin})
                    if(currentMargin === finalMargin){
                        clearInterval(interval)
                        this.setState({
                            currentInterval:null
                        })
                    }
                }, 1);
            }
        }else{
            if(index > 0){
                this.setState({index:index - 1})
                const finalMargin = currentMargin + 100
                let interval = setInterval(() => {
                    if(!this.state.currentInterval){
                        this.setState({currentInterval:interval})
                        isCurrent = true
                    }else if(this.state.currentInterval && !isCurrent){
                        clearInterval(this.state.currentInterval)
                        this.setState({
                            currentInterval:interval,
                            wrapperBoxMargin:index * -100
                        })
                        isCurrent = true
                    }
                    currentMargin = currentMargin + 1
                    this.setState({wrapperBoxMargin:currentMargin})
                    if(currentMargin === finalMargin){
                        clearInterval(interval)
                        this.setState({
                            currentInterval:null
                        })
                    }
                }, 1);
            }
        }
    }
    bannerJump(currentIndex,targetIndex){
        if(currentIndex !== targetIndex){
            this.setState({index: targetIndex})
            let currentMargin = currentIndex * - 100
            const finalMargin = targetIndex * - 100
            const gap = (finalMargin - currentMargin)/100
            let interval = setInterval(() => {
                if(this.state.currentInterval){
                    this.setState({currentInterval:interval})
                }else{
                    clearInterval(this.state.currentInterval)
                    this.setState({
                        currentInterval:interval,
                        wrapperBoxMargin:currentIndex * -100
                    })
                }
                currentMargin = currentMargin + gap
                this.setState({wrapperBoxMargin:currentMargin})
                if(currentMargin === finalMargin){
                    clearInterval(interval)
                    this.setState({
                        currentInterval:null
                    })
                }
            }, 1);
        }
    }
    // bannerSlide(currentMargin,direction){
    //     console.log('button clicked',currentMargin)
    //     if(!direction){
    //         if(currentMargin>-300){
    //             const finalMargin = currentMargin - 100
    //             let interval = setInterval(() => {
    //                 currentMargin = currentMargin - 1
    //                 this.setState({wrapperBoxMargin:currentMargin})
    //                 if(currentMargin === finalMargin){
    //                     clearInterval(interval)
    //                 }
    //             }, 1);
    //         }
    //     }else{
    //         if(currentMargin<0){
    //             const finalMargin = currentMargin + 100
    //             let interval = setInterval(() => {
    //                 currentMargin = currentMargin + 1
    //                 this.setState({wrapperBoxMargin:currentMargin})
    //                 if(currentMargin === finalMargin){
    //                     clearInterval(interval)
    //                 }
    //             }, 1);
    //         }
    //     }
    // }
    componentDidMount() {
        window.addEventListener('resize',  this.windowResizeHandler)
    }
    componentWillUnmount(){
        window.removeEventListener('resize',  this.windowResizeHandler)
    }
  render() {
    return (
      <div className='banner' ref={this.bannerRef}>
        <div className='banner-main'>
            <div className='toggle'>
                <button className='toggle-button' onClick={()=>this.bannerSlide(this.state.index,true)}><IoIosArrowBack/></button>
            </div>
            <div className='wrapper'>
                <div className='wrapper-box' style={{marginLeft:`${this.state.wrapperBoxMargin}%`,width:`${this.state.indexArray.length*100}%`}}>
                    <a href='#'><img src={banner1URL}/></a>
                    {/* <a href='#'><img src={banner2URL}/></a> */}
                    {/* <a href='#'><img src={banner3URL}/></a>
                    <a href='#'><img src={banner4URL}/></a> */}
                </div>
            </div>
            <div className='toggle'>
                <button className='toggle-button' onClick={()=>this.bannerSlide(this.state.index,false)}><IoIosArrowForward/></button>
            </div>
        </div>
        <div className='wrapper-footer' >
            <ul className='wrapper-indicator'>
                {
                    this.state.indexArray.map(index=>(<button key={`banner-button-${index}`} onClick={()=>this.bannerJump(this.state.index,index)}><li key={`banner-li-${index}`} className={this.state.index === index ? 'active':""}></li></button>))
                }
                {/* <button><li className='active'></li></button>
                <button><li></li></button>
                <button><li></li></button>
                <button><li></li></button> */}
            </ul>
        </div>
      </div>
    )
  }
}
