import React, { Component } from 'react'
import './index.css'
import Address from '../AccountPage/Address'
import { Link } from 'react-router-dom'
import withRouter from '../../withRouter'
import serverInfo from '../../config'
import userInfo from '../../userInfo'
import PubSub from 'pubsub-js'
import PageRoute from '../PageRoute'
class CheckOutPage extends Component {
    constructor(props){
        super(props)
        this.taxes = {
            Ontario:13,
            Quebec:14.975,
            'Nova Scotia':15,
            'New Brunswick':15,
            'Manitoba':12,
            'British Columbia':12,
            'Prince Edward Island':15,
            Saskatchewan:11,
            Alberta:5,
            'Newfoundland and Labrador':15,
            'Northwest Territories':5,
            Yukon:5,
            Nunavut:5,
        }
        this.state = {
            isSynced:false,
            cartItemsNumber:0,
            cartItemsSubtotal:0,
            addresses:[],
            tax: null,
            grandTotal:null,
            shippingAddress:null,
            billingAddress:null,
            isSameAddress:false,
            chosenAddress:null,
            shippingMethod:'Standard',
            purchaseOrder:'',
        }
    }
    isSameAddressHandler= ()=>{
        if(!this.state.isSameAddress){
            this.setState({
                isSameAddress: !this.state.isSameAddress,
                billingAddress: this.state.shippingAddress? {...this.state.shippingAddress} : this.state.shippingAddress
            })
        }else{
            this.setState({isSameAddress:!this.state.isSameAddress})
        }
    }
    placeOrder = () => {
        if(this.state.billingAddress && this.state.shippingAddress && this.state.cartItemsNumber !== 0 && this.state.purchaseOrder){
            var tzoffset = (new Date()).getTimezoneOffset() * 60000;
            var localISOTime = (new Date(Date.now() - tzoffset)).toISOString();
            const lineItems = []
            for(const item of userInfo.cartItems){
                console.log(item.personal_price)
                lineItems.push({
                    item_id:item.item_id,
                    item_name:item.item_name,
                    quantity:item.quantity,
                    sku:item.sku,
                    item_rate:item.item_condition === "ScratchDent" ? item.personal_scratch_dent_price  : (item.is_sale ? item.personal_sale_price : item.personal_price),
                    main_image:item.main_image,
                    item_condition:item.item_condition
                })
            }
            const data = {
                orderInfo:{
                    customer_id:serverInfo.customerid,
                    shipping_address_id:this.state.shippingAddress.address_id,
                    billing_address_id:this.state.billingAddress.address_id,
                    created_time:localISOTime,
                    line_items:lineItems,
                    subtotal:this.state.cartItemsSubtotal,
                    items_tax:this.state.tax,
                    total:this.state.grandTotal,
                    purchase_order_id: this.state.purchaseOrder,
                    shipping_method: this.state.shippingMethod
                }
            }
            this.props.navigate('/acer/order/placed',{state:{...this.props.location.state,orderData:data,billingAddress:this.state.billingAddress,shippingAddress:this.state.shippingAddress,isFirstTime:true},replace:true})
        }else if(this.state.cartItemsNumber === 0){
            alert('There is no item in your cart needs to check out.')
        }else if(!this.state.purchaseOrder){
            alert('Purchase order number is mandatory, the length should be 1 - 50 characters and cannot be 0.')
        }else{
            alert('please make sure you have select your shipping and billing address.')
        }
    }
    chooseShipping = (method)=>{
        this.setState({shippingMethod:method})
    }
    changePurchaseOrder = (e)=>{
        const newValue = e.target.value;
        // 使用正则表达式来确保只有数字被输入
        const numericRegex = /^[0-9]*$/;
        const newValueIsNumeric = numericRegex.test(newValue);
        if(newValue.length<=50){
            this.setState({
                purchaseOrder:newValue
            })
        }else{
            this.setState({
                purchaseOrder:newValue?.substring(0,50)
            })
        }
        // const stock = this.state.condition === 'ScratchDent' ? this.state.itemDetail.scratch_dent_stock : this.state.itemDetail.stock
        // if (newValueIsNumeric) {
        //     const newNumber = Number.parseInt(newValue)
        //     if(Number.isNaN(newNumber)){
        //         this.setState({orderedQuantity:newValue})
        //     }else if(newNumber>0 && newNumber<= stock){
        //         this.setState({orderedQuantity:newNumber})
        //     }else if(newNumber > stock){
        //         this.setState({orderedQuantity:stock})
        //     }
        // }
        // console.log(e.target.value);
    }
    componentDidMount(){
        window.scrollTo({top:0,behavior:"auto"})
        this.cartItemsNumber = PubSub.subscribe('cartItemsNumber',(_,data)=>this.setState({cartItemsNumber:data}))
        this.cartItemsSubtotal = PubSub.subscribe('cartItemsSubtotal',(_,data)=>this.setState({cartItemsSubtotal:data}))
        this.addresses = PubSub.subscribe('addresses',(_,data)=>this.setState({addresses:[...data]}))
        this.shippingAddress = PubSub.subscribe('shippingAddress',(_,data)=>{
            if(data){
                const tax = this.state.cartItemsSubtotal * this.taxes[data.province] * 0.01
                const grandTotal = this.state.cartItemsSubtotal + tax
                this.setState({shippingAddress:{...data},billingAddress:this.state.isSameAddress?{...data}:this.state.billingAddress,tax,grandTotal})
            }else{
                this.setState({shippingAddress:data,tax:null,grandTotal:null})
            }
        })
        this.billingAddress = PubSub.subscribe('billingAddress',(_,data)=>this.setState({billingAddress:data?{...data}:data}))
        if(this.props.location.state){
            this.setState({
                cartItemsNumber:userInfo.cartItemsNumber,
                cartItemsSubtotal:userInfo.cartItemsSubtotal,
                addresses:[...userInfo.addresses]
            })
        }
        PubSub.publish('routes',[
            {pathname:'/acer/cart',name:"Your Cart",isCurrent:false},
            {pathname:'/acer/checkout',name:"Check Out",isCurrent:true}
        ])
    }
    componentWillUnmount(){
        PubSub.unsubscribe(this.cartItemsNumber)
        PubSub.unsubscribe(this.cartItemsSubtotal)
        PubSub.unsubscribe(this.addresses)
        PubSub.unsubscribe(this.shippingAddress)
        PubSub.unsubscribe(this.billingAddress)
    }
  render() {
    // console.log(this.props.location.pathname)
    return (
      <div className='checkoutpage'>
        <PageRoute/>
        <div className='checkoutpage-content'>
            <div className='checkoutpage-navi' style={{display:'none'}}>
                <button className='active'>1. PLACE ORDER</button>
                <button>2. ORDER REVIEW</button>
            </div>
            <div className='checkoutpage-order'>
                <div className='checkoutpage-order-info'>
                    <div className='checkoutpage-purchase-order'>
                        <h2>Purchase Order Number: </h2>
                        <div className='checkoutpage-address-content'>
                            <input type="text" value={this.state.purchaseOrder} onChange={this.changePurchaseOrder}/>
                        </div>
                    </div>
                    <div className='checkoutpage-address'>
                        <h2>SHIPPING ADDRESS</h2>
                        
                        <div className='checkoutpage-address-content'>
                            {this.state.addresses?.map(address=>(<Address width='100%' key={`s-${address.address_id}`} showCheckbox={true} address={address} addressType={'shippingAddress'} isChecked={this.state.shippingAddress && this.state.shippingAddress.address_id === address.address_id?true:false}/>))}
                            <Link to={{pathname:'/acer/account/addressForm'}} state={{from:this.props.location.pathname}}>Add a New Address</Link>
                        </div>
                    </div>
                    <div className='checkoutpage-address'>
                        <h2 style={{border:"none"}}>BILLING ADDRESS</h2>
                        <label><input type='checkbox' checked={this.state.isSameAddress} onChange={(e)=>this.isSameAddressHandler()}/>Use delivery address as my billing address.</label>
                        <div className='checkoutpage-address-content'>
                            {this.state.isSameAddress && this.state.addresses.length > 0 ? 
                            (this.state.billingAddress ? (<Address width='100%' showCheckbox={false} addressType={'billingAddress'} hideButtons={true} isChecked={true} address={this.state.billingAddress}/>): "" ):
                            this.state.addresses?.map(address=>(<Address width='100%' key={`b-${address.address_id}`} showCheckbox={!this.state.isSameAddress}  addressType={'billingAddress'} hideButtons={true} address={address} isChecked={this.state.billingAddress && this.state.billingAddress.address_id === address.address_id?true:false}/>))}
                            {/* <Address width='100%' showCheckbox={false} /> */}
                            <Link to={{pathname:'/acer/account/addressForm'}} state={{from:this.props.location.pathname}} style={{display:"none"}}>Add a New Address</Link>
                        </div>
                    </div>
                    <div className='checkoutpage-shipping-methods'>
                        <h2>SELECT SHIPPING METHOD</h2>
                        <div className='checkoutpage-shipping-list'>
                            <div className='checkoutpage-shipping-tag'>
                                <input type='checkbox' checked={this.state.shippingMethod ==='Standard'?true:false} onChange={()=>this.chooseShipping('Standard')}/>
                                <div className='checkoutpage-shipping-info'>
                                    <p>Standard</p>
                                    {/* <p>Allow 2-3 business days to ship - Arrives 5-12 business days after it has shipped</p> */}
                                    <p>Shipping fee will be manually calculated and shows in invoice.</p>
                                </div>
                            </div>
                        </div>
                        <div className='checkoutpage-shipping-list'>
                            <div className='checkoutpage-shipping-tag'>
                                <input type='checkbox' checked={this.state.shippingMethod ==='Pick Up'?true:false} onChange={()=>this.chooseShipping('Pick Up')}/>
                                <div className='checkoutpage-shipping-info'>
                                    <p>Pick Up</p>
                                    {/* <p>Allow 2-3 business days to ship - Arrives 5-12 business days after it has shipped</p> */}
                                    <p>Pick up order.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkoutpage-summary'>
                    <h2>ORDER SUMMARY {this.state.cartItemsNumber>1?`(${this.state.cartItemsNumber} items)`:`(${this.state.cartItemsNumber} item)`}</h2>
                    <div className='checkoutpage-summary-detail'>
                        <div>
                            <p>Subtotal:</p>
                            <p>${this.state.cartItemsSubtotal.toFixed(2)}</p>
                        </div>
                        <div>
                            <p>Shipping:</p>
                            <p>Check in Invoice</p>
                        </div>
                        <div>
                            <p>Tax:</p>
                            <p>{this.state.tax?`$${this.state.tax.toFixed(2)}`:'without shipping'}</p>
                        </div>
                        <div>
                            <p>Grand Total:</p>
                            <p>{this.state.grandTotal?`$${this.state.grandTotal.toFixed(2)}`:`$${this.state.cartItemsSubtotal.toFixed(2)}`}</p>
                        </div>
                    </div>
                    
                    <button className={this.state.cartItemsNumber>0?"":"disabled"} onClick={()=>this.placeOrder()} disabled={this.state.cartItemsNumber>0?false:true}>PLACE ORDER</button>
                </div>
            </div>
            <div className='checkoutpage-review'>
            </div>
        </div>
      </div>
    )
  }
}
export default withRouter(CheckOutPage)